import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import NewSession from "../NewSession/NewSession";
import PreviousEntries from "../../components/PreviousEntries/PreviousEntries";
import { Link, useHistory, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faLongArrowAltRight,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./journalStyles.module.scss";

import Loading from "../../components/Loading";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import StackBanner from "../../components/StackBanner/StackBanner";
import { colors } from "../../utils/constants";
import Insights from "../Insights/Insights";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import MethodIcon from "../../components/MethodIcon";
import { format } from "date-fns";
import TextDisplay from "../../components/TextDisplay/TextDisplay";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import EntryDetail from "../../components/EntryDetail/EntryDetail";
import MySessions from "../MySessions/MySessions";
import { AppDataContext } from "../../components/AppDataProvider";
import MyNotes from "../MyNotes/MyNotes";
import NoteDetail from "../NoteDetail/NoteDetail";
import Placeholder from "../../components/Placeholder/Placeholder";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import Button from "../../components/Button/Button";

export const MobileH1 = ({ children, style, subHeaderText, subHeaderHref }) => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
      }}
    >
      <h2
        style={{
          fontSize: "1em",
          color: colors[4],
          textAlign: "left",
          display: "inline",
          marginRight: "10px",
          marginBottom: "5px",
          ...style,
        }}
      >
        {children}
      </h2>
      {subHeaderText ? (
        <button
          style={{
            display: "inline",
            color: colors[3],
            backgroundColor: "rgba(0,0,0,0)",
            border: "none",
            padding: "0",
            minHeight: "0",
            lineHeight: "1.3",
            fontSize: "0.9em",
            margin: "0",
          }}
          onClick={() => {
            history.push(subHeaderHref);
          }}
        >
          {subHeaderText}{" "}
          <FontAwesomeIcon icon={faLongArrowAltRight}></FontAwesomeIcon>
        </button>
      ) : null}
    </div>
  );
};

const IconLink = ({ icon, text, href, noBorder, onClick }) => {
  return (
    <TouchableOpacity
      style={{ textAlign: "left" }}
      noBorder={noBorder}
      onClick={onClick}
    >
      <div style={{ color: colors[3], fontWeight: "bold" }}>
        <FontAwesomeIcon icon={icon} style={{ marginRight: "10px" }} />
        <span style={{ fontSize: "0.9em" }}>{text}</span>
      </div>
    </TouchableOpacity>
  );
};

export const StrainLink = ({
  strainName,
  rating,
  ratingLabel = "My Rating",
  numSessions,
  numNotes,
  noBorder,
  onClick,
}) => {
  return (
    <TouchableOpacity noBorder={noBorder} onClick={onClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <FontAwesomeIcon
          icon={faLeaf}
          style={{ color: colors[3], marginRight: "10px" }}
        ></FontAwesomeIcon>
        <div style={{ textAlign: "left", margin: 0, flex: 1 }}>
          <div style={{ color: colors[4], lineHeight: 0.9 }}>{strainName}</div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              color: colors[3],
              fontSize: "0.8em",
              lineHeight: 0.94,
              marginTop: "3px",
            }}
          >
            <div>
              <div>{numSessions} sessions</div>
              <div>{numNotes} strain notes</div>
            </div>
            <div style={{ textAlign: "right" }}>
              <div>{ratingLabel}</div>
              <div>{rating} / 5</div>
            </div>
          </div>
        </div>
      </div>
    </TouchableOpacity>
  );
};

export const SessionLink = ({
  name,
  date,
  method,
  rating,
  onClick,
  noBorder,
}) => {
  return (
    <TouchableOpacity onClick={onClick} noBorder={noBorder}>
      <div style={{ display: "flex" }}>
        <MethodIcon
          method={method}
          style={{ color: colors[3], marginRight: "10px" }}
        ></MethodIcon>
        <div style={{ textAlign: "left", flex: 1 }}>
          <div style={{ color: colors[4], lineHeight: 0.9 }}>{name}</div>
          <div
            style={{
              color: colors[3],
              fontSize: "0.8em",
              lineHeight: 0.94,
              marginTop: "3px",
            }}
          >
            {format(new Date(Date.parse(date)), "MMM d, yyyy")}
            <br />
            Session Rating: {rating} / 5
          </div>
        </div>
      </div>
    </TouchableOpacity>
  );
};

export const NoteLink = ({ note, onClick }) => {
  return (
    <TouchableOpacity onClick={onClick}>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div>
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{ color: colors[3], marginRight: "10px" }}
          />
        </div>
        <div style={{ flex: 1, textAlign: "left" }}>
          <div style={{ fontWeight: "bold", color: colors[3] }}>
            {note.strainName}
          </div>
          <div
            style={{ color: colors[3], fontSize: "0.8em", lineHeight: 0.94 }}
          >
            {format(new Date(Date.parse(note.createdAt)), "MMM d, yyyy")}
          </div>
          <div>
            <TextDisplay rawData={note.text}></TextDisplay>
          </div>
        </div>
      </div>
    </TouchableOpacity>
  );
};

const Journal = ({ location }) => {
  const [insightData, setInsightData] = useState(false);
  const [loadingInsightData, setLoadingInsightData] = useState(true);
  const [forumData, setForumData] = useState(false);
  const [loadingForumData, setLoadingForumData] = useState(true);
  const { mySessions, strains, reloadData } = useContext(AppDataContext);
  let { page, id } = useParams();
  const [deferredPrompt, setDeferredPrompt] = useState();
  const isMounted = useRef(true);
  const firebase = useContext(FirebaseContext);

  const history = useHistory();

  const getInsightData = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getInsightsData`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();

    return jsonResponse;
  };

  const getForumData = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getPosts`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  useEffect(() => {
    if (insightData) {
      setLoadingInsightData(false);
    }
  }, [insightData]);

  useEffect(() => {
    if (forumData) {
      setLoadingForumData(false);
    }
  }, [forumData]);

  // Get all Journal Entries and Strains and store in state.
  useEffect(() => {
    getInsightData().then((insights) => {
      if (isMounted.current) {
        setInsightData(insights);
      }
    });
    getForumData().then((posts) => {
      if (isMounted.current) {
        setForumData(posts);
      }
    });
    const handleInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA

      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    };
    window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    return () => {
      isMounted.current = false;
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
    };
  }, []);

  const handleRefresh = () => {
    history.push("dosage-journal");
    document.location.reload();
  };

  const myNotes = strains
    ? strains.reduce((l, strain) => {
        const notes = strain.notes.reduce(
          (n, d) =>
            d.firebaseId === firebase.user.uid
              ? [
                  ...n,
                  {
                    ...d,
                    strainName: strain.strainName,
                    strainId: strain._id,
                  },
                ]
              : n,
          []
        );
        return [...l, ...notes];
      }, [])
    : [];

  const strainRatings = mySessions
    ? mySessions.reduce((obj, session) => {
        if (session.strainId) {
          if (session.strainId in obj) {
            obj[session.strainId].push(parseFloat(session.overallRating));
          } else {
            obj[session.strainId] = [parseFloat(session.overallRating)];
          }
        }
        return obj;
      }, {})
    : {};

  const myStrains =
    mySessions && strains
      ? strains.reduce((obj, strain) => {
          const ratings = strainRatings[strain._id] || [];
          const numSessions = ratings.length;
          const numNotes = myNotes.reduce(
            (count, note) => (note.strainId === strain._id ? count + 1 : count),
            0
          );
          console.log(ratings);
          if (numSessions > 0 || numNotes > 0) {
            obj[strain._id] = {
              numSessions,
              numNotes,
              rating:
                ratings.length > 0
                  ? (
                      ratings.reduce((a, b) => a + b) /
                      parseFloat(ratings.length)
                    ).toFixed(2)
                  : "-",
            };
          }
          return obj;
        }, {})
      : {};

  if (page === "new-session") {
    return (
      <NewSession
        defaultStep={location?.state?.step || 0}
        defaultDatetime={location?.state?.datetime || new Date()}
        onComplete={handleRefresh}
      ></NewSession>
    );
  }
  if (page === "session-detail") {
    return (
      <div style={{ padding: "0 8px 50px 8px" }}>
        <EntryDetail
          entryData={mySessions.find((s) => s._id === id)}
          onBack={() => {
            history.goBack();
          }}
          strains={strains}
          onDelete={() => {
            reloadData();
            history.push("/dosage-journal");
          }}
        ></EntryDetail>
      </div>
    );
  }
  if (page === "note-detail") {
    return <NoteDetail note={myNotes.find((n) => n._id === id)} />;
  }
  if (page === "my-sessions") {
    return <MySessions />;
  }
  if (page === "my-notes") {
    return <MyNotes />;
  }
  if (page === "data-and-insights") {
    return (
      <Insights
        data={insightData}
        strains={strains}
        loading={loadingInsightData}
      ></Insights>
    );
  }

  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        padding: "0 18px 80px 18px",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "15px 0",
        }}
      >
        <EmployeePhoto
          style={{ margin: "0 auto" }}
          round
          signedUrl={firebase.data.signedUrl}
          width={130}
          height={130}
        ></EmployeePhoto>
        <h1 style={{ margin: "15px 0" }}>
          Welcome, {firebase.data.nickName || firebase.data.firstName}
        </h1>
        <Button
          onClick={() => {
            history.push("/dosage-journal/new-session");
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: "7px" }} />
          New Session
        </Button>
      </div>

      <MobileH1 style={{ marginTop: "10px" }}>
        My Strains ({Object.keys(myStrains).length})
      </MobileH1>
      {Object.keys(myStrains).length > 0 ? (
        Object.keys(myStrains).map((strain, i) => {
          const strainData = strains.find((s) => {
            return s._id === strain;
          });
          return (
            <StrainLink
              key={strain}
              strainName={strainData?.strainName}
              rating={myStrains[strain].rating}
              numNotes={myStrains[strain].numNotes}
              numSessions={myStrains[strain].numSessions}
              noBorder={i === Object.keys(myStrains).length - 1}
              onClick={() => {
                history.push(`/strains/${strain}`);
              }}
            ></StrainLink>
          );
        })
      ) : (
        <Placeholder>
          No strains yet. Enter a session or create a strain note to get
          started.
        </Placeholder>
      )}
      <MobileH1
        style={{ marginTop: "10px" }}
        subHeaderText={mySessions.length > 0 ? "View all" : null}
        subHeaderHref="/dosage-journal/my-sessions"
      >
        Sessions ({mySessions.length})
      </MobileH1>
      {mySessions.length > 0 ? (
        mySessions.slice(0, 5).map((session, i) => (
          <SessionLink
            key={session._id}
            method={session.method}
            onClick={() => {
              history.push(`/dosage-journal/session-detail/${session._id}`);
            }}
            name={
              session.strainId
                ? strains.find((strain) => strain._id === session.strainId)
                    .strainName
                : session.product?.name
            }
            date={session.entryDate}
            rating={session.overallRating}
            noBorder={i === 4 || i === mySessions.length}
          ></SessionLink>
        ))
      ) : (
        <Placeholder>No sessions yet.</Placeholder>
      )}
      <MobileH1
        style={{ marginTop: "10px" }}
        subHeaderText={myNotes.length > 0 ? "View all" : null}
        subHeaderHref="/dosage-journal/my-notes"
      >
        Notes ({myNotes.length})
      </MobileH1>
      {myNotes.length > 0 ? (
        myNotes.map((note) => (
          <NoteLink
            key={note._id}
            note={note}
            onClick={() => {
              history.push(`/dosage-journal/note-detail/${note._id}`);
            }}
          ></NoteLink>
        ))
      ) : (
        <Placeholder style={{ margin: "5px 0" }}>
          No strain notes yet.
        </Placeholder>
      )}
    </div>
  );
};

export default Journal;
