import { format } from "date-fns";
import React from "react";
import { Link, useParams } from "react-router-dom";
import ClickableListItem from "../../components/ClickableListItem/ClickableList";
import Container from "../../components/Container/Container";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import MethodIcon from "../../components/MethodIcon";
import MethodInsights from "../../components/MethodInsights/MethodInsights";
import styles from "./userDetailStyles.module.scss";
const UserDetail = ({ user }) => {
  console.log(user);
  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            padding: "40px 0",
          }}
        >
          <EmployeePhoto imgLocation={user?.imgLocation} />
          <div style={{ padding: "0 10px" }}>
            <h2 style={{ marginBottom: "10px" }}>
              {user.firstName} {user.lastName}
            </h2>
            <p className={styles.field}>Email: {user.email}</p>
            <p className={styles.field}>Cell Phone: {user.cellPhone}</p>
            <p className={styles.field}>
              Employee: {user.roles.includes("Employee") ? "Yes" : "No"}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div style={{ flex: "50%", paddingRight: "30px" }}>
            <h2>Journal Entries ({user.journalEntries.length})</h2>
            <div style={{ margin: "10px 0 30px 0" }}>
              {user.journalEntries.reverse().map((entry, i) => (
                <ClickableListItem key={`${i}-${entry.firebaseId}`}>
                  <div
                    style={{
                      minHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 20px 0 10px",
                    }}
                  >
                    <div>
                      {format(new Date(entry.entryDate), "MMM d, yyyy")}
                    </div>
                    <div>{entry.strainName}</div>
                    <MethodIcon method={entry.method}></MethodIcon>
                  </div>
                </ClickableListItem>
              ))}
            </div>
          </div>
          <div style={{ flex: "50%", paddingLeft: "30px" }}>
            <h2>Strain Notes ({user.notes.length})</h2>
            <div>
              {user.notes.map((note) => {
                //console.log(note.createdAt);
                return (
                  <div key={note._id} style={{ marginTop: "10px" }}>
                    <div style={{ fontWeight: "bold" }}>
                      {format(new Date(note.createdAt), "MMM d, yyyy")}
                    </div>
                    <div>
                      <Link to={`/strains/${note.strainId}`}>
                        {note.strainName}
                      </Link>
                    </div>
                    <div>{note.content}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UserDetail;
