import React, { useState, useEffect, useContext, Fragment } from "react";
import { HorizontalBar } from "react-chartjs-2";
import styles from "./feelingsStyles.module.scss";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import LinkButton from "../LinkButton/LinkButton";
import ActionSheet, { ActionSheetButton } from "../ActionSheet/ActionSheet";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { MobileH1 } from "../../mobileViews/Journal/Journal";

//import { isMobile } from "react-device-detect";
const isMobile = true;
const YAxis = ({ labels, strains }) => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-around",
        paddingTop: "0px",
        paddingBottom: "32px",
      }}
    >
      {labels.map((label, i) => (
        <LinkButton
          onClick={() => {
            history.push(
              `/strains/${
                strains.find((strain) => strain.strainName === label)._id
              }`
            );
          }}
          style={{
            fontSize: "14px",
            textAlign: "right",
            minWidth: "80px",
            whiteSpace: "nowrap",
          }}
          key={i}
        >
          {label}
        </LinkButton>
      ))}
    </div>
  );
};

export const BarGraph = ({
  data,
  strains,
  refProp,
  renderChart = true,
  style,
  header = "Feelings",
  subHeader = "Compare strains by how they make you feel.",
}) => {
  const firebase = useContext(FirebaseContext);
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      minHeight: 10,
      padding: 0,
      fontSize: 8,
      backgroundColor: "white",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    smallerFont: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();
  const [selectedCharacteristic, setSelectedCharacteristic] = useState("");

  const history = useHistory();
  const navigate = (location) => {
    history.push(location);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const containsData = data && Object.keys(data).length > 0;
    setSelectedCharacteristic(containsData ? Object.keys(data)[0] : null);
  }, [data]);

  const characteristicsData = data[selectedCharacteristic]
    ? {
        labels: [...Object.keys(data[selectedCharacteristic])],
        datasets: [
          {
            label: "Avg Rating",
            backgroundColor: "#62d2a2",

            hoverBackgroundColor: "#50c090",

            data: Object.keys(data[selectedCharacteristic]).map((strain) =>
              parseFloat(data[selectedCharacteristic][strain])
            ),
          },
        ],
      }
    : { datasets: [{ data: [] }] };

  const handleBarClick = (elem, x) => {
    const name = [...Object.keys(data[selectedCharacteristic])][elem[0]._index];
    const id = strains.find((strain) => strain.strainName === name)._id;
    navigate(`/strains/${id}`);
  };

  const chartOptions = {
    maintainAspectRatio: false,
    showScale: true,
    pointDot: true,
    showLines: false,
    label: "",
    legend: {
      display: false,
    },

    scales: {
      yAxes: [
        {
          ticks: { display: false },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 1,
          },
        },
      ],
    },
  };

  return (
    <div className={styles.wrapper} style={{ ...style }}>
      <div className={styles.chartAreaWrapper} ref={refProp}>
        <div className={styles.effectsChartWrapper}>
          <div style={{ flex: "60%", paddingRight: "15px" }}>
            <div className={styles.chartHeaderWrapper}>
              <div style={{ maxWidth: "400px" }}>
                <MobileH1>{header}</MobileH1>

                <p
                  style={{ margin: "0", fontSize: "0.8em" }}
                  className={styles.subHeader}
                >
                  {subHeader}
                </p>
              </div>
              <div>
                <FormControl className={classes.formControl}>
                  <Select
                    className={classes.smallerFont}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    value={selectedCharacteristic}
                    onChange={(e) => {
                      setSelectedCharacteristic(e.target.value);
                    }}
                  >
                    {Object.keys(data).map((feeling) => (
                      <MenuItem
                        key={feeling}
                        value={feeling}
                        className={classes.smallerFont}
                      >
                        {feeling}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: "10px",
              }}
            >
              <YAxis
                labels={[...Object.keys(data[selectedCharacteristic] || {})]}
                strains={strains}
              ></YAxis>
              <div
                style={{ minHeight: "600px", flex: 0 }}
                className={styles.chartWrapper}
              >
                {renderChart ? (
                  <HorizontalBar
                    data={characteristicsData}
                    options={chartOptions}
                    onElementsClick={handleBarClick}
                  ></HorizontalBar>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Feelings = ({ data, strains, renderChart, refProp, style }) => {
  if (data) {
    return (
      <BarGraph
        data={data}
        strains={strains}
        refProp={refProp}
        renderChart={renderChart}
        style={{ ...style }}
      />
    );
  }

  return <div ref={refProp}>loading</div>;
};

export default Feelings;
