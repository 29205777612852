import { format, set } from "date-fns";
import { roundToNearestMinutes } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import DateSelect from "../DateSelect/DateSelect";
import TimeSelect from "../TimeSelect/TimeSelect";

const DatetimeSelect = ({ currentDatetime = new Date(), onChange, style }) => {
  const dt = roundToNearestMinutes(currentDatetime, { nearestTo: 15 });
  const [currentDate, setCurrentDate] = useState(dt);
  const [currentHour, setCurrentHour] = useState(parseInt(format(dt, "HH")));
  const [currentMinute, setCurrentMinute] = useState(
    parseInt(format(dt, "mm"))
  );

  useEffect(() => {
    setCurrentDate((c) =>
      set(c, { hours: currentHour, minutes: currentMinute })
    );
  }, [currentHour, currentMinute]);

  useEffect(() => {
    onChange(set(currentDate, { hours: currentHour, minutes: currentMinute }));
  }, [currentDate]);

  return (
    <div style={{ ...style, whiteSpace: "nowrap" }}>
      <DateSelect
        defaultDate={dt}
        onChange={(d) => {
          setCurrentDate(d);
        }}
      ></DateSelect>
      <span style={{ margin: "0 20px" }}>at</span>
      <TimeSelect
        currentTime={`${
          currentHour === 0 || currentHour === 12 ? 12 : currentHour % 12
        }:${currentMinute < 10 ? `0${currentMinute}` : currentMinute} ${
          currentHour >= 12 ? "PM" : "AM"
        }`}
        onSet={(t) => {
          const h = parseInt(t.f24.split(":")[0]);
          const m = parseInt(t.f24.split(":")[1]);
          setCurrentHour(h);
          setCurrentMinute(m);
          onChange(set(currentDate, { hours: h, minutes: m }));
        }}
      ></TimeSelect>
    </div>
  );
};

export default DatetimeSelect;
