import cloneDeep from "lodash/cloneDeep";

export const lightenDarkenColor = (colorCode, amount) => {
  let usePound = false;

  if (colorCode[0] == "#") {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  let num = parseInt(colorCode, 16);

  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return g | (b << 8) | (r << 16);
};

export const clean = (obj) => {
  var propNames = Object.getOwnPropertyNames(obj);
  const newObj = cloneDeep(obj);
  for (var i = 0; i < propNames.length; i++) {
    var propName = propNames[i];
    if (newObj[propName] === null || newObj[propName] === undefined) {
      delete newObj[propName];
    }
  }
  return newObj;
};

export const range = (n) => {
  return Object.keys([...Array(n)]).map((i) => parseInt(i));
};

export const getMean = (list) => {
  return (
    list.reduce((a, b) => {
      return parseInt(a) + parseInt(b);
    }, 0) / list.length
  ).toFixed(2);
};

export const getStrainRatings = (entries) => {
  return entries.reduce((obj, entry) => {
    if (entry.strainId in obj) {
      obj[entry.strainId].push(entry.overallRating);
    } else {
      obj[entry.strainId] = [entry.overallRating];
    }
    return obj;
  }, {});
};

export const getHighestRated = (entries, strainData) => {
  const strainRatings = getStrainRatings(entries);
  const strainId = Object.keys(strainRatings).reduce((winner, currentId) => {
    if (getMean(strainRatings[currentId]) > getMean(strainRatings[winner])) {
      return currentId;
    } else {
      return winner;
    }
  });
  return strainData.find((strain) => strain._id === strainId);
};

export const getFrequencies = (entries) => {
  return entries.reduce((obj, entry) => {
    if (entry.strainId in obj) {
      obj[entry.strainId] += 1;
    } else {
      obj[entry.strainId] = 1;
    }
    return obj;
  }, {});
};

export const getMostFrequent = (entries, strains) => {
  const frequencies = getFrequencies(entries);
  const mostFrequent = Object.keys(frequencies).reduce((prev, current) => {
    if (frequencies[prev] > frequencies[current]) {
      return prev;
    } else {
      return current;
    }
  }, 0);
  return strains.find((strain) => strain._id === mostFrequent);
};
