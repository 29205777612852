import React, { useState } from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./starRatingStyles.module.scss";

const StarRating = ({ value, onChange, style }) => {
  const [rating, setRating] = useState(value || 0);
  return (
    <div className={styles.stars} style={{ ...style }}>
      {[1, 2, 3, 4, 5].map((i) => {
        return (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            className={[styles.star, rating >= i ? styles.checked : null].join(
              " "
            )}
            onClick={() => {
              setRating(i);
              onChange(i);
            }}
          ></FontAwesomeIcon>
        );
      })}
      <figure
        className={[
          styles.face,
          rating > 0 ? styles[`face${rating}`] : null,
        ].join(" ")}
      >
        <i className={styles.eye}></i>
        <i className={styles.eye}></i>
        <u className={styles.smile}>
          <div className={styles.cover}></div>
        </u>
      </figure>
    </div>
  );
};

export default StarRating;
