import React from "react";
import PhoneInput from "react-phone-input-2";
import CheckboxButtons from "../CheckboxButtons/CheckboxButtons";
import rolesAndPermissions from "../../utils/rolesAndPermissions";

import styles from "./employeeDataFormStyles.module.scss";
import DateSelect from "../DateSelect/DateSelect";

export const StringInput = React.memo(
  ({ label, value, onChange, style, className }) => (
    <div
      style={{ ...style }}
      className={[className, styles.fieldWrapper].join(" ")}
    >
      <span className={styles.fieldLabelWrapper}>{label}</span>
      <input
        type="text"
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        className={styles.fieldInputWrapper}
      ></input>
    </div>
  )
);

const EmployeeDataForm = ({ value, onChange, disableEmail }) => {
  const newEmployee = value;
  const startD = newEmployee.startDate
    ? new Date(newEmployee.startDate)
    : new Date();

  return (
    <div>
      {disableEmail ? (
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldLabelWrapper}>Cell Phone:</div>
          <div className={styles.fieldInputWrapper}>{newEmployee.email}</div>
        </div>
      ) : (
        <StringInput
          label="Email"
          value={newEmployee.email || ""}
          onChange={(v) => {
            const newData = { ...newEmployee, email: v };
            onChange(newData);
          }}
        ></StringInput>
      )}
      {[
        { label: "First Name: ", fieldName: "firstName" },
        { label: "Nickname: ", fieldName: "nickName" },
        { label: "Last Name: ", fieldName: "lastName" },
        { label: "Job Title: ", fieldName: "jobTitle" },
      ].map((field) => (
        <StringInput
          key={field.fieldName}
          label={field.label}
          value={newEmployee[field.fieldName] || ""}
          onChange={(v) => {
            const newData = { ...newEmployee, [field.fieldName]: v };
            onChange(newData);
          }}
        ></StringInput>
      ))}
      <div className={styles.fieldWrapper}>
        <div className={styles.fieldLabelWrapper}>Cell Phone:</div>
        <div className={styles.fieldInputWrapper}>
          <PhoneInput
            country="us"
            value={newEmployee.cellPhone}
            onChange={(v) => {
              const newData = { ...newEmployee, cellPhone: v };
              onChange(newData);
            }}
            countryCodeEditable={false}
          ></PhoneInput>
        </div>
      </div>

      <div className={styles.fieldWrapper}>
        <span className={styles.fieldLabelWrapper}>Roles:</span>
        <div
          className={[styles.fieldInputWrapper, styles.roleButtons].join(" ")}
        >
          <CheckboxButtons
            options={Object.keys(rolesAndPermissions)}
            selected={newEmployee.roles || []}
            onChange={(newRoles) => {
              const newData = { ...newEmployee, roles: newRoles };
              onChange(newData);
            }}
            style={{ margin: "0 10px 10px 0" }}
          ></CheckboxButtons>
        </div>
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.fieldLabelWrapper}>Start Date:</div>
        <div className={styles.fieldInputWrapper}>
          <DateSelect
            showYear
            defaultDate={startD}
            onChange={(d) => {
              const newData = { ...newEmployee, startDate: d };
              onChange(newData);
            }}
          ></DateSelect>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDataForm;
