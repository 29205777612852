import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import Helix from "../Helix/Helix";

import StrainSphere from "../StrainSphere/StrainSphere";

import styles from "./strainSummaryStyles.module.scss";

const StrainSummary = ({ strain, noBorder, style, mini, fontColor }) => {
  const history = useHistory();
  const chemistry = strain?.chemistry || {};
  const notes = strain?.notes || {};
  if (!strain) {
    return <div></div>;
  }
  return (
    <div
      className={[
        styles.strainContainer,
        noBorder ? styles.noBorder : null,
      ].join(" ")}
      style={{ padding: noBorder ? "0px" : mini ? "20px" : undefined }}
      onClick={() => {
        history.push(`/strains/${strain._id}`);
        window.scrollTo(0, 0);
      }}
      style={{ ...style }}
    >
      {/*<StrainSphere
        width={mini ? 110 : 200}
        height={mini ? 110 : 200}
        numSpheres={
          strain.thcContent ? (Math.floor(strain.thcContent) - 14) * 7 : null
        }
        sphereSpeed={(strain.energyRating / 1000.0) * 1.2}
        colors={
          strain.kindbioColors?.length === 0
            ? undefined
            : strain.kindbioColors?.map((color) =>
                parseInt(parseInt(color).toString(16), 16)
              )
        }
      ></StrainSphere>*/}
      <div
        className={styles.strainInfoWrapper}
        style={{ minWidth: mini ? "80px" : "200px" }}
      >
        <div
          className={[
            styles.strainHeaderWrapper,
            mini ? null : styles.underline,
          ].join(" ")}
        >
          <h2
            className={[styles.header3, "sans"].join(" ")}
            style={{
              fontSize: mini ? "1em" : "1.3em",
              maxWidth: mini ? "150px" : null,
              color: fontColor || undefined,
            }}
          >
            {strain.strainName}
          </h2>
          {/*
            <Can
              user={employeeData}
              granted={
                <div>
                  <Button
                    compact
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      history.push(`/edit-strain/${strain._id}`);
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
                  </Button>
                  <Button
                    compact
                    red
                    onClick={() => {
                      handleDelete(strain);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  </Button>
                </div>
              }
              denied={null}
            ></Can>*/}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            marginTop: "10px",
            fontSize: mini ? "0.85em" : "1em",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Helix mini={mini} color={strain.color}></Helix>
            <div style={{ paddingLeft: "10px" }}>
              {["thc", "thcv", "cbg", "cbc"].map((chemical) => {
                return (
                  <div key={chemical} style={{ color: fontColor || undefined }}>
                    <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                      {chemical.toUpperCase()}:{" "}
                    </span>
                    <span>
                      {chemistry[`${chemical}MinPercent`]
                        ? chemistry[`${chemical}MinPercent`] ===
                          chemistry[`${chemical}MaxPercent`]
                          ? `${chemistry[`${chemical}MinPercent`]} %`
                          : `${chemistry[`${chemical}MinPercent`]} - ${
                              chemistry[`${chemical}MaxPercent`]
                            } %`
                        : "-- %"}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {mini ? null : (
          <div style={{ marginTop: "10px" }}>
            <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
            {` ${notes.length || 0} `}Notes
          </div>
        )}
      </div>
    </div>
  );
};

export default StrainSummary;
