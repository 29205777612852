import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, Fragment } from "react";
import Button from "../Button/Button";

const FileInput = ({
  onChange,
  showImg,
  imgUrl,
  loading,
  children,
  compact,
}) => {
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    onChange(fileUploaded);
  };
  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };
  return (
    <Fragment>
      {showImg ? (
        <div>
          <div
            style={{
              width: 220,
              height: 220,
              backgroundImage: `url("${imgUrl}")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              border: "1px solid rgb(150,150,150)",
              borderRadius: "5px",
              marginBottom: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon style={{ fontSize: "30px" }} icon={faImage} />
          </div>
        </div>
      ) : null}

      <Button onClick={handleClick} loading={loading} compact={compact}>
        {children}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      ></input>
    </Fragment>
  );
};

export default FileInput;
