import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import LinkButton from "../LinkButton/LinkButton";
import Rating from "../Rating/Rating";

import styles from "./entryDetailStyles.module.scss";
import { faLongArrowAltLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import Button from "../Button/Button";
import PopUp from "../PopUp/PopUp";
import Loading from "../Loading";
import { Fragment } from "react";

const EntryDetail = ({ entryData, onBack, strains, style, onDelete }) => {
  const d = new Date(entryData.entryDate);
  const firebase = useContext(FirebaseContext);
  const [showPopUp, setShowPopUp] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const history = useHistory();
  const navigate = (location) => {
    history.push(location);
    window.scrollTo(0, 0);
  };

  const Label = ({ children }) => (
    <span className={styles.entryDetailLabel}>{children}</span>
  );

  const handleDelete = async (id) => {
    setDeleting(true);
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/deleteSession/${id}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });

    setDeleting(false);
    if (response.status === 200) {
      onDelete();
      if (isMounted.current) {
        setShowPopUp(false);
      }
    } else {
      setError(true);
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  return (
    <div style={{ textAlign: "left", ...style }}>
      {showPopUp ? (
        <PopUp>
          <div>
            {deleting ? (
              <Loading />
            ) : error ? (
              <div>There was an error.</div>
            ) : (
              <div>
                <div>
                  Are you sure you want to delete your entry for{" "}
                  {format(d, "MMM d")} at {format(d, "p")}?
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    style={{ margin: "5px" }}
                    onClick={() => {
                      setShowPopUp(false);
                    }}
                  >
                    No, cancel
                  </Button>
                  <Button
                    red
                    style={{ margin: "5px" }}
                    onClick={() => {
                      handleDelete(entryData._id);
                    }}
                  >
                    Yes, delete
                  </Button>
                </div>
              </div>
            )}
          </div>
        </PopUp>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LinkButton onClick={onBack}>
          <FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon> Back
        </LinkButton>
        <Button
          compact
          onClick={() => {
            setShowPopUp(true);
          }}
          red
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>
      </div>
      <div style={{ marginTop: "20px" }} className={styles.fieldWrapper}>
        <Label>Time:</Label> {format(d, "p")}
      </div>
      <div className={styles.fieldWrapper}>
        <Label>Dosage:</Label> {entryData.dosage} {entryData.units}
      </div>
      <div className={styles.fieldWrapper}>
        <Label>Consumption Method:</Label> {entryData.method}
      </div>
      {entryData.product ? (
        <Fragment>
          <div className={styles.fieldWrapper}>
            <Label>{entryData.method}:</Label>{" "}
            <LinkButton
              onClick={() => {
                //history.push(`/products/${entryData.product._id}`);
              }}
            >
              {entryData.product.name}
            </LinkButton>
          </div>
          {entryData.batchNum ? (
            <div className={styles.fieldWrapper}>
              <Label>Batch Number:</Label> {entryData.batchNum}
            </div>
          ) : null}
        </Fragment>
      ) : null}

      {entryData.strainId ? (
        <div className={styles.fieldWrapper}>
          <Label>Strain:</Label>{" "}
          <LinkButton
            onClick={() => {
              navigate(`/strains/${entryData.strainId}`);
            }}
          >
            {
              strains.find((strain) => entryData.strainId === strain._id)
                .strainName
            }
          </LinkButton>
        </div>
      ) : null}
      <div className={styles.fieldWrapper}>
        <Label>Symptom Relief:</Label>
        <ul>
          {Object.keys(entryData.symptomRatings || {}).length > 0
            ? Object.keys(entryData.symptomRatings).map((symptom) => {
                return (
                  <li key={symptom} style={{ marginLeft: "10px" }}>
                    {symptom}:{" "}
                    <span>
                      {
                        ["Worse", "No Effect", "Better"][
                          entryData.symptomRatings[symptom]
                        ]
                      }
                    </span>
                  </li>
                );
              })
            : " N/A"}
        </ul>
      </div>
      <div className={styles.fieldWrapper}>
        <Label>Side Effects:</Label>
        <ul>
          {entryData.sideEffects.map((effect) => {
            return <li key={effect}>{effect}</li>;
          })}
        </ul>
      </div>
      <div className={styles.fieldWrapper}>
        <Label>Feelings:</Label>
        <ul>
          {entryData.feelings.map((feeling) => {
            return <li key={feeling}>{feeling}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default EntryDetail;
