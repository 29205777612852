import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faCheckCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import styles from "./strainsStyles.module.scss";
import PopUp from "../../components/PopUp/PopUp";
import Loading from "../../components/Loading";
import Can from "../../components/Can/Can";
import StrainDetail from "../StrainDetail/StrainDetail";
import StrainSummary from "../../components/StrainSummary/StrainSummary";
import Container from "../../components/Container/Container";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";
import { AppDataContext } from "../../components/AppDataProvider";

const apiOrigin = "http://localhost:3001";

const DeletePopUp = (props) => {
  const firebase = useContext(FirebaseContext);
  const [deleting, setDeleting] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [error, setError] = useState(false);
  //const { getAccessTokenSilently } = useAuth0();

  const deleteStrain = async (strainId) => {
    setDeleting(true);
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/deleteStrain/${strainId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDeleting(false);
    const jsonResponse = response.json();
    if (response.status == 200) {
      setShowDeleted(true);
    } else {
      setError(jsonResponse.message);
    }
  };
  return (
    <PopUp>
      {deleting ? (
        <Loading></Loading>
      ) : showDeleted ? (
        <div>
          <h3>
            <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>Deleted
            Successfully
          </h3>
          <div style={{ marginTop: "40px" }}>
            <Button
              onClick={() => {
                props.exit();
              }}
            >
              Done
            </Button>
          </div>
        </div>
      ) : error ? (
        <div>
          <h3>Error: {error}</h3>
          <div>
            <Button
              onClick={() => {
                props.exit();
              }}
            >
              Ok.
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h3>Are you sure you want to delete {props.strain.strainName}?</h3>
          <div style={{ marginTop: "40px" }}>
            <Button
              style={{ margin: "10px" }}
              onClick={() => {
                props.exit();
              }}
            >
              No, go back.
            </Button>
            <Button
              red
              onClick={() => {
                deleteStrain(props.strain._id);
              }}
              style={{ margin: "10px" }}
            >
              Yes, delete.
            </Button>
          </div>
        </div>
      )}
    </PopUp>
  );
};

const Strains = (props) => {
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;
  const history = useHistory();
  const { id } = useParams();
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const { strains } = useContext(AppDataContext);
  const sortedStrains = strains.sort((a, b) =>
    a.strainName > b.strainName ? 1 : b.strainName > a.strainName ? -1 : 0
  );

  if (id) {
    const strain = strains.find((s) => {
      return s._id === id;
    });
    return <StrainDetail strain={strain}></StrainDetail>;
  }

  const filteredStrains = sortedStrains.filter((strain) =>
    strain.strainName.toLowerCase().includes(filterValue.toLowerCase())
  );

  return (
    <Container>
      <div>
        {showDeletePopUp ? (
          <DeletePopUp
            strain={showDeletePopUp}
            exit={() => {
              setShowDeletePopUp(false);
            }}
          ></DeletePopUp>
        ) : null}
        <div className={styles.wrapper}>
          <h2 className={styles.header2}>Strains ({filteredStrains.length})</h2>
          <Input
            value={filterValue}
            label={
              <span>
                <FontAwesomeIcon icon={faSearch} /> Search
              </span>
            }
            onChange={(v) => {
              setFilterValue(v);
            }}
          ></Input>
          <Can
            user={employeeData}
            access="strains:create"
            yes={
              <Button
                onClick={() => {
                  history.push("/add-new-strain");
                }}
                style={{ margin: "10px 0" }}
              >
                <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> Add New
                Strain
              </Button>
            }
            no={null}
          ></Can>
        </div>

        <div className={styles.strainsWrapper} style={{ marginTop: "5px" }}>
          {filteredStrains.map((strain) => {
            return (
              <div className={styles.strainWrapper} key={strain._id}>
                <StrainSummary
                  strain={strain}
                  handleDelete={() => {
                    setShowDeletePopUp(strain);
                  }}
                  noBorder
                  style={{ margin: "10px" }}
                ></StrainSummary>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Strains;
