import { faClock, faCloudMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useState } from "react";
import { range } from "../../utils/helpers";
import styles from "./timeSelectStyles.module.scss";

const TimeOption = ({ selected, val, onClick }) => {
  return (
    <div
      key={val}
      className={[styles.fieldOption, selected ? styles.selected : null].join(
        " "
      )}
      onClick={onClick}
    >
      {val}
    </div>
  );
};

const TimeSelect = ({
  style,
  currentTime = format(new Date(), "p"),
  onSet,
}) => {
  const h = parseInt(currentTime.split(":")[0]);
  const m = parseInt(currentTime.split(":")[1].split(" ")[0]);
  const a = currentTime.split(":")[1].split(" ")[1];
  const [hour, setHour] = useState(h);
  const [minutes, setMinutes] = useState(m);
  const [amPm, setAmPm] = useState(a);
  const [showDropdown, setShowdropdown] = useState(false);

  const formattedMinutes = `${minutes < 10 ? "0" : ""}${minutes}`;
  const formattedTime = `${hour}:${formattedMinutes} ${amPm}`;

  const changed = currentTime !== formattedTime;

  return (
    <div style={{ position: "relative", display: "inline-block", ...style }}>
      <button
        className={styles.wrapper}
        style={{ ...style }}
        onClick={() => {
          setShowdropdown(!showDropdown);
        }}
      >
        <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
        <span style={{ marginLeft: "10px" }}>{currentTime}</span>
      </button>
      {showDropdown ? (
        <div className={styles.dropdownWrapper}>
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid rgb(200,200,200)",
              padding: "10px 0",
            }}
          >
            <div style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}>
              H
            </div>
            <div style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}>
              M
            </div>
            <div style={{ flex: "1", textAlign: "center" }}>
              <FontAwesomeIcon icon={faCloudMoon}></FontAwesomeIcon>
            </div>
          </div>
          <div className={styles.selections}>
            <div className={styles.field}>
              {range(12).map((i) => (
                <TimeOption
                  key={i}
                  val={i + 1}
                  selected={hour === i + 1}
                  onClick={() => {
                    setHour(i + 1);
                  }}
                />
              ))}
            </div>
            <div className={styles.field}>
              {[0, 15, 30, 45].map((i) => (
                <TimeOption
                  key={i}
                  val={`${i < 10 ? "0" : ""}${i}`}
                  selected={minutes === i}
                  onClick={() => {
                    setMinutes(i);
                  }}
                />
              ))}
            </div>
            <div className={styles.field}>
              {["AM", "PM"].map((v) => (
                <TimeOption
                  key={v}
                  val={v}
                  selected={amPm === v}
                  onClick={() => {
                    setAmPm(v);
                  }}
                />
              ))}
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            <span
              className={[
                styles.newTime,
                changed ? styles.changed : styles.unchanged,
              ].join(" ")}
            >
              {`${hour}:${minutes < 10 ? "0" : ""}${minutes}`}
              <span style={{ fontSize: "0.9em" }}> {amPm}</span>
            </span>

            <button
              className={styles.button}
              onClick={() => {
                onSet({
                  f12: formattedTime,
                  f24: `${
                    amPm === "AM" ? hour % 12 : (hour % 12) + 12
                  }:${formattedMinutes}`,
                });
                setShowdropdown(false);
              }}
            >
              Set
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TimeSelect;
