import React from "react";

const DataComparison = ({ header, original, updated }) => {
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>{header}</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "50%", maxWidth: "50%" }}>
          <h4 style={{ textAlign: "center" }}>Original:</h4>
          <br />
          <br />
          <pre>{JSON.stringify(original, null, 2)}</pre>
        </div>
        <div style={{ flex: "50%", maxWidth: "50%" }}>
          <h4 style={{ textAlign: "center" }}>Updated:</h4>
          <br />
          <br />
          <pre>{JSON.stringify(updated, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

export default DataComparison;
