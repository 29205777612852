import React, { useRef, useState } from "react";
import Container from "../../components/Container/Container";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";
import Button from "../../components/Button/Button";
import styles from "./textEditorStyles.module.scss";

const TextEditor = ({
  onChange = (s) => {
    console.log(s);
  },
  placeholder = "Text (optional)",
  compact,
  content,
  isMobile,
}) => {
  const editorRef = useRef();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  //console.log(convertToRaw(editorState.getCurrentContent()));

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onUnderlineClick = () => {
    setEditorState((s) => {
      return RichUtils.toggleInlineStyle(s, "UNDERLINE");
    });
  };

  const onBoldClick = () => {
    setEditorState((s) => {
      return RichUtils.toggleInlineStyle(s, "BOLD");
    });
  };

  const onItalicClick = () => {
    setEditorState((s) => {
      return RichUtils.toggleInlineStyle(s, "ITALIC");
    });
  };

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <Button onClick={onUnderlineClick} compact className={styles.button}>
          <span style={{ textDecoration: "underline" }}>U</span>
        </Button>
        <Button onClick={onBoldClick} compact className={styles.button}>
          <b>B</b>
        </Button>
        <Button onClick={onItalicClick} compact className={styles.button}>
          <em>I</em>
        </Button>
      </div>
      <div
        className={[
          styles.editorWrapper,
          compact ? styles.compact : null,
          isMobile ? styles.isMobile : null,
        ].join(" ")}
        onClick={() => {
          editorRef.current.focus();
        }}
      >
        <Editor
          ref={editorRef}
          editorState={content || editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={(s) => {
            setEditorState(s);
            onChange(s);
          }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default TextEditor;
