import {
  faChartBar,
  faLeaf,
  faPencilAlt,
  faSeedling,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { colors } from "../../utils/constants";

const MobileNavItem = ({ children, icon, label, location, active }) => {
  const history = useHistory();
  return (
    <button
      style={{
        padding: "0 15px",
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
      }}
      onClick={() => {
        history.push(location);
      }}
    >
      <div>
        <FontAwesomeIcon
          icon={icon}
          style={{ color: active ? colors[3] : "rgb(100,100,100)" }}
        />
      </div>
      <div style={{ fontSize: "10px", color: active ? colors[3] : colors[4] }}>
        {label}
      </div>
    </button>
  );
};

const MobileNav = () => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: "60px",
        backgroundColor: "white",
        borderTop: `2px solid ${colors[0]}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        zIndex: 99,
      }}
    >
      <MobileNavItem
        icon={faPencilAlt}
        label={"JOURNAL"}
        location={"/dosage-journal"}
        active={
          location.pathname.includes("dosage-journal") &&
          !location.pathname.includes("data-and-insights")
        }
      ></MobileNavItem>
      <MobileNavItem
        icon={faChartBar}
        label={"INSIGHTS"}
        location={"/dosage-journal/data-and-insights"}
        active={location.pathname.includes("data-and-insights")}
      ></MobileNavItem>
      <MobileNavItem
        icon={faSeedling}
        label={"STRAINS"}
        location={"/strains"}
        active={location.pathname.includes("strains")}
      ></MobileNavItem>
    </div>
  );
};

export default MobileNav;
