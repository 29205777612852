import React, { useState, useEffect, useContext, Fragment } from "react";
import styles from "./strainDetailStyles.module.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import Can from "../../components/Can/Can";
import StrainNotes from "../../components/StrainNotes/StrainNotes";
import { HorizontalBar } from "react-chartjs-2";
import Container from "../../components/Container/Container";
import KPI from "../../components/KPI/KPI";
import colors from "nice-color-palettes";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Helix from "../../components/Helix/Helix";
import StackBanner from "../../components/StackBanner/StackBanner";
import { AppDataContext } from "../../components/AppDataProvider";

const StrainDetail = ({ strain }) => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;
  const [journalData, setJournalData] = useState({});

  const { reloadData } = useContext(AppDataContext);

  // Helper function to fetch journal data
  const getJournalData = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(
      `/api/getJournalDataByStrain?id=${strain._id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  // Helper function to create a strain note
  const createStrainNote = async (noteContent) => {
    const note = {
      firebaseId: employeeData.firebaseId,
      author: `${employeeData.firstName} ${employeeData.lastName}`,
      text: noteContent || undefined,
    };
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/createStrainNote/${strain._id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(note),
    });
    const jsonResponse = await response.json();
    reloadData();
    return jsonResponse;
  };

  // Helper function to delete a strain note
  const deleteNote = async (noteId) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(
      `/api/deleteStrainNote/${strain._id}/${noteId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsonResponse = await response.json();
    reloadData();
    return jsonResponse;
  };

  // Fetch journal data on component mount
  useEffect(() => {
    let subscribed = true;

    getJournalData().then((data) => {
      if (subscribed) {
        console.log(data);
        setJournalData(data.allUsers);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  // Chart data and options
  const feelingsData = {
    labels: [...Object.keys(journalData.feelings || {})],
    datasets: [
      {
        label: "Avg Rating (out of 5)",
        backgroundColor: [...colors[9], ...colors[11]],

        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Object.keys(journalData.feelings || {}).map(
          (feeling) => journalData.feelings[feeling]
        ),
      },
    ],
  };

  const effectsData = {
    labels: [
      ...Object.keys(journalData.sideEffects || {}).filter(
        (key) => parseFloat(journalData.sideEffects[key]) > 0
      ),
    ],
    datasets: [
      {
        label: "Avg Rating (out of 5)",
        backgroundColor: [...colors[0], ...colors[1]],
        data: Object.keys(journalData.sideEffects || {})
          .filter((key) => parseFloat(journalData.sideEffects[key]) > 0)
          .map((effect) => journalData.sideEffects[effect]),
      },
    ],
  };

  const symptomsData = {
    labels: [...Object.keys(journalData.symptoms || {})],
    datasets: [
      {
        label: "Avg Rating (out of 5)",
        backgroundColor: [...colors[2], ...colors[3]],

        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Object.keys(journalData.symptoms || {}).map(
          (effect) => journalData.symptoms[effect]
        ),
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    showScale: true,
    pointDot: true,
    showLines: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 5,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 1,
          },
        },
      ],
    },
  };

  const { chemistry, notes } = strain;

  return (
    <Fragment>
      <StackBanner
        data={[
          { label: "Strains", location: "/strains" },
          { label: strain.strainName },
        ]}
      />
      <Container>
        <div className={styles.layout}>
          <div className={styles.leftWrapper}>
            <div className={styles.headerWrapper}>
              <h1 className={[styles.strainHeader].join(" ")}>
                {strain.strainName}
              </h1>
              <Can
                user={employeeData}
                access="strains:update"
                yes={
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "0px",
                    }}
                  >
                    <Button
                      compact
                      style={{ marginRight: "5px" }}
                      onClick={() => {
                        history.push(`/edit-strain/${strain._id}`);
                      }}
                    >
                      <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
                    </Button>
                    <Button compact red>
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </Button>
                  </div>
                }
                no={null}
              ></Can>
            </div>
            <div
              className={styles.chemistryWrapper}
              style={{ marginTop: "20px" }}
            >
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                <Helix color={strain.color}></Helix>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                {["thc", "thcv", "cbg", "cbc"].map((field) => {
                  const minPercent = chemistry[`${field}MinPercent`];
                  const maxPercent = chemistry[`${field}MaxPercent`];
                  const displayValue =
                    minPercent === maxPercent
                      ? `${
                          minPercent === null || minPercent === undefined
                            ? "--"
                            : minPercent
                        } %`
                      : `${minPercent} - ${maxPercent} %`;
                  return (
                    <div key={field}>
                      <h3 className={styles.fieldLabel}>
                        {field.toUpperCase()}:
                      </h3>
                      <span style={{ marginLeft: "20px" }}>{displayValue}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.bodyWrapper}>
              <div style={{ flex: "70%", paddingRight: "20px" }}>
                <h3
                  style={{
                    borderBottom: "1px solid rgb(200,200,200)",
                    padding: "21px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Employee Journal Data
                </h3>
                <div className={styles.journalWrapper}>
                  <KPI label="Total Sessions" val={journalData.totalSessions} />

                  <KPI label="Overall Rating" val={journalData.overallRating} />
                </div>
                <h3 style={{ marginTop: "30px" }}>Feelings</h3>

                <div style={{ minHeight: "500px", margin: "30px 0" }}>
                  <HorizontalBar
                    data={feelingsData}
                    options={{
                      ...chartOptions,
                    }}
                  ></HorizontalBar>
                </div>
                <h3>Symptom Relief</h3>
                <div style={{ minHeight: "500px", margin: "30px 0" }}>
                  <HorizontalBar
                    data={symptomsData}
                    options={{
                      ...chartOptions,
                    }}
                  ></HorizontalBar>
                </div>
                <h3>Side Effects</h3>
                <div style={{ minHeight: "500px", margin: "30px 0" }}>
                  <HorizontalBar
                    data={effectsData}
                    options={{
                      ...chartOptions,
                    }}
                  ></HorizontalBar>
                </div>
              </div>
            </div>
          </div>
          <StrainNotes
            data={notes}
            style={{ flex: "30%", paddingLeft: "20px" }}
            handleSave={createStrainNote}
            handleDeleteNote={(noteId) => {
              deleteNote(noteId);
            }}
          ></StrainNotes>
        </div>
      </Container>
    </Fragment>
  );
};

export default StrainDetail;
