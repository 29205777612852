import React, { useState, useEffect, useReducer, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
//import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import rolesAndPermissions from "../../utils/rolesAndPermissions";
import Button from "../../components/Button/Button";
import CheckboxButtons from "../../components/CheckboxButtons/CheckboxButtons";
import DataComparison from "../../components/DataComparison/DataComparison";
import ColorDots from "../../components/ColorDots/ColorDots";

import styles from "./adminEditEmployeeStyles.module.scss";
import LinkButton from "../../components/LinkButton/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import Container from "../../components/Container/Container";
import PhoneInput from "react-phone-input-2";
import EmployeeDataForm from "../../components/EmployeeDataForm/EmployeeDataForm";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";

const AdminEditEmployee = (props) => {
  let { id } = useParams();
  const firebase = useContext(FirebaseContext);
  const [employee, setEmployee] = useState(false);
  const [newEmployee, setNewEmployee] = useState(false);
  const [updatingEmployee, setUpdatingEmployee] = useState(false);
  const [loading, setLoading] = useState(true);
  //const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const getEmployee = async (firebaseId) => {
    setLoading(true);
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);

    const response = await fetch(`/api/getEmployee/${firebaseId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();

    return jsonResponse;
  };

  useEffect(() => {
    let subscribed = true;
    getEmployee(id).then((response) => {
      if (subscribed) {
        setLoading(false);
        setEmployee(response);
        setNewEmployee(response);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  const handleSave = async (data) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/updateEmployee/${data.firebaseId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response;
  };

  if (loading || updatingEmployee) {
    return <Loading></Loading>;
  }

  return (
    <Container style={{ padding: "0 20px 50px 20px" }}>
      <div>
        <LinkButton
          onClick={() => {
            history.push("/employees");
          }}
          style={{ margin: "30px 0" }}
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon> Back to
          Employees
        </LinkButton>
        <div style={{ textAlign: "center" }}>
          <EmployeePhoto
            imgLocation={employee.imgLocation}
            style={{ margin: "0 auto" }}
          />
          <h1 style={{ marginTop: "20px" }}>
            {employee.firstName} {employee.lastName}
          </h1>
          <ColorDots style={{ margin: "30px 0" }}></ColorDots>
        </div>
        <div style={{ maxWidth: "600px", margin: "10px auto 30px auto" }}>
          <EmployeeDataForm
            value={newEmployee}
            onChange={(d) => {
              setNewEmployee(d);
            }}
            disableEmail
          ></EmployeeDataForm>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            handleSave(newEmployee).then((response) => {
              setUpdatingEmployee(true);
              if (response.status == 200) {
                window.scrollTo(0, 0);
                history.push("/employees");
              } else {
                setUpdatingEmployee(false);
                console.log("error");
              }
            });
          }}
        >
          Save Changes
        </Button>
      </div>
      <hr style={{ margin: "70px 0" }} />
      <DataComparison
        header="Raw Employee Data"
        original={employee}
        updated={newEmployee}
      ></DataComparison>
    </Container>
  );
};

export default AdminEditEmployee;
