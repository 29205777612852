import React, { useState, useEffect, useContext, Fragment } from "react";

import { BarGraph } from "../Feelings/Feelings";

const SymptomRelief = ({
  data,
  journalData,
  strains,
  style,
  renderChart,
  refProp,
}) => {
  if (data) {
    return (
      <BarGraph
        data={data}
        strains={strains}
        refProp={refProp}
        renderChart={renderChart}
        header={"Symptom Relief"}
        subHeader={"Compare strains by how well they treat various symptoms."}
        style={{ ...style }}
      />
    );
  }

  return <div ref={refProp}>nothing</div>;
};

export default SymptomRelief;
