import React from "react";
import styles from "./kpiStyles.module.scss";

const KPI = ({ val, label, className, style, color }) => {
  return (
    <div className={[styles.kpi, className].join(" ")} style={{ ...style }}>
      <div className={styles.kpiValue} style={{ color: color || undefined }}>
        {val}
      </div>
      <div className={styles.kpiLabel} style={{ color: color || undefined }}>
        {label.toUpperCase()}
      </div>
    </div>
  );
};

export default KPI;
