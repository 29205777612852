import React from "react";

const ProgressUnderline = ({ currentStep, totalSteps }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        height: "5px",
        margin: "9px 0",
      }}
    >
      <div
        style={{
          flex: `${((currentStep / totalSteps) * 100).toFixed(2)}%`,
          backgroundColor: "#007A39",
        }}
      ></div>
      <div
        style={{
          flex: `${(((totalSteps - currentStep) / totalSteps) * 100).toFixed(
            2
          )}%`,
          backgroundColor: "#E0E0E1",
        }}
      ></div>
    </div>
  );
};

export default ProgressUnderline;
