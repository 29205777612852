import React from "react";
import styles from "./radioButtonStyles.module.scss";

const RadioButtons = ({ options, onChange, selected }) => {
  if (selected === undefined) selected = options[0];
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {options.map((option, i) => {
        return (
          <button
            key={option}
            style={{
              borderRadius:
                i === 0
                  ? "5px 0 0 5px"
                  : i === options.length - 1
                  ? "0 5px 5px 0"
                  : "0",
              borderLeft: i === 0 ? "2px solid black" : "none",
            }}
            onClick={() => {
              onChange(option);
            }}
            className={[
              styles.button,
              selected === option ? styles.selected : null,
            ].join(" ")}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

export default RadioButtons;
