import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import CheckboxButtons from "../../components/CheckboxButtons/CheckboxButtons";
import LinkButton from "../../components/LinkButton/LinkButton";

const QuestionOne = ({ handleNext }) => {
  const [selectedCondition, setSelectedCondition] = useState();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "500px" }}>
        <h2 style={{ margin: "10px 0 20px 0" }}>
          What is the qualifying condition that awarded you a medical cannabis
          license?
        </h2>
        <CheckboxButtons
          radio
          style={{ margin: "0 7px 7px 0" }}
          selected={selectedCondition}
          onChange={(v) => {
            setSelectedCondition(v);
          }}
          options={[
            "Cancer",
            "Glaucoma",
            "Epilepsy",
            "PTSD",
            "Migraines",
            "Multiple Sclerosis",
            "Seizures",
            "Parkinson's",
            "Tourettes",
            "Psychiatric Disorder",
            "HIV/AIDS",
            "Medication Dependency",
            "Terminal Illness",
            "Physician Prognosis",
            "Hepatitis C",
            "Amyotrophic Lateral Sclerosis",
            "Inflammatory Bowel Disease",
            "Huntington's Disease",
            "Autism",
            "Neuropathies",
            "Sickle Cell Anemia",
            "Alzheimer's Disease",
            "Cachexia",
            "Wasting Syndrome",
          ]}
        ></CheckboxButtons>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            solid
            style={{ minWidth: "150px" }}
            disabled={!selectedCondition}
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

const QuestionTwo = ({ handleNext }) => {
  const [selectedConditions, setSelectedConditions] = useState([]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "500px" }}>
        <h2 style={{ margin: "10px 0 20px 0" }}>
          What are the conditions in your life that you are treating with
          medication on a daily basis?
        </h2>
        <CheckboxButtons
          style={{ margin: "0 7px 7px 0" }}
          selected={selectedConditions}
          onChange={(v) => {
            setSelectedConditions(v);
          }}
          options={[
            "Anxiety",
            "Depression",
            "Addiction",
            "Nausea",
            "Vomiting",
            "Weight Loss",
            "Weight Gain",
            "Insomnia",
            "Fatigue",
            "Muscle Pain",
            "Joint Pain",
            "Diarrhea",
            "Constipation",
            "Headaches",
            "Seizures",
            "Stress",
            "ADHD",
            "Cramps",
            "Inflammation",
          ]}
        ></CheckboxButtons>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            solid
            style={{ minWidth: "150px" }}
            disabled={!selectedConditions}
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

const QuestionThree = ({ handleNext }) => {
  const [selectedConditions, setSelectedConditions] = useState([]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "500px" }}>
        <h2 style={{ margin: "10px 0 20px 0" }}>
          Which types of effects do you prefer from your cannabis medication?
          Choose up to three.
        </h2>
        <CheckboxButtons
          style={{ margin: "0 7px 7px 0" }}
          selected={selectedConditions}
          onChange={(v) => {
            setSelectedConditions(v);
          }}
          options={[
            "Relaxed",
            "Energized",
            "Creative",
            "Focused",
            "Talkative",
            "Aroused",
            "Carefree",
            "Sleepy",
          ]}
        ></CheckboxButtons>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            solid
            style={{ minWidth: "150px" }}
            disabled={!selectedConditions}
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

const Conclusion = () => {
  const history = useHistory();
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ marginTop: "50px" }}>
        Your Kindbio Color is <span style={{ color: "red" }}>Red</span>.
      </h1>
      <LinkButton
        style={{ marginTop: "50px" }}
        onClick={() => {
          history.push("/");
        }}
      >
        Go to my homepage.
      </LinkButton>
    </div>
  );
};

const OnboardingDemo = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  return [
    <QuestionOne
      handleNext={() => {
        setCurrentQuestion(1);
      }}
    />,
    <QuestionTwo
      handleNext={() => {
        setCurrentQuestion(2);
      }}
    />,
    <QuestionThree
      handleNext={() => {
        setCurrentQuestion(3);
      }}
    />,
    <Conclusion />,
  ][currentQuestion];
};

export default OnboardingDemo;
