import React, { useContext, useEffect, useState } from "react";
import AddNewStrain from "../AddNewStrain/AddNewStrain";
import { useHistory, useParams } from "react-router-dom";
//import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import DataComparison from "../../components/DataComparison/DataComparison";
import LinkButton from "../../components/LinkButton/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import StrainSphere from "../../components/StrainSphere/StrainSphere";
import ColorDots from "../../components/ColorDots/ColorDots";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import Button from "../../components/Button/Button";
import CheckboxButtons from "../../components/CheckboxButtons/CheckboxButtons";

import styles from "./editStrainStyles.module.scss";
import Container from "../../components/Container/Container";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Helix from "../../components/Helix/Helix";

const ChemistryField = ({
  label,
  minVal,
  maxVal,
  onMinChange,
  onMaxChange,
  onChange,
  style,
}) => {
  const [isRange, setIsRange] = useState(minVal !== maxVal);
  const handleRangeChange = (v) => {
    if (v === "Yes") {
      setIsRange(true);
    } else {
      onMaxChange(minVal);
      setIsRange(false);
    }
  };

  return (
    <div style={{ ...style }} className={styles.chemistryFieldWrapper}>
      <div style={{ minWidth: "10%" }} className={styles.chemistryFieldLabel}>
        {label}:
      </div>
      <div style={{ minWidth: "250px" }}>
        {isRange ? (
          <span>
            <input
              type="text"
              value={minVal !== null && minVal !== undefined ? minVal : ""}
              onChange={(e) => {
                onMinChange(e.currentTarget.value);
              }}
              style={{ display: "inline-block", maxWidth: "100px" }}
            ></input>{" "}
            % -{" "}
            <input
              type="text"
              value={maxVal !== null && maxVal !== undefined ? maxVal : ""}
              onChange={(e) => {
                onMaxChange(e.currentTarget.value);
              }}
              style={{ display: "inline-block", maxWidth: "100px" }}
            ></input>{" "}
            %
          </span>
        ) : (
          <span style={{ minWidth: "250px" }}>
            <input
              type="text"
              value={minVal !== null && minVal !== undefined ? minVal : ""}
              onChange={(e) => {
                const v = e.currentTarget.value;
                onChange(v);
              }}
              style={{
                display: "inline-block",
                maxWidth: "100px",
              }}
            ></input>{" "}
            %
          </span>
        )}
      </div>
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "0.9em",
            textAlign: "center",
          }}
        >
          Range?
        </div>
        <ToggleButton
          options={["Yes", "No"]}
          onChange={handleRangeChange}
          selected={isRange ? "Yes" : "No"}
        ></ToggleButton>
      </div>
    </div>
  );
};

const EditStrain = (props) => {
  let { id } = useParams();
  const [strain, setStrain] = useState(false);
  const [newStrain, setNewStrain] = useState(false);
  const [loading, setLoading] = useState(false);
  //const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const firebase = useContext(FirebaseContext);

  const getStrain = async (strainId) => {
    setLoading(true);
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getStrain/${strainId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();

    return jsonResponse;
  };

  useEffect(() => {
    let subscribed = true;
    getStrain(id).then((response) => {
      if (subscribed) {
        setLoading(false);
        setStrain(response[0]);
        setNewStrain(response[0]);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  const saveStrain = async (data) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/updateStrain/${data._id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response;
  };

  if ((id && !strain) || (id && !newStrain)) {
    return <Loading></Loading>;
  }

  return (
    <Container>
      <LinkButton
        onClick={() => {
          history.push(`/strains/${id}`);
        }}
      >
        <FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon> Back to
        Strain
      </LinkButton>
      <div style={{ textAlign: "center" }}>
        <Helix color={newStrain.color?.toLowerCase()}></Helix>
      </div>
      <h1 style={{ textAlign: "center" }}>{strain.strainName}</h1>
      <ColorDots style={{ margin: "30px 0" }}></ColorDots>
      <hr />
      <div className={styles.wrapper}>
        <div
          style={{
            flex: "50%",
            paddingRight: "20px",
          }}
        >
          <h2>Strain Details</h2>
          <div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
              }}
            >
              <span style={{ minWidth: "15%" }}>Name:</span>
              <input
                type="text"
                value={newStrain.strainName}
                onChange={(e) => {
                  setNewStrain({
                    ...newStrain,
                    strainName: e.currentTarget.value,
                  });
                }}
              ></input>
            </div>
            <div style={{ marginTop: "20px", display: "flex" }}>
              <span style={{ minWidth: "15%" }}>Color:</span>
              <CheckboxButtons
                radio
                options={["Green", "Blue", "Red", "Yellow", "Violet"]}
                selected={newStrain.color}
                onChange={(v) => {
                  setNewStrain({ ...newStrain, color: v });
                }}
                style={{ marginRight: "5px" }}
              ></CheckboxButtons>
            </div>
          </div>
        </div>
        <div style={{ flex: "50%" }} className={styles.chemistryWrapper}>
          <h2>Chemistry Data:</h2>
          <div>
            {[
              {
                label: "THC",
                minField: "thcMinPercent",
                maxField: "thcMaxPercent",
              },
              {
                label: "THCV",
                minField: "thcvMinPercent",
                maxField: "thcvMaxPercent",
              },
              {
                label: "CBG",
                minField: "cbgMinPercent",
                maxField: "cbgMaxPercent",
              },
              {
                label: "CBC",
                minField: "cbcMinPercent",
                maxField: "cbcMaxPercent",
              },
            ].map((field, i) => {
              return (
                <ChemistryField
                  key={field.label}
                  label={field.label}
                  minVal={newStrain.chemistry[field.minField]}
                  maxVal={newStrain.chemistry[field.maxField]}
                  onMinChange={(val) => {
                    setNewStrain({
                      ...newStrain,
                      chemistry: {
                        ...newStrain.chemistry,
                        [field.minField]: val,
                      },
                    });
                  }}
                  onMaxChange={(v) => {
                    setNewStrain({
                      ...newStrain,
                      chemistry: {
                        ...newStrain.chemistry,
                        [field.maxField]: v,
                      },
                    });
                  }}
                  onChange={(v) => {
                    setNewStrain({
                      ...newStrain,
                      chemistry: {
                        ...newStrain.chemistry,
                        [field.minField]: v,
                        [field.maxField]: v,
                      },
                    });
                  }}
                  style={{
                    marginTop: "20px",
                    borderTop: i > 0 ? "1px solid rgb(100,100,100)" : "none",
                    paddingTop: i > 0 ? "20px" : "none",
                  }}
                ></ChemistryField>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            saveStrain(newStrain).then((response) => {
              if (response.status == 200) {
                history.push(`/strains/${strain._id}`);
              }
            });
          }}
          style={{ margin: "50px auto" }}
        >
          Save Changes
        </Button>
      </div>
      <hr />
      <DataComparison
        header="Raw Strain Data"
        original={strain}
        updated={newStrain}
      ></DataComparison>
    </Container>
  );
};

export default EditStrain;
