import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { FirebaseContext } from "./FirebaseProvider/FirebaseProvider";

export const AppDataContext = createContext();

const AppDataProvider = ({ children }) => {
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [value, setValue] = useState({
    mySessions: null,
    strains: null,
    reloadData: () => {
      setReloadTrigger((v) => !v);
    },
  });
  const firebase = useContext(FirebaseContext);
  const { isLoading, isAuthenticated } = firebase;
  const isMounted = useRef(true);

  const getMySessions = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getMySessions`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  const getStrains = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getStrains`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  useEffect(() => {
    console.log(isLoading);
    if (!isLoading && isAuthenticated) {
      console.log("this runs");
      console.log(firebase);
      getMySessions().then((sessions) => {
        console.log("this too");
        if (isMounted.current) {
          console.log("this three");
          setValue((v) => ({
            ...v,
            mySessions: sessions.sort((a, b) => a.entryDate < b.entryDate),
          }));
        }
      });
      getStrains().then((strains) => {
        if (isMounted.current) {
          setValue((v) => ({ ...v, strains: strains }));
        }
      });
    }
  }, [isLoading, reloadTrigger, isAuthenticated]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
  );
};

export default AppDataProvider;
