import React from "react";
import styles from "./clickableListStyles.module.scss";

const ClickableListItem = ({
  children,
  onClick,
  className,
  style,
  noShadow,
}) => {
  return (
    <div
      className={[
        styles.entryBox,
        className,
        noShadow ? styles.noShadow : null,
      ].join(" ")}
      onClick={onClick}
      style={{ ...style }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          minHeight: "100%",
          flex: 1,
        }}
      >
        <div className={styles.colorBar}></div>
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    </div>
  );
};

export default ClickableListItem;
