import React from "react";
import {
  faCircle,
  faCookieBite,
  faEyeDropper,
  faJoint,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconMap = {
  smoke: faJoint,
  edible: faCookieBite,
  tincture: faEyeDropper,
  vape: faWind,
};

const MethodIcon = ({ method, style, className }) => {
  const methodKey = method.toLowerCase();
  return (
    <FontAwesomeIcon
      style={{ color: "inherit", ...style }}
      className={className}
      icon={methodKey in iconMap ? iconMap[methodKey] : faCircle}
    />
  );
};

export default MethodIcon;
