import React, { useEffect, useRef, useState } from "react";
import styles from "./insightStyles.module.scss";
import SymptomRelief from "../../components/SymptomRelief/SymptomRelief";
import Feelings from "../../components/Feelings/Feelings";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import graph from "../../assets/animations/graphs.json";
import KPI from "../../components/KPI/KPI";
import { Doughnut } from "react-chartjs-2";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import StrainSummary from "../../components/StrainSummary/StrainSummary";
import Loading from "../../components/Loading";
import Anime from "react-anime";

const Header = ({ data, selectedUsers, onChangeSelectedUsers }) => {
  const chartOptions = {
    maintainAspectRatio: false,
    showScale: true,
    pointDot: true,
    showLines: false,
    legend: {
      display: false,
    },
  };

  return (
    <div className={styles.headerWrapper}>
      <div>
        <div
          style={{ display: "flex", margin: "0 auto", alignItems: "center" }}
        >
          <Player
            autoplay
            loop={false}
            speed={2}
            src={graph}
            style={{
              height: "200px",
              width: "200px",
            }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
          <div className={styles.headerTextWrapper}>
            <h1 style={{ textAlign: "left", maxWidth: "200px" }}>
              DOSAGE JOURNAL
            </h1>
            <h2>Insights</h2>
          </div>
        </div>
        <div className={styles.radioWrapper}>
          <p
            style={{
              fontSize: "0.9em",
              fontWeight: "bold",
              color: "rgb(100,100,100)",
            }}
          >
            SHOWING DATA FOR:
          </p>
          <RadioButtons
            options={["All Users", "Just Me"]}
            selected={selectedUsers}
            onChange={(v) => {
              onChangeSelectedUsers(v);
            }}
          ></RadioButtons>
        </div>
      </div>
      <div className={styles.kpiWrapper}>
        <div className={styles.kpiSubWrapper}>
          <Anime
            duration={1000}
            easing={"easeOutQuad"}
            opacity={[0, 1]}
            translateX={[15, 0]}
          >
            <KPI
              label={"Total Sessions"}
              val={data.edibleSessions + data.smokeSessions}
              color="white"
            ></KPI>
          </Anime>
          <Anime
            duration={1000}
            easing={"easeOutQuad"}
            opacity={[0, 1]}
            translateX={[25, 0]}
          >
            <div style={{ padding: "0 10px" }}>
              <p className={styles.kpiHeader}>METHODS</p>

              <div
                style={{
                  maxWidth: "130px",
                  padding: "0 10px",
                }}
              >
                <Doughnut
                  data={{
                    labels: ["Smoke", "Edible"],
                    datasets: [
                      {
                        label: "Avg Rating (out of 5)",
                        backgroundColor: ["#36A2EB", "#FFCE56", "#36EBA2"],
                        hoverBackgroundColor: "#ffd2d2",
                        data: [data.smokeSessions, data.edibleSessions],
                      },
                    ],
                  }}
                  options={chartOptions}
                />
              </div>
            </div>
          </Anime>
        </div>
        <div className={styles.kpiSubWrapper}>
          <Anime
            duration={1000}
            easing={"easeOutQuad"}
            opacity={[0, 1]}
            translateX={[40, 0]}
          >
            <div style={{ padding: "0 10px" }}>
              <p className={styles.kpiHeader}>MOST SMOKED</p>
              <StrainSummary mini strain={data.mostSmoked}></StrainSummary>
            </div>
          </Anime>
          <Anime
            duration={1000}
            easing={"easeOutQuad"}
            opacity={[0, 1]}
            translateX={[60, 0]}
          >
            <div style={{ padding: "0 10px" }}>
              <p className={styles.kpiHeader}>HIGHEST RATED</p>
              <StrainSummary mini strain={data.overall}></StrainSummary>
            </div>
          </Anime>
        </div>
      </div>
    </div>
  );
};

const Insights = ({ data, strains, loading }) => {
  const [selectedUsers, setSelectedUsers] = useState("All Users");
  const feelingsRef = useRef();
  const symptomsRef = useRef();
  const { allUsers, justMe } = data;

  console.log("allUsers");
  console.log(allUsers);

  console.log("justMe");
  console.log(justMe);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Header
        strains={strains}
        data={selectedUsers === "All Users" ? allUsers : justMe}
        selectedUsers={selectedUsers}
        onChangeSelectedUsers={(v) => {
          setSelectedUsers(v);
        }}
      ></Header>
      <Anime
        duration={1000}
        easing={"easeOutQuad"}
        opacity={[0, 1]}
        translateY={[20, 0]}
      >
        <div className={styles.chartsWrapper}>
          <Feelings
            data={
              selectedUsers === "All Users"
                ? allUsers.feelings
                : justMe.feelings
            }
            strains={strains}
            refProp={feelingsRef}
            style={{ padding: "20px" }}
          />

          {/*<SymptomHighlightSlide data={smokeData} strains={strains} />*/}

          <SymptomRelief
            data={
              selectedUsers === "All Users"
                ? allUsers.symptoms
                : justMe.symptoms
            }
            strains={strains}
            refProp={symptomsRef}
            style={{ padding: "20px" }}
          />
        </div>
      </Anime>
    </div>
  );
};

export default Insights;
