import React from "react";

const PhoneNumber = ({ num }) => {
  const phoneNum = num.toString();
  if (phoneNum.charAt(0) === "1" && phoneNum.length === 11) {
    return (
      <span>{`(${phoneNum.substring(1, 4)}) ${phoneNum.substring(
        4,
        7
      )}-${phoneNum.substring(7, 11)}`}</span>
    );
  }
  return <span>--</span>;
};

export default PhoneNumber;
