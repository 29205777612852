import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";
import styles from "./signInContainerStyles.module.scss";
import logo from "../../assets/logo-no-tag.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "../LinkButton/LinkButton";

const SignInContainer = ({ onSuccess, noBorder }) => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [magicLink, setMagicLink] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const handleSignIn = () => {
    if (magicLink) {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: "https://employee.kindbio.com",
        // This must be true.
        handleCodeInApp: true,
        /*iOS: {
          bundleId: 'com.example.ios'
        },
        android: {
          packageName: 'com.example.android',
          installApp: true,
          minimumVersion: '12'
        },
        dynamicLinkDomain: 'example.page.link'*/
      };
      firebase.auth
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function () {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          console.log("link was sent");
          setLinkSent(true);
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          console.error(error);
        });
    } else {
      firebase.setLoading(true);
      firebase.auth
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          history.push("/");
          window.scrollTo(0, 0);
          onSuccess(response);
        })
        .catch((err) => {
          firebase.setLoading(false);
          firebase.setErrorMessage(err.message);
          console.error(err);
        });
    }
  };

  return (
    <div
      style={{ textAlign: "center" }}
      className={[styles.container, noBorder ? styles.noBorder : null].join(
        " "
      )}
    >
      <img src={logo} alt="KindBio" />
      {linkSent ? (
        <div style={{ margin: "50px 0" }}>
          A sign-in link was sent to your email.
        </div>
      ) : (
        <Fragment>
          <p style={{ marginTop: "20px" }}>
            {magicLink
              ? "Please enter your email to continue."
              : "Please log in to continue."}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSignIn();
            }}
            style={{ marginBottom: "30px" }}
          >
            <div>
              <Input
                fluid
                type="email"
                label="Email"
                value={email}
                onChange={(v) => {
                  setEmail(v);
                }}
              />
            </div>
            {!magicLink ? (
              <div>
                <Input
                  fluid
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(v) => {
                    setPassword(v);
                  }}
                />
              </div>
            ) : null}

            <input type="submit" style={{ display: "none" }} />
            <Button type="submit" style={{ marginTop: "20px" }}>
              Continue
            </Button>
          </form>
          {firebase.errorMessage ? (
            <div
              style={{
                color: "red",
                fontSize: "0.9em",
                margin: "0 0 10px 0",
                maxWidth: "300px",
              }}
            >
              {firebase.errorMessage}
            </div>
          ) : null}
          {magicLink ? (
            <LinkButton
              onClick={() => {
                setMagicLink(false);
              }}
              style={{ fontSize: "0.9em" }}
            >
              Log in with email and password.
            </LinkButton>
          ) : (
            <LinkButton
              //style={{ display: "block", margin: "0 auto" }}
              onClick={() => {
                console.log("called");
                setMagicLink(true);
              }}
              style={{ fontSize: "0.9em" }}
            >
              Use a magic link to log in{" "}
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </LinkButton>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default SignInContainer;
