import React, { Fragment } from "react";
import styles from "./inputStyles.module.scss";

const Input = ({
  type,
  label,
  value,
  onChange,
  bgColor,
  compact,
  fluid = false,
  refObject,
  style,
}) => {
  return (
    <div
      className={[styles.wrapper, fluid ? styles.fluid : null].join(" ")}
      style={{ ...style }}
    >
      <input
        className={[
          styles.input,
          fluid ? styles.fluid : null,
          compact ? styles.compact : null,
        ].join(" ")}
        name={label}
        type={type}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        style={{ backgroundColor: bgColor || "rgba(0,0,0,0)" }}
        ref={refObject}
      />
      <label
        htmlFor={label}
        className={[
          styles.label,
          value !== "" ? styles.textEntered : null,
          compact ? styles.compact : null,
        ].join(" ")}
      >
        {label}
      </label>
    </div>
  );
};

export default Input;
