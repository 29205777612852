import React, { useEffect, useState, useContext, Fragment } from "react";
import logo from "../../assets/kindbio-no-text.svg";
import bigLogo from "../../assets/logo-no-tag.svg";
import styles from "./navStyles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCog,
  faPowerOff,
  faBars,
  faPlusCircle,
  faJoint,
  faChartBar,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LinkButton from "../LinkButton/LinkButton";
import { EmployeeContext } from "../../App";
import EmployeePhoto from "../EmployeePhoto/EmployeePhoto";
import Button from "../Button/Button";
import SideBar from "../SideBar/SideBar";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import Can from "../Can/Can";

const NavLink = ({ children, selected, onClick }) => {
  return (
    <LinkButton
      style={{ padding: "10px", fontWeight: "bold" }}
      noBlur
      className={[styles.navLink, selected ? styles.selected : null].join(" ")}
      onClick={onClick}
    >
      {children}
    </LinkButton>
  );
};

const DropdownItem = ({ onClick, text, icon }) => {
  return (
    <button className={styles.customSelectOption} onClick={onClick}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: "10px" }} />
      {text}
    </button>
  );
};

const Dropdown = ({ show, children }) => {
  console.log(children);
  if (show) {
    return <div className={[styles.listOptions].join(" ")}>{children}</div>;
  }
  return null;
};

const NavBar = (props) => {
  //const { employeeData } = useContext(EmployeeContext);
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;
  const [selected, setSelected] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [renderSideBar, setRenderSideBar] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const history = useHistory();

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    history.push("/");
  };
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (renderSideBar) {
      setShowSideBar(true);
    }
  }, [renderSideBar]);

  const hideSideBar = () => {
    setShowSideBar(false);
    setTimeout(() => {
      setRenderSideBar(false);
    }, 301);
  };

  const toggleSideBar = () => {
    if (renderSideBar && showSideBar) {
      hideSideBar();
    }
    if (!renderSideBar && !showSideBar) {
      setRenderSideBar(true);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((v) => !v);
  };

  const handleClickOutside = (e) => {
    const classList = e.target.classList.toString();
    if (
      !classList.includes("customSelectOption") &&
      !classList.includes("listOptions") &&
      !classList.includes("userButton")
    ) {
      setSelected(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  if (!firebase.isAuthenticated) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          {firebase.isAuthenticated ? (
            <LinkButton onClick={toggleSideBar} className={styles.bars} noBlur>
              <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
            </LinkButton>
          ) : null}
        </div>
        {/* <div className={styles.midWrapper}>
        <FontAwesomeIcon
          icon={faTh}
          style={{ marginRight: "10px" }}
        ></FontAwesomeIcon>
        My Apps{" "}
        <FontAwesomeIcon
          icon={faCaretDown}
          style={{ marginLeft: "10px" }}
        ></FontAwesomeIcon>
      </div>*/}
        <div className={styles.midWrapper}>
          <LinkButton
            onClick={handleLogoClick}
            style={{
              display: "flex",
              alignItems: "center",
              color: "rgb(80,80,80)",
            }}
            noBlur
            noUnderline
          >
            <img
              src={logo}
              alt="Kindbio Logo"
              height={40}
              className={styles.smallLogo}
            ></img>
            <img
              src={bigLogo}
              alt="Kindbio Logo"
              height={40}
              className={styles.bigLogo}
            ></img>
          </LinkButton>
        </div>

        <div className={styles.rightWrapper}>
          {firebase.isAuthenticated ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={styles.navLinksWrapper}>
                <div style={{ position: "relative" }}>
                  <NavLink
                    selected={selected === "Journal"}
                    onClick={() => {
                      setSelected("Journal");
                    }}
                  >
                    Journal
                  </NavLink>
                  {selected === "Journal" ? (
                    <Dropdown show={true}>
                      <DropdownItem
                        onClick={() => {
                          history.push("/dosage-journal/new-session");
                          setSelected(null);
                        }}
                        icon={faPlusCircle}
                        text="Add New Session"
                      ></DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          history.push("/dosage-journal/previous-entries");
                          setSelected(null);
                        }}
                        icon={faJoint}
                        text="Previous Sessions"
                      />
                      <DropdownItem
                        onClick={() => {
                          history.push("/dosage-journal/data-and-insights");
                          setSelected(null);
                        }}
                        icon={faChartBar}
                        text="Data & Insights"
                      />
                    </Dropdown>
                  ) : null}
                </div>
                <NavLink
                  onClick={() => {
                    history.push("/strains");
                  }}
                >
                  Strains
                </NavLink>{" "}
              </div>

              <div style={{ position: "relative", paddingLeft: "20px" }}>
                <LinkButton
                  className={styles.userButton}
                  onClick={() => {
                    setSelected("User");
                  }}
                  noBlur
                >
                  <EmployeePhoto
                    width={40}
                    height={40}
                    imgLocation={employeeData.imgLocation}
                    className="userButtonChild"
                    round
                    selected={showDropdown}
                  ></EmployeePhoto>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ fontSize: "1.2em", marginLeft: "3px" }}
                    className="userButtonChild"
                  ></FontAwesomeIcon>
                </LinkButton>
                {selected === "User" ? (
                  <Dropdown show={true}>
                    <DropdownItem
                      onClick={() => {
                        history.push("/account");
                        setSelected(null);
                      }}
                      icon={faCog}
                      text="Settings"
                    ></DropdownItem>
                    <Can
                      user={employeeData}
                      access={"admin-console"}
                      yes={
                        <div className={styles.bottomLinks}>
                          <DropdownItem
                            onClick={() => {
                              history.push("/admin-console");
                              setSelected(null);
                            }}
                            text="Admin Console"
                            icon={faUsers}
                          ></DropdownItem>
                        </div>
                      }
                      no={null}
                    ></Can>
                    <DropdownItem
                      onClick={() => {
                        firebase.setLoading(true);
                        setShowDropdown(false);
                        history.push("/");
                        firebase.auth.signOut();
                      }}
                      icon={faPowerOff}
                      text="Log Out"
                    />
                  </Dropdown>
                ) : null}
              </div>
            </div>
          ) : (
            <Button
              onClick={() => {
                loginWithRedirect();
              }}
            >
              Login
            </Button>
          )}
        </div>
      </div>
      {renderSideBar ? (
        <SideBar visible={showSideBar} onExit={hideSideBar}></SideBar>
      ) : null}
    </Fragment>
  );
};

export default NavBar;
