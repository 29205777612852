import React from "react";

import styles from "./linkButtonStyles.module.scss";

const LinkButton = ({
  onClick,
  black,
  noBlur,
  noUnderline,
  className,
  style,
  children,
  type = "button",
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={[
        styles.linkButton,
        black ? styles.black : null,
        noBlur ? styles.noBlur : null,
        noUnderline ? styles.noUnderline : null,
        className,
      ].join(" ")}
      style={style}
    >
      {children}
    </button>
  );
};

export default LinkButton;
