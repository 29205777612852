import React, { useContext } from "react";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import { Redirect } from "react-router-dom";
import styles from "./homeStyles.module.scss";
import Loading from "../../components/Loading";
import SignInContainer from "../../components/SignInContainer/SignInContainer";

const Home = () => {
  console.log("home is rendered");
  const firebase = useContext(FirebaseContext);

  if (firebase.isLoading) {
    return <Loading></Loading>;
  }

  if (!firebase.isAuthenticated) {
    return <Redirect to="auth"></Redirect>;
  }
  if (firebase.isAuthenticated) {
    return <Redirect to="dosage-journal"></Redirect>;
  }
};

export default Home;
