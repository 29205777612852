import { faLeaf, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, FormControl, Select, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { colors } from "../../utils/constants";
import StrainDetail from "../StrainDetail/StrainDetail";
import { MobileH1 } from "../Journal/Journal";
import { AppDataContext } from "../../components/AppDataProvider";

const ChemistryStrainLink = ({
  strainName,
  rating,
  ratingLabel = "My Rating",
  chemistryData,
  numNotes,
  noBorder,
  onClick,
}) => {
  return (
    <TouchableOpacity noBorder={noBorder} onClick={onClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <FontAwesomeIcon
          icon={faLeaf}
          style={{ color: colors[3], marginRight: "10px" }}
        ></FontAwesomeIcon>
        <div style={{ textAlign: "left", margin: 0, flex: 1 }}>
          <div style={{ color: colors[4], lineHeight: 0.9 }}>{strainName}</div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              color: colors[3],
              fontSize: "0.8em",
              lineHeight: 0.94,
              marginTop: "3px",
            }}
          >
            <div style={{ flex: "50%" }}>
              <div>
                {chemistryData.thcMinPercent === chemistryData.thcMaxPercent
                  ? `${chemistryData.thcMinPercent || "-"}`
                  : `${chemistryData.thcMinPercent} - ${chemistryData.thcMaxPercent}`}
                {"% THC"}
              </div>
              <div>
                {chemistryData.thcvMinPercent === chemistryData.thcvMaxPercent
                  ? `${chemistryData.thcvMinPercent || "-"}`
                  : `${chemistryData.thcvMinPercent} - ${chemistryData.thcvMaxPercent}`}
                {"% THCV"}
              </div>
            </div>
            <div style={{ flex: "50%" }}>
              <div>
                {chemistryData.cbgMinPercent === chemistryData.cbgMaxPercent
                  ? `${chemistryData.cbgMinPercent || "-"}`
                  : `${chemistryData.cbgMinPercent} - ${chemistryData.cbgMaxPercent}`}
                {"% CBG"}
              </div>
              <div>
                {chemistryData.cbcMinPercent === chemistryData.cbcMaxPercent
                  ? `${chemistryData.cbcMinPercent || "-"}`
                  : `${chemistryData.cbcMinPercent} - ${chemistryData.cbcMaxPercent}`}
                {"% CBC"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TouchableOpacity>
  );
};

const Strains = (props) => {
  const firebase = useContext(FirebaseContext);
  const isMounted = useRef(true);
  const { strains } = useContext(AppDataContext);
  const [searchValue, setSearchValue] = useState("");
  const [sortedStrains, setSortedStrains] = useState(
    strains.sort((a, b) => a.strainName > b.strainName)
  );
  const [sortBy, setSortBy] = useState("ABC");
  const history = useHistory();

  useEffect(() => {
    if (sortBy === "ABC") {
      setSortedStrains((s) => [
        ...s.sort((a, b) => (a.strainName > b.strainName ? 1 : -1)),
      ]);
    } else {
      const str = `${sortBy.toLowerCase()}MinPercent`;
      setSortedStrains((s) => [
        ...s.sort((a, b) => (a.chemistry[str] < b.chemistry[str] ? 1 : -1)),
      ]);
    }
  }, [sortBy]);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: 0,
      minWidth: 120,
      minHeight: 10,
      padding: 0,
      fontSize: 8,
      backgroundColor: "white",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    smallerFont: {
      fontSize: 12,
    },
  }));

  const classes = useStyles();

  console.log(sortBy);

  const filteredStrains = sortedStrains.filter((s) =>
    s.strainName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          backgroundColor: colors[0],
          padding: "0 8px",
          position: "sticky",
        }}
      >
        <Input
          fluid
          compact
          bgColor="white"
          value={searchValue}
          label={
            <span>
              <FontAwesomeIcon icon={faSearch} /> Search
            </span>
          }
          onChange={(v) => {
            setSearchValue(v);
          }}
        ></Input>
      </div>
      <div
        style={{
          padding: "12px 8px 50px 8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: "10px",
          }}
        >
          <MobileH1>Strains</MobileH1>
          <div
            style={{
              fontSize: "0.9em",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <span style={{ marginRight: "5px" }}>Sort by:</span>
            <FormControl className={classes.formControl}>
              <Select
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                value={sortBy}
                className={classes.smallerFont}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
              >
                <MenuItem value="ABC" className={classes.smallerFont}>
                  ABC
                </MenuItem>
                <MenuItem value="THC" className={classes.smallerFont}>
                  THC
                </MenuItem>
                <MenuItem value="THCV" className={classes.smallerFont}>
                  THCV
                </MenuItem>
                <MenuItem value="CBC" className={classes.smallerFont}>
                  CBC
                </MenuItem>
                <MenuItem value="CBG" className={classes.smallerFont}>
                  CBG
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {filteredStrains.map((s) => (
          <ChemistryStrainLink
            key={s._id}
            strainName={s.strainName}
            chemistryData={s.chemistry}
            onClick={() => {
              history.push(`/strains/${s._id}`);
            }}
          ></ChemistryStrainLink>
        ))}
      </div>
    </div>
  );
};

export default Strains;
