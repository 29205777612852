import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import NewSession from "../NewSession/NewSession";
import PreviousEntries from "../../components/PreviousEntries/PreviousEntries";
import { Link, useHistory, useParams } from "react-router-dom";
import DatetimeSelect from "../../components/DatetimeSelect/DatetimeSelect";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./dosageJournalStyles.module.scss";
import { isToday } from "date-fns";
import PreviousEntriesPreview, {
  SessionListItem,
} from "../../components/PreviousEntriesPreview/PreviousEntriesPreview";
import Container from "../../components/Container/Container";
import Insights from "../Insights/Insights";
import Flex from "../../components/Flex/Flex";
import MyUsageSummary from "../../components/MyUsageSummary/MyUsageSummary";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import journal from "../../assets/notebook.png";
import StackBanner from "../../components/StackBanner/StackBanner";
import Blob from "../../components/Blob/Blob";
import { colors } from "../../utils/constants";
import { AppDataContext } from "../../components/AppDataProvider";

const DosageJournal = (props) => {
  const [insightData, setInsightData] = useState(false);
  const [loadingInsightData, setLoadingInsightData] = useState(true);

  let { page } = useParams();
  //const { getAccessTokenSilently } = useAuth0();
  const [currentDatetime, setCurrentDatetime] = useState(new Date());
  const [deferredPrompt, setDeferredPrompt] = useState();

  const { strains, mySessions, reloadData } = useContext(AppDataContext);
  const isMounted = useRef(true);
  const firebase = useContext(FirebaseContext);

  const history = useHistory();

  const getInsightData = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getInsightsData`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();

    return jsonResponse;
  };

  useEffect(() => {
    if (insightData) {
      setLoadingInsightData(false);
    }
  }, [insightData]);

  // Get all Journal Entries and Strains and store in state.
  useEffect(() => {
    getInsightData().then((insights) => {
      if (isMounted.current) {
        setInsightData(insights);
      }
    });

    const handleInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA

      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    };
    window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    return () => {
      isMounted.current = false;
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
    };
  }, []);

  if (page === "new-session") {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div style={{ maxWidth: "500px" }}>
          <NewSession onComplete={reloadData}></NewSession>
        </div>
      </div>
    );
  }
  if (page === "previous-entries") {
    return (
      <Fragment>
        <StackBanner
          data={[
            {
              label: "Dosage Journal",
              location: "/dosage-journal",
            },
            {
              label: "Previous Entries",
              location: "/dosage-journal/previous-entries",
            },
          ]}
        ></StackBanner>
        <PreviousEntries
          journalData={mySessions}
          strains={strains}
          onDelete={reloadData}
        />
      </Fragment>
    );
  }
  if (page === "data-and-insights") {
    return (
      <Insights
        data={insightData}
        strains={strains}
        loading={loadingInsightData}
      ></Insights>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.heroBgWrapper}>
        <Container>
          <div className={styles.heroWrapper}>
            <div className={styles.leftWrapper}>
              <div className={styles.heroHeaderWrapper}>
                <h1 className={styles.heroHeader}>Dosage Journal</h1>
                <img src={journal} className={styles.heroImage} />
              </div>

              <p className={styles.subHeader}>
                Log sessions with your KindBio products to earn rewards and
                improve your recommendations.
              </p>
              <div>
                <div className={styles.dateTimeWrapper}>
                  <p style={{ margin: "0" }} className={styles.subHeader}>
                    New Entry for {isToday(currentDatetime) ? "Today," : null}
                  </p>
                  <DatetimeSelect
                    style={{ margin: "0" }}
                    currentDatetime={currentDatetime}
                    onChange={(d) => {
                      setCurrentDatetime(d);
                    }}
                  ></DatetimeSelect>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: "/dosage-journal/new-session",
                      state: { step: 1, datetime: currentDatetime },
                    }}
                  >
                    <Button>
                      <span>Get Started </span>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",

                margin: "0 0 100px 0",
                flex: "33%",

                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "center",
              }}
            ></div>
            <div className={styles.topicsWrapper}></div>
          </div>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: colors[0],
          backgroundImage: `linear-gradient(to bottom right, ${colors[3]},${colors[2]})`,
          padding: "50px 0",
        }}
      >
        <Container>
          <Flex alignItems="flex-start" direction="responsive">
            <Flex
              direction="column"
              alignItems="stretch"
              justifyContent="flex-start"
              flex={"30%"}
              className={styles.recentEntriesWrapper}
            >
              <Blob
                style={{
                  backgroundColor: "rgb(246,246,246)",
                  padding: "20px",
                  borderRadius: "10px",
                }}
                title="Recent Entries"
                footerText="View All"
                onFooterClick={() => {
                  history.push("/dosage-journal/previous-entries");
                  window.scrollTo(0, 0);
                }}
              >
                <PreviousEntriesPreview
                  entries={mySessions
                    .reverse()
                    .slice(0, mySessions.length > 5 ? 5 : mySessions.length)}
                  strains={strains}
                  style={{ margin: "10px 0" }}
                  onDelete={reloadData}
                ></PreviousEntriesPreview>
              </Blob>
            </Flex>
            <Flex
              direction="column"
              alignItems="stretch"
              justifyContent="flex-start"
              className={styles.myEntriesPreviewWrapper}
              flex={"40%"}
            >
              <Blob
                style={{
                  backgroundColor: "rgb(246,246,246)",
                  padding: "20px",
                  borderRadius: "10px",
                }}
                title={"My Usage"}
                footerText="View More Data & Insights"
                onFooterClick={() => {
                  history.push("/dosage-journal/data-and-insights");
                  window.scrollTo(0, 0);
                }}
              >
                <MyUsageSummary
                  journalData={mySessions}
                  strains={strains}
                  style={{
                    padding: "20px 0",
                    backgroundColor: "white",
                    margin: "10px 0",
                  }}
                ></MyUsageSummary>
              </Blob>
            </Flex>
          </Flex>
        </Container>
      </div>
    </div>
  );
};

export default DosageJournal;
