import React from "react";
//import { isMobile } from "react-device-detect";
import logo from "../../assets/kindbio-no-text.svg";
import styles from "./footerStyles.module.scss";
const isMobile = true;
const Footer = () => {
  return isMobile ? (
    <div style={{ minHeight: "60px" }}></div>
  ) : (
    <footer className={styles.wrapper}>
      <img src={logo} alt="Logo" height={60}></img>
    </footer>
  );
};

export default Footer;
