import React, { useContext, useState } from "react";
import { lightenDarkenColor } from "../../utils/helpers";
import { useHistory } from "react-router-dom";

import styles from "./newStrainStyles.module.scss";
import StrainSphere from "../../components/StrainSphere/StrainSphere";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/LinkButton/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
//import { useAuth0 } from "@auth0/auth0-react";

const AddNewStrain = (props) => {
  const firebase = useContext(FirebaseContext);
  const existingStrain = props.strain;
  const existingChemistry = existingStrain?.chemistry || {};
  const history = useHistory();
  const [strainName, setStrainName] = useState(
    existingStrain?.strainName || ""
  );
  const [thcMinPercent, setThcMinPercent] = useState(
    existingChemistry.thcMinPercent || ""
  );
  const [thcMaxPercent, setThcMaxPercent] = useState(
    existingChemistry.thcMaxPercent || ""
  );
  const [thcvMinPercent, setThcvMinPercent] = useState(
    existingChemistry.thcvMinPercent || ""
  );
  const [thcvMaxPercent, setThcvMaxPercent] = useState(
    existingChemistry.thcvMaxPercent || ""
  );
  const [cbgMinPercent, setCbgMinPercent] = useState(
    existingChemistry.cbgMinPercent || ""
  );
  const [cbgMaxPercent, setCbgMaxPercent] = useState(
    existingChemistry.cbgMaxPercent || ""
  );
  const [cbcMinPercent, setCbcMinPercent] = useState(
    existingChemistry.cbcMinPercent || ""
  );
  const [cbcMaxPercent, setCbcMaxPercent] = useState(
    existingChemistry.cbcMaxPercent || ""
  );
  const [energyRating, setEnergyRating] = useState("");
  const [bestFor, setBestFor] = useState([]);
  const [kindbioColors, setKindbioColors] = useState([]);
  //const { getAccessTokenSilently } = useAuth0();

  const submitStrain = () => {
    const strainData = {
      strainName,
      chemistry: {
        thcMinPercent,
        thcMaxPercent,
        thcvMinPercent,
        thcvMaxPercent,
        cbgMinPercent,
        cbgMaxPercent,
        cbcMinPercent,
        cbcMaxPercent,
      },
      energyRating,
      bestFor,
      kindbioColors,
      createdAt: Date.now(),
    };
    return fetch(`/api/createNewStrain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(strainData),
    })
      .then((response) => response.json())
      .then((data) => {
        window.scrollTo(0, 0);
        history.push("/strains");
        //return data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateStrain = async () => {
    const strainData = {
      strainName,
      chemistry: {
        thcMinPercent,
        thcMaxPercent,
        thcvMinPercent,
        thcvMaxPercent,
        cbgMinPercent,
        cbgMaxPercent,
        cbcMinPercent,
        cbcMaxPercent,
      },
      energyRating,
      bestFor,
      kindbioColors,
      createdAt: Date.now(),
    };
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    return fetch(`/api/updateStrain/${existingStrain._id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(strainData),
    })
      .then((response) => response.json())
      .then((data) => {
        history.push(`/strains/${data._id}`);
        //return data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={styles.wrapper} style={{ marginTop: "20px" }}>
      <LinkButton
        style={{ marginBottom: "20px" }}
        onClick={() => {
          history.push("/strains");
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> Back to Strains
      </LinkButton>
      <h1 className={styles.header1}>
        {props.strain ? "Edit Strain" : "Add New Strain"}
      </h1>
      <div className={styles.field}>
        <h2 className={styles.fieldLabel}>Strain Name:</h2>
        <input
          type="text"
          className={styles.fieldInput}
          value={strainName}
          onChange={(e) => {
            setStrainName(e.currentTarget.value);
          }}
        ></input>
      </div>
      <div>
        Chemistry:
        <div className={styles.field}>
          <h2 className={styles.fieldLabel}>THC Content:</h2>
          <input
            type="text"
            className={styles.fieldInput}
            value={thcMinPercent}
            onChange={(e) => {
              setThcMinPercent(e.currentTarget.value);
            }}
          ></input>
          <span> - </span>
          <input
            type="text"
            className={styles.fieldInput}
            value={thcMaxPercent}
            onChange={(e) => {
              setThcMaxPercent(e.currentTarget.value);
            }}
          ></input>
        </div>
        <div className={styles.field}>
          <h2 className={[styles.fieldLabel].join(" ")}>THCV Content:</h2>
          <input
            type="text"
            className={[styles.fieldInput, styles.percentLabel].join(" ")}
            value={thcvMinPercent}
            onChange={(e) => {
              setThcvMinPercent(e.currentTarget.value);
            }}
          ></input>
          <span> - </span>
          <input
            type="text"
            className={[styles.fieldInput, styles.percentLabel].join(" ")}
            value={thcvMaxPercent}
            onChange={(e) => {
              setThcvMaxPercent(e.currentTarget.value);
            }}
          ></input>
        </div>
        <div className={styles.field}>
          <h2 className={[styles.fieldLabel].join(" ")}>CBG Content:</h2>
          <input
            type="text"
            className={[styles.fieldInput, styles.percentLabel].join(" ")}
            value={cbgMinPercent}
            onChange={(e) => {
              setCbgMinPercent(e.currentTarget.value);
            }}
          ></input>
          <span> - </span>
          <input
            type="text"
            className={[styles.fieldInput, styles.percentLabel].join(" ")}
            value={cbgMaxPercent}
            onChange={(e) => {
              setCbgMaxPercent(e.currentTarget.value);
            }}
          ></input>
        </div>
        <div className={styles.field}>
          <h2 className={[styles.fieldLabel].join(" ")}>CBC Content:</h2>
          <input
            type="text"
            className={[styles.fieldInput, styles.percentLabel].join(" ")}
            value={cbcMinPercent}
            onChange={(e) => {
              setCbcMinPercent(e.currentTarget.value);
            }}
          ></input>
          <span> - </span>
          <input
            type="text"
            className={[styles.fieldInput, styles.percentLabel].join(" ")}
            value={cbcMaxPercent}
            onChange={(e) => {
              setCbcMaxPercent(e.currentTarget.value);
            }}
          ></input>
        </div>
      </div>
      <div className={styles.field}>
        <h2 className={styles.fieldLabel}>Energy Rating:</h2>
        <div
          style={{
            display: "flex",
            flex: "70%",
            justifyContent: "space-between",
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <div key={rating}>
              <div>{rating}</div>
              <div>
                <input
                  type="radio"
                  value={rating}
                  checked={rating == energyRating}
                  onChange={(e) => {
                    setEnergyRating(e.currentTarget.value);
                  }}
                ></input>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.field}>
        <h2 className={styles.fieldLabel}>Best For:</h2>
        <div style={{ flex: "70%", flexWrap: "wrap", display: "flex" }}>
          {[
            "Chronic pain",
            "Lack of appetite",
            "Nausea and/or vomiting",
            "Muscle spasms",
            "Seizures",
            "Dizziness",
            "Anxiety",
            "Pain",
            "Diarrhea",
            "Tremors",
            "Insomnia",
            "Self-injurious behavior",
          ].map((symptom) => {
            return (
              <div
                key={symptom}
                style={{
                  marginLeft: "10px",
                  minWidth: "200px",
                  maxWidth: "200px",
                  marginBottom: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  id={symptom}
                  checked={bestFor.includes(symptom)}
                  onChange={(e) => {
                    const currentSymptom = e.currentTarget.id;
                    const bestForCopy = [...bestFor];
                    const i = bestForCopy.indexOf(currentSymptom);
                    if (i === -1) {
                      bestForCopy.push(currentSymptom);
                    } else {
                      bestForCopy.splice(i, 1);
                    }
                    setBestFor(bestForCopy);
                  }}
                ></input>{" "}
                <div style={{ marginLeft: "10px" }}>{symptom}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.field}>
        <h2 className={styles.fieldLabel}>Kindbio Colors:</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: "70%",
          }}
        >
          {[
            ["Cayenne", 0xeb7d5b],
            ["Mustard", 0xfed23f],
            ["Lime", 0xb5d33d],
            ["Ocean", 0x6ca2ea],
            ["Violet", 0x442288],
          ].map((color) => {
            return (
              <div key={color[1]}>
                <div>{color[0]}</div>
                <div
                  className={styles.colorCircle}
                  style={{
                    backgroundColor: `#${color[1].toString(16)}`,
                    borderColor: `#${lightenDarkenColor(
                      `#${color[1].toString(16)}`,
                      -80
                    ).toString(16)}`,
                  }}
                ></div>
                <div>
                  <input
                    type="checkbox"
                    id={color[1]}
                    checked={kindbioColors.includes(color[1].toString())}
                    onChange={(e) => {
                      const currentColor = e.currentTarget.id.toString(16);

                      const kindbioColorsCopy = [...kindbioColors];
                      const i = kindbioColorsCopy.indexOf(currentColor);
                      if (i === -1) {
                        kindbioColorsCopy.push(currentColor);
                      } else {
                        kindbioColorsCopy.splice(i, 1);
                      }

                      setKindbioColors(kindbioColorsCopy);
                    }}
                  ></input>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.field}>
        <h2 className={styles.fieldLabel}>Strain Sphere:</h2>
        <div style={{ width: "100%" }}>
          <StrainSphere
            width={400}
            height={400}
            numSpheres={
              thcMaxPercent.length > 0
                ? (Math.floor(thcMaxPercent) - 14) * 7
                : undefined
            }
            sphereSpeed={(energyRating / 1000.0) * 1.2}
            colors={
              kindbioColors.length === 0
                ? undefined
                : kindbioColors.map((color) =>
                    parseInt(parseInt(color).toString(16), 16)
                  )
            }
          ></StrainSphere>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ fontSize: "1.5em", margin: "40px 0 60px 0" }}
          onClick={props.strain ? updateStrain : submitStrain}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddNewStrain;
