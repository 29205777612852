import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Pie } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import {
  getFrequencies,
  getHighestRated,
  getMostFrequent,
  getStrainRatings,
  getMean,
} from "../../utils/helpers";
import Flex from "../Flex/Flex";
import KPI from "../KPI/KPI";
import LinkButton from "../LinkButton/LinkButton";
import StrainSummary from "../StrainSummary/StrainSummary";

const MyUsageSummary = ({ journalData, strains, style, className }) => {
  const history = useHistory();
  const navigate = (location) => {
    history.push(location);
    window.scrollTo(0, 0);
  };
  const entryCountsByMethod = journalData.reduce((obj, entry) => {
    if (entry.method in obj) {
      obj[entry.method] += 1;
    } else {
      obj[entry.method] = 1;
    }
    return obj;
  }, {});
  const smokeData = journalData.filter((entry) => entry.method === "Smoke");
  const methods = Object.keys(entryCountsByMethod);
  const data = {
    labels: methods,
    datasets: [
      {
        data: methods.map((method) => entryCountsByMethod[method]),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#36EBA2"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#36EBA2"],
      },
    ],
  };

  const strainRatings =
    journalData && smokeData.length > 0 ? getStrainRatings(smokeData) : [];
  const highestRated =
    journalData && smokeData.length > 0
      ? getHighestRated(smokeData, strains)
      : {};
  const frequencies =
    journalData && smokeData.length > 0 ? getFrequencies(smokeData) : {};
  const mostFrequent =
    journalData && smokeData.length > 0
      ? getMostFrequent(smokeData, strains)
      : {};

  if (journalData.length === 0) {
    return (
      <div
        style={{
          margin: "20px 0",
          backgroundColor: "rgb(248,248,248)",
          borderRadius: "10px",
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <FontAwesomeIcon
          icon={faChartBar}
          style={{ fontSize: "50px", color: "rgb(220,220,220)" }}
        />
        <div
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontWeight: "bold",
          }}
        >
          Begin logging your sessions to get personalized insights and
          recommendations.
        </div>
      </div>
    );
  }

  return (
    <Flex
      direction="column"
      flex={1}
      style={{ ...style, alignItems: "stretch", padding: "10px" }}
      className={className}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "stretch",

          flex: 1,
        }}
      >
        <KPI
          val={journalData.length}
          label="Total Sessions"
          style={{ flex: "50%" }}
        ></KPI>
        <div style={{ minWidth: "100px", minHeight: "100px" }}>
          <Pie
            data={data}
            options={{
              maintainAspectRatio: false,
              title: {
                display: true,
                text: "Methods",
              },
              legend: { display: false },
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ flex: "48%", paddingRight: "5px" }}>
          <span style={{ fontWeight: "bold" }}>Highest Rated Strain:</span>
          <StrainSummary mini noBorder strain={highestRated} />
        </div>
        <div style={{ flex: "48%", paddingLeft: "5px" }}>
          <span style={{ fontWeight: "bold" }}>Most Smoked Strain:</span>{" "}
          <StrainSummary mini noBorder strain={mostFrequent} />
        </div>
      </div>
    </Flex>
  );
};

export default MyUsageSummary;
