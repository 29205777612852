import React, { useState, Fragment, useEffect, useRef } from "react";
import styles from "./previousEntriesStyles.module.scss";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlusCircle,
  faJoint,
  faWind,
  faCookieBite,
  faEyeDropper,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Button/Button";
import animate from "../../assets/styles/animate.module.scss";
import { useHistory } from "react-router-dom";
import Container from "../Container/Container";
import EntryDetail from "../EntryDetail/EntryDetail";
import { range } from "../../utils/helpers";
import {
  add,
  endOfMonth,
  format,
  getMonth,
  isSameDay,
  setDate,
  setMonth,
  startOfMonth,
  sub,
} from "date-fns";
import ClickableListItem from "../ClickableListItem/ClickableList";

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Icons that correspond to cannabis consumption methods
const iconMap = {
  Smoke: faJoint,
  Edible: faCookieBite,
  Tincture: faEyeDropper,
  Vape: faWind,
};

// Internal component - a box on the calendar that represents a day of the previous or subsequent month
const InactiveDay = (props) => {
  return (
    <div className={[styles.inactiveDay, styles.dayBox].join(" ")}>
      {props.dayNum}
    </div>
  );
};

// Internal component - a box on the calendar that represents a day of the current month
const ActiveDay = (props) => {
  return (
    <div
      id={props.dayNum}
      className={[
        styles.activeDay,
        styles.dayBox,
        props.selected ? styles.selected : null,
        props.className ? props.className : null,
      ].join(" ")}
      style={props.style ? props.style : null}
      onClick={props.onClick}
    >
      <div id={props.dayNum} onClick={props.onClick}>
        {props.dayNum}
      </div>
      <div
        id={props.dayNum}
        onClick={props.onClick}
        className={[
          styles.calIcons,
          props.selected ? styles.selected : null,
        ].join(" ")}
      >
        {props.hasEntries ? (
          <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
        ) : null}
      </div>
    </div>
  );
};

const generateCalendarLayout = (selectedDate) => {
  const firstDay = parseInt(format(startOfMonth(selectedDate), "i")) % 7;
  const lastDay = parseInt(format(endOfMonth(selectedDate), "d"));
  const lastDayPrevMonth = parseInt(
    format(sub(startOfMonth(selectedDate), { days: 1 }), "d")
  );
  const leadingDays = range(firstDay).map((i) => ({
    dayNum: lastDayPrevMonth - (firstDay - 1 - i),
    type: "inactive",
  }));
  const activeDays = range(lastDay).map((i) => {
    return { dayNum: i + 1, type: "active" };
  });
  const trailingDays = range(7).map((i) => ({
    dayNum: i + 1,
    type: "inactive",
  }));
  const allDays = [...leadingDays, ...activeDays, ...trailingDays];
  return allDays;
};

// Main component
const PreviousEntries = ({ journalData, strains, onDelete = () => {} }) => {
  const today = new Date();
  // State Declarations
  const [selectedDate, setSelectedDate] = useState(today);
  const [calendarLayout, setCalendarLayout] = useState(
    generateCalendarLayout(today)
  );
  const [entryDetail, setEntryDetail] = useState(null);
  const [todayEntries, setTodayEntries] = useState(
    journalData.filter((entry) => isSameDay(new Date(entry.entryDate), today))
  );
  const [numWeeks, setNumWeeks] = useState(0);
  const history = useHistory();
  const navigate = (location) => {
    history.push(location);
    window.scrollTo(0, 0);
  };
  const isMounted = useRef(true);

  const getEntriesForDate = (data, day) => {
    return data.filter((entry) => isSameDay(new Date(entry.entryDate), day));
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setTodayEntries(getEntriesForDate(journalData, selectedDate));
  }, [journalData, selectedDate]);

  useEffect(() => {
    const numLeadingDays = calendarLayout.findIndex(
      (day) => day.dayNum === 1 && day.type === "active"
    );
    const numActiveDays = parseInt(format(endOfMonth(selectedDate), "d"));
    const minDays = numLeadingDays + numActiveDays;
    const weeks = Math.ceil(minDays / 7);
    setNumWeeks(weeks);
  }, [calendarLayout]);

  // Handler function for whenever the user clicks on a calendar square to select the date.
  const handleSelectDay = (e) => {
    setEntryDetail(null);
    const newDayNum = parseInt(e.target.id);
    const newDate = setDate(selectedDate, newDayNum);
    setCalendarLayout(generateCalendarLayout(newDate));
    setSelectedDate(newDate);
  };

  // Handler function to go back one month on the calendar.
  const subtractMonth = () => {
    const newMonth = getMonth(selectedDate) - 1;
    const newDate = setMonth(selectedDate, newMonth);
    setCalendarLayout(generateCalendarLayout(newDate));
    setSelectedDate(setMonth(selectedDate, newMonth));
  };

  // Handler function to go forward one month on the calendar.
  const addMonth = () => {
    const newMonth = getMonth(selectedDate) + 1;
    const newDate = setMonth(selectedDate, newMonth);
    setCalendarLayout(generateCalendarLayout(newDate));
    setSelectedDate(setMonth(selectedDate, newMonth));
  };

  return (
    <Container>
      <div className={styles.wrapper}>
        <div>
          <section
            className={[
              styles.calWrapper,
              animate.animated,
              animate.fadeIn,
            ].join(" ")}
          >
            <div className={styles.headerWrapper}>
              <div className={styles.monthHeader}>
                <button onClick={subtractMonth} className={styles.arrowButton}>
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </button>
                <div
                  style={{
                    minWidth: "150px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {format(selectedDate, "MMMM yyyy")}
                </div>
                <button onClick={addMonth} className={styles.arrowButton}>
                  <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                </button>
              </div>
              <div className={[styles.row, styles.dayNames].join(" ")}>
                {dayNames.map((day) => (
                  <div key={day} className={styles.dayName}>
                    {day}
                  </div>
                ))}
              </div>
            </div>
            <div
              className={[
                styles.gridWrapper,
                animate.animated,
                animate.fadeIn,
              ].join(" ")}
            >
              {range(numWeeks).map((weekStart) => (
                <div className={styles.row} key={weekStart}>
                  {calendarLayout
                    .slice(weekStart * 7, weekStart * 7 + 7)
                    .map((day, i) => {
                      if (day.type === "active") {
                        return (
                          <ActiveDay
                            key={`${day.dayNum}_${uuidv4()}`}
                            id={day.dayNum}
                            dayNum={day.dayNum}
                            selected={
                              day.dayNum.toString() ===
                              format(selectedDate, "d")
                            }
                            onClick={handleSelectDay}
                            hasEntries={journalData.find((entry) =>
                              isSameDay(
                                new Date(entry.entryDate),
                                setDate(selectedDate, day.dayNum)
                              )
                            )}
                          ></ActiveDay>
                        );
                      } else {
                        return (
                          <InactiveDay
                            dayNum={day.dayNum}
                            key={`${day.dayNum}_${uuidv4()}`}
                          ></InactiveDay>
                        );
                      }
                    })}
                </div>
              ))}
            </div>
          </section>
        </div>
        <section
          className={[
            styles.entriesWrapper,
            animate.animated,
            animate.fadeIn,
          ].join(" ")}
        >
          <h1 className={styles.entriesHeader}>
            {format(selectedDate, "MMMM do, yyyy")}
          </h1>
          <div
            style={{ padding: "20px" }}
            className={[
              styles.entryContent,
              animate.animated,
              animate.fadeIn,
            ].join(" ")}
          >
            {todayEntries.length === 0 ? (
              <Fragment>
                <div>No Entries Yet</div>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    onClick={() => {
                      navigate("/dosage-journal/new-session");
                    }}
                    appear
                  >
                    <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> Add
                    New Entry
                  </Button>
                </div>
              </Fragment>
            ) : entryDetail !== null ? (
              <EntryDetail
                entryData={entryDetail}
                handleExit={() => {
                  setEntryDetail(null);
                }}
                strains={strains}
                onDelete={() => {
                  onDelete();
                  if (isMounted.current) {
                    setEntryDetail(null);
                  }
                }}
              ></EntryDetail>
            ) : (
              <Fragment>
                {todayEntries.map((entry) => {
                  const d = new Date(entry.entryDate);
                  return (
                    <ClickableListItem
                      key={entry._id}
                      onClick={() => {
                        setEntryDetail(entry);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minHeight: "100%",
                        }}
                      >
                        <div style={{ marginLeft: "10px" }}>
                          {format(d, "p")}
                        </div>
                        <div
                          className={styles.icon}
                          style={{ marginLeft: "10px" }}
                        >
                          {
                            <FontAwesomeIcon
                              icon={iconMap[entry.method]}
                              style={{ margin: "3px" }}
                            ></FontAwesomeIcon>
                          }
                        </div>
                      </div>
                    </ClickableListItem>
                  );
                })}
                <div style={{ marginTop: "20px" }}>
                  <Button
                    onClick={() => {
                      navigate("/dosage-journal/new-session");
                    }}
                    appear
                  >
                    <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> Add
                    New Entry
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        </section>
      </div>
    </Container>
  );
};

export default PreviousEntries;
