import { faCheck, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../utils/constants";

const TouchableOpacity = ({
  children,
  style,
  noBorder,
  arrowIcon,
  onClick,
  selected,
}) => {
  return (
    <button
      style={{
        backgroundColor: selected ? colors[2] : "white",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 0",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: noBorder ? "none" : `1px solid ${colors[1]}`,
        ...style,
      }}
      onClick={onClick}
    >
      {selected ? (
        <span
          style={{
            flex: 0,
            position: "absolute",
            left: "20px",
            color: selected ? "white" : "rgb(150,150,150)",
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
      ) : null}
      <div
        style={{
          flex: 1,
          color: selected ? "white" : "black",
          fontWeight: selected ? "bold" : "normal",
        }}
      >
        {children}
      </div>
      {arrowIcon ? (
        <span
          style={{
            flex: 0,
            position: "fixed",
            right: "5px",
            color: selected ? "white" : "rgb(150,150,150)",
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      ) : null}
    </button>
  );
};

export default TouchableOpacity;
