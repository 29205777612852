import React from "react";
import styles from "./popUpStyles.module.scss";
import animate from "../../assets/styles/animate.module.scss";

const PopUp = ({ children, isMobile }) => {
  return (
    <div
      className={[animate.animated, animate.fadeIn, styles.wrapper].join(" ")}
    >
      <div
        className={[
          animate.animated,
          animate.backInUp,
          styles.popUp,
          isMobile ? styles.isMobile : null,
        ].join(" ")}
      >
        {children}
      </div>
    </div>
  );
};

export default PopUp;
