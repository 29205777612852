import React, { createContext, useEffect, useState } from "react";
import { auth } from "../../utils/firebase";

export const FirebaseContext = createContext();

const FirebaseProvider = ({ children }) => {
  const [value, setValue] = useState({
    isLoading: true,
    isAuthenticated: false,
    user: null,
    data: null,
    auth: auth,
    errorMessage: false,
    setData: (newData) => {
      setValue((v) => ({ ...v, data: { ...v.data, ...newData } }));
    },
    setLoading: (val) => {
      setValue((v) => ({ ...v, isLoading: val }));
    },
    setErrorMessage: (val) => {
      setValue((v) => ({ ...v, errorMessage: val }));
    },
  });

  useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");

          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
    auth.onAuthStateChanged((user) => {
      if (user) {
        auth.currentUser.getIdToken(true).then((token) => {
          fetch(`/api/getEmployee/${user.uid}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((response) => response.json())
            .then((json) => {
              setValue((v) => ({
                ...v,
                user: user,
                data: json,
                isAuthenticated: true,
                isLoading: false,
              }));
              fetch(`/api/getSignedImgUrl?location=${json.imgLocation}`, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
              })
                .then((response) => response.json())
                .then((json) => {
                  setValue((v) => ({
                    ...v,
                    data: { ...v.data, signedUrl: json.url },
                  }));
                });
            });
        });
      } else {
        setValue((v) => ({
          ...v,
          user: null,
          isAuthenticated: false,
          isLoading: false,
        }));
      }
    });
  }, []);

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
