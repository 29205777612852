export const colors = ["#d7fbe8", "#9df3c4", "#62d2a2", "#1fab89", "#0c4436"];

export const symptoms = [
  "Chronic Pain",
  "Lack of Appetite",
  "Nausea",
  "Muscle Spasms",
  "Seizures",
  "Dizziness",
  "Anxiety",
  "Pain",
  "Diarrhea",
  "Tremors",
  "Insomnia",
  "Depression",
];
