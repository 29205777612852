import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import { EmployeeContext } from "../../App";
import FileInput from "../../components/FileInput/FileInput";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faCheckCircle,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./accountStyles.module.scss";
import Loading from "../../components/Loading/Loading";
import Container from "../../components/Container/Container";
import ColorDots from "../../components/ColorDots/ColorDots";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";

const Account = (props) => {
  //const {  setEmployeeData } = useContext(EmployeeContext);
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;
  const wrapperRef = useRef();
  const pwRef = useRef();
  const pwVerifyRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [cellPhone, setCellPhone] = useState(employeeData.cellPhone || "");
  const [displayName, setDisplayName] = useState(
    employeeData.displayName || ""
  );

  const handlePasswordChange = async () => {
    setChangingPassword(true);
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(
      `/api/updateEmployeePassword/${employeeData.firebaseId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password: password }),
      }
    );
    setChangingPassword(false);
    if (response.status == 200) {
      setShowSuccess(true);
      setPassword("");
      setVerifyPassword("");
      pwRef.current.blur();
      pwVerifyRef.current.blur();
      setTimeout(() => {
        setShowSuccess(false);
      }, 2500);
    } else {
      setErrorMessage("Server Error");
    }
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  const handleImageUpload = async (file) => {
    setUploadingImage(true);
    console.log(file);
    const formData = new FormData();
    formData.append("myFile", file);
    formData.append(
      "filename",
      `employee-photos/${employeeData.firstName}-${
        employeeData.lastName
      }/${Date.now()}`
    );
    const token = await firebase.auth.currentUser.getIdToken(true);

    const response = await fetch(`/api/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const jsonResponse = await response.json();
    await fetch(`/api/updateEmployee/${employeeData.firebaseId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imgLocation: jsonResponse.location }),
    });
    setUploadingImage(false);
    window.location.replace(`${window.location}?`);
  };

  const handleUpdateEmployeeData = async (d) => {
    const token = await firebase.auth.currentUser.getIdToken(true);

    const response = await fetch(
      `/api/updateEmployee/${employeeData.firebaseId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(d),
      }
    );
    const jsonResponse = await response.json();
    console.log(jsonResponse);
    firebase.setData(jsonResponse);
  };

  return (
    <Container style={{ padding: "30px 20px 90px 20px" }}>
      <div>
        <div className={styles.settingsWrapper}>
          <div>
            <EmployeePhoto
              width={200}
              height={200}
              signedUrl={employeeData.signedUrl}
            ></EmployeePhoto>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FileInput onChange={handleImageUpload} loading={uploadingImage}>
                Change Photo
              </FileInput>
              {/*<Button red>
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>*/}
            </div>
          </div>

          <div className={styles.dataWrapper}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <h2 style={{ marginRight: "10px" }}>Personal Info</h2>
              <div>
                {editMode ? (
                  <div style={{ display: "flex", flexWrap: "nowrap" }}>
                    <div style={{ textAlign: "right", marginRight: "5px" }}>
                      <Button
                        onClick={() => {
                          handleUpdateEmployeeData({
                            cellPhone: cellPhone,
                            displayName: displayName,
                          });
                          //setEditMode((v) => !v);
                        }}
                        compact
                      >
                        <FontAwesomeIcon icon={faSave} /> Save
                      </Button>
                    </div>
                    <div>
                      <Button
                        red
                        onClick={() => {
                          setEditMode((v) => !v);
                          setCellPhone(employeeData.cellPhone || "");
                          setDisplayName(employeeData.displayName || "");
                        }}
                        compact
                      >
                        <FontAwesomeIcon icon={faTimes} /> Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Button
                    compact
                    onClick={() => {
                      setEditMode((v) => !v);
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.dataElement}>
              <h3 className={styles.dataLabel}>Name: </h3>
              <span style={{ marginLeft: "10px" }}>
                {employeeData.firstName} {employeeData.lastName}
              </span>
            </div>

            <div className={styles.dataElement}>
              <h3 className={styles.dataLabel}>Email: </h3>
              <span style={{ marginLeft: "10px" }}>{employeeData.email}</span>
            </div>
            <div className={styles.dataElement}>
              <h3 className={styles.dataLabel}>Job Title: </h3>
              <span style={{ marginLeft: "10px" }}>
                {employeeData.jobTitle}
              </span>
            </div>
            <div className={styles.dataElement}>
              <h3 className={styles.dataLabel}>Display Name: </h3>
              {editMode ? (
                <div style={{ marginLeft: "10px" }}>
                  <Input
                    value={displayName}
                    onChange={(v) => {
                      setDisplayName(v);
                    }}
                    compact
                  ></Input>
                </div>
              ) : (
                <span style={{ marginLeft: "10px" }}>
                  {employeeData.displayName}
                </span>
              )}
            </div>
            <div className={styles.dataElement}>
              <h3 className={styles.dataLabel}>Cell Phone: </h3>
              <span style={{ marginLeft: "10px" }}>
                {editMode ? (
                  <PhoneInput
                    value={cellPhone}
                    onChange={(v) => {
                      setCellPhone(v);
                    }}
                    country="us"
                    countryCodeEditable={false}
                  ></PhoneInput>
                ) : (
                  <PhoneNumber num={employeeData.cellPhone || ""} />
                )}
              </span>
            </div>
          </div>
        </div>
        <div ref={wrapperRef} style={{ marginTop: "30px" }}>
          <h2>Change Password</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handlePasswordChange();
            }}
          >
            <div style={{ marginTop: "20px" }}>
              <Input
                label="New Password"
                type="password"
                value={password}
                onChange={(v) => {
                  setPassword(v);
                }}
                refObject={pwRef}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Input
                label="Verify Password"
                type="password"
                value={verifyPassword}
                onChange={(v) => {
                  setVerifyPassword(v);
                }}
                refObject={pwVerifyRef}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button
                onClick={() => {
                  handlePasswordChange();
                }}
                disabled={password !== verifyPassword || password === ""}
                style={{ marginTop: "30px" }}
                type="submit"
              >
                Update Password
              </Button>
            </div>
          </form>
          {changingPassword ? (
            <Loading inline style={{ marginTop: "15px" }}></Loading>
          ) : null}
          {errorMessage ? <div>{errorMessage}</div> : null}
          {showSuccess ? (
            <div style={{ color: "green", marginTop: "15px" }}>
              <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon> Password
              saved successfully.
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default Account;
