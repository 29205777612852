import React from "react";
import Button from "../Button/Button";

const ToggleButton = ({
  options,
  selected,
  onChange,
  compact,
  style,
  className,
}) => {
  return (
    <div style={{ textAlign: "center", ...style }} className={className}>
      <Button
        onClick={() => {
          onChange(options[0]);
        }}
        selected={selected === options[0]}
        noBlur
        compact={compact}
        style={{ borderRight: "none" }}
      >
        {options[0]}
      </Button>
      <Button
        onClick={() => {
          onChange(options[1]);
        }}
        selected={selected === options[1]}
        compact={compact}
        noBlur
      >
        {options[1]}
      </Button>
    </div>
  );
};

export default ToggleButton;
