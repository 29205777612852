import React, { useContext } from "react";
import { MobileH1, NoteLink } from "../Journal/Journal";
import { AppDataContext } from "../../components/AppDataProvider";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import LinkButton from "../../components/LinkButton/LinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const MyNotes = (props) => {
  const firebase = useContext(FirebaseContext);
  const { strains } = useContext(AppDataContext);
  const history = useHistory();
  const myNotes = strains
    ? strains.reduce((l, strain) => {
        const notes = strain.notes.reduce(
          (n, d) =>
            d.firebaseId === firebase.user.uid
              ? [
                  ...n,
                  {
                    ...d,
                    strainName: strain.strainName,
                    strainId: strain._id,
                  },
                ]
              : n,
          []
        );
        return [...l, ...notes];
      }, [])
    : [];
  return (
    <div style={{ padding: "3px 8px 80px 8px", textAlign: "left" }}>
      <LinkButton
        style={{ marginBottom: "10px" }}
        onClick={() => {
          history.push("/dosage-journal");
        }}
      >
        <FontAwesomeIcon icon={faLongArrowAltLeft} /> Dosage Journal
      </LinkButton>
      <MobileH1>My Notes ({myNotes.length})</MobileH1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {myNotes.map((note) => (
          <NoteLink
            key={note._id}
            note={note}
            onClick={() => {
              history.push(`/dosage-journal/note-detail/${note._id}`);
            }}
          ></NoteLink>
        ))}
      </div>
    </div>
  );
};

export default MyNotes;
