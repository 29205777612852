import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import LinkButton from "../LinkButton/LinkButton";
import styles from "./blobStyles.module.scss";

const Blob = ({
  title,
  children,
  noShadow,
  onFooterClick,
  footerText,
  style,
  className,
}) => {
  return (
    <div
      style={{
        ...style,
      }}
      className={[
        styles.wrapper,
        noShadow ? styles.noShadow : null,
        className,
      ].join(" ")}
    >
      <h4>{title}</h4>
      <div>{children}</div>
      {footerText ? (
        <LinkButton onClick={onFooterClick}>
          {footerText}
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            style={{ marginLeft: "5px" }}
          />
        </LinkButton>
      ) : null}
    </div>
  );
};

export default Blob;
