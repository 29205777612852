import React from "react";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";

const TextDisplay = ({ rawData, color = "blue", fontSize = "1em" }) => {
  return (
    <div>
      <Editor
        readOnly
        editorState={EditorState.createWithContent(convertFromRaw(rawData))}
      />
    </div>
  );
};

export default TextDisplay;
