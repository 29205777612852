import React, { Fragment } from "react";
import rolesAndPermissions from "../../utils/rolesAndPermissions";

const Can = ({ user, access, yes, no }) => {
  const userRoles = user.roles || [];
  const permissions = [];

  if (access === "admin-console") {
    if (userRoles.includes("Admin")) {
      return <Fragment>{yes}</Fragment>;
    } else {
      return <Fragment>{no}</Fragment>;
    }
  }

  userRoles.forEach((role) => {
    const currentPermissions = rolesAndPermissions[role];
    currentPermissions.forEach((perm) => {
      if (!permissions.includes(perm)) {
        permissions.push(perm);
      }
    });
  });

  const prefix = access.split(":")[0];
  if (permissions.includes(access) || permissions.includes(`${prefix}:all`)) {
    return <Fragment>{yes}</Fragment>;
  } else {
    return <Fragment>{no}</Fragment>;
  }
};

export default Can;
