import React, { useContext } from "react";
import styles from "./signInStyles.module.scss";
import SignInContainer from "../../components/SignInContainer/SignInContainer";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Loading from "../../components/Loading";
import { Redirect } from "react-router-dom";
//import { isMobile } from "react-device-detect";
const isMobile = true;
const SignIn = ({
  onSuccess = (v) => {
    console.log(v);
  },
  onError = (err) => {
    console.error(err);
  },
}) => {
  const firebase = useContext(FirebaseContext);
  if (firebase.isLoading) {
    return <Loading></Loading>;
  }
  if (firebase.isAuthenticated) {
    return <Redirect to="dosage-journal"></Redirect>;
  }

  return (
    <div className={isMobile ? styles.mobileWrapper : styles.wrapper}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SignInContainer onSuccess={onSuccess} noBorder={isMobile} />
      </div>
    </div>
  );
};

export default SignIn;
