import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import Button from "../Button/Button";

const CheckboxButtons = ({
  options,
  selected,
  onChange,
  style,
  className,
  compact,
  rounded,
  noWrap,
  max = false,
  icons = [],
  radio = false,
}) => {
  return (
    <Fragment>
      {options.map((option, i) => {
        return (
          <Button
            compact={compact}
            checkbox
            key={option}
            selected={radio ? selected === option : selected?.includes(option)}
            onClick={
              radio
                ? () => {
                    onChange(option);
                  }
                : () => {
                    const copy = [...selected];
                    const index = copy.indexOf(option);
                    if (index > -1) {
                      copy.splice(index, 1);
                    } else {
                      if (max) {
                        if (selected.length < max) copy.push(option);
                      } else {
                        copy.push(option);
                      }
                    }
                    onChange(copy, option);
                  }
            }
            style={{
              ...style,
              borderRadius: rounded ? "20px" : undefined,
              padding: rounded ? "0 20px" : undefined,
              whiteSpace: noWrap ? "nowrap" : "wrap",
            }}
            className={className}
            noBlur
          >
            {icons[i] ? (
              <FontAwesomeIcon
                style={{ marginRight: "10px" }}
                icon={icons[i]}
              />
            ) : null}
            {option}
          </Button>
        );
      })}
    </Fragment>
  );
};

export default CheckboxButtons;
