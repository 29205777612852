import {
  faCalendarAlt,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  format,
  endOfMonth,
  getMonth,
  getYear,
  addMonths,
  subMonths,
  startOfMonth,
  setDate,
  isSameDay,
} from "date-fns";
import { range } from "../../utils/helpers";
import styles from "./dateSelectStyles.module.scss";
import LinkButton from "../LinkButton/LinkButton";

const InactiveDay = () => {
  return <button className={styles.dayBox}></button>;
};

const ActiveDay = ({ day, onClick, selected }) => {
  return (
    <button
      className={[styles.dayBox, selected ? styles.selected : null].join(" ")}
      onClick={() => {
        onClick(day);
      }}
    >
      {day}
    </button>
  );
};

const generateCalendarLayout = (selectedDate) => {
  const firstDay = parseInt(format(startOfMonth(selectedDate), "i")) % 7;
  const lastDay = parseInt(format(endOfMonth(selectedDate), "d"));
  const leadingDays = range(firstDay).map((i) => ({
    dayNum: i + 1,
    type: "inactive",
  }));
  const activeDays = range(lastDay).map((i) => {
    return { dayNum: i + 1, type: "active" };
  });
  const trailingDays = range(7).map((i) => ({
    dayNum: i + 1,
    type: "inactive",
  }));
  const allDays = [...leadingDays, ...activeDays, ...trailingDays];
  return allDays;
};

const DateSelect = ({
  defaultDate = new Date(),
  onChange = () => {},
  style,
  showYear,
}) => {
  const [currentDate, setCurrentDate] = useState(defaultDate);
  const [showCal, setShowCal] = useState(false);
  const [calDate, setCalDate] = useState(
    new Date(getYear(defaultDate), getMonth(defaultDate), 1)
  );
  const [layout, setLayout] = useState(generateCalendarLayout(calDate));
  const [numWeeks, setNumWeeks] = useState(0);

  const handleClick = () => {
    const currentVal = showCal;
    setShowCal(!currentVal);
  };

  const handleDayClick = (v) => {
    const newDate = new Date(setDate(calDate, v));
    setCurrentDate(newDate);
    onChange(newDate);
    setShowCal(false);
  };

  const handleAddMonth = () => {
    const newDate = addMonths(calDate, 1);
    setCalDate(newDate);
  };

  const handleSubtractMonth = () => {
    const newDate = subMonths(calDate, 1);
    setCalDate(newDate);
  };

  useEffect(() => {
    const newLayout = generateCalendarLayout(calDate);
    setLayout(newLayout);
  }, [calDate]);

  useEffect(() => {
    const numLeadingDays = layout.findIndex(
      (day) => day.dayNum === 1 && day.type === "active"
    );
    const numActiveDays = parseInt(format(endOfMonth(calDate), "d"));
    const minDays = numLeadingDays + numActiveDays;
    const weeks = Math.ceil(minDays / 7);
    setNumWeeks(weeks);
  }, [layout]);

  return (
    <div style={{ position: "relative", display: "inline-block", ...style }}>
      <button onClick={handleClick} className={styles.button}>
        <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
        <span style={{ marginLeft: "8px" }}>{format(currentDate, "E")}</span>
        <span style={{ marginLeft: "12px" }}>
          {format(currentDate, showYear ? "MM/dd/yyyy" : "MM/dd")}
        </span>
      </button>
      {showCal ? (
        <div className={styles.calWrapper}>
          <div className={styles.calHeaderWrapper}>
            <LinkButton
              onClick={handleSubtractMonth}
              style={{ minWidth: "20px" }}
            >
              <FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>
            </LinkButton>
            <div>{format(calDate, "MMM")}</div>
            <LinkButton onClick={handleAddMonth} style={{ minWidth: "20px" }}>
              <FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>
            </LinkButton>
          </div>
          {range(numWeeks).map((weekStart, i) => (
            <div className={styles.row} key={weekStart}>
              {layout.slice(weekStart * 7, weekStart * 7 + 7).map((day, i) => {
                if (day.type === "active") {
                  return (
                    <ActiveDay
                      key={`${day.dayNum}_active`}
                      day={day.dayNum}
                      selected={isSameDay(
                        setDate(calDate, parseInt(day.dayNum)),
                        currentDate
                      )}
                      onClick={handleDayClick}
                    ></ActiveDay>
                  );
                } else {
                  return <InactiveDay key={`${day.dayNum}_${i}`}></InactiveDay>;
                }
              })}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DateSelect;
