import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faSave as faSaveSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./saveButtonStyles.module.scss";

const SaveButton = ({
  className,
  selected,
  onClick = () => {
    console.log("hey");
  },
}) => {
  return (
    <button
      className={[styles.button, selected ? styles.selected : null].join(" ")}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={selected ? faSaveSolid : faSave} />
      <span style={{ marginLeft: "5px" }}>{selected ? "Saved" : "Save"}</span>
    </button>
  );
};

export default SaveButton;
