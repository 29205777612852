import {
  faCommentDots,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import Button from "../../components/Button/Button";
import TextDisplay from "../../components/TextDisplay/TextDisplay";
import TextEditor from "../../components/TextEditor/TextEditor";
import { colors } from "../../utils/constants";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Fragment } from "react";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import PopUp from "../../components/PopUp/PopUp";
import { useHistory } from "react-router";
import { AppDataContext } from "../../components/AppDataProvider";

const NoteDetail = ({ note }) => {
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState(
    EditorState.createWithContent(convertFromRaw(note.text))
  );
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const firebase = useContext(FirebaseContext);
  const appData = useContext(AppDataContext);
  const employeeData = firebase.data;
  const history = useHistory();

  console.log(note);

  const updateStrainNote = async (noteContent) => {
    const newNote = {
      _id: note._id,
      firebaseId: employeeData.firebaseId,
      author: `${employeeData.firstName} ${employeeData.lastName}`,
      text: convertToRaw(content.getCurrentContent()) || undefined,
    };

    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/updateStrainNote/${note.strainId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newNote),
    });
    const jsonResponse = await response.json();

    return jsonResponse;
  };

  const deleteNote = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(
      `/api/deleteStrainNote/${note.strainId}/${note._id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  return (
    <div style={{ padding: "3px 8px 50px 8px" }}>
      {showDeletePopUp ? (
        <PopUp>
          <div>Are you sure you want to delete this note?</div>
          <Button
            red
            style={{ margin: "3px" }}
            onClick={() => {
              deleteNote().then((response) => {
                appData.reloadData();
                history.push("/dosage-journal");
              });
            }}
          >
            Yes, Delete
          </Button>
          <Button
            style={{ margin: "3px" }}
            onClick={() => {
              setShowDeletePopUp(false);
            }}
          >
            No, Cancel
          </Button>
        </PopUp>
      ) : null}
      <div style={{ textAlign: "right", marginBottom: "10px" }}>
        <Button
          compact
          onClick={() => {
            setEditMode(true);
          }}
          style={{ marginRight: "5px" }}
        >
          <FontAwesomeIcon icon={faPencilAlt} /> Edit
        </Button>
        <Button
          red
          compact
          style={{ marginLeft: "5px" }}
          onClick={() => {
            setShowDeletePopUp(true);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} /> Delete
        </Button>
      </div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div>
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{ color: colors[3], marginRight: "10px" }}
          />
        </div>
        <div style={{ flex: 1, textAlign: "left" }}>
          <div style={{ fontWeight: "bold", color: colors[3] }}>
            {note.strainName}
          </div>
          <div
            style={{ color: colors[3], fontSize: "0.8em", lineHeight: 0.94 }}
          >
            {format(new Date(Date.parse(note.createdAt)), "MMM d, yyyy")}
          </div>
          <div>
            {editMode ? (
              <Fragment>
                <div style={{ marginTop: "10px" }}>
                  <TextEditor
                    content={content}
                    onChange={(v) => {
                      setContent(v);
                    }}
                  />
                </div>
                <div style={{ marginTop: "5px" }}>
                  <Button
                    onClick={() => {
                      updateStrainNote(content).then((response) => {
                        document.location.reload();
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Fragment>
            ) : (
              <TextDisplay rawData={note.text}></TextDisplay>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteDetail;
