import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
  faChartBar,
  faLeaf,
  faPenAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { EmployeeContext } from "../../App";
import Can from "../Can/Can";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import LinkButton from "../LinkButton/LinkButton";

import styles from "./sideBarStyles.module.scss";

const SideBar = (props) => {
  const history = useHistory();

  //const { employeeData } = useContext(EmployeeContext);
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;

  const navigate = (location) => {
    props.onExit();
    history.push(location);
  };

  return (
    <div
      className={[styles.wrapper, props.visible ? null : styles.hidden].join(
        " "
      )}
    >
      <div className={styles.sideBarWrapper}>
        <div className={styles.contentWrapper}>
          <button
            className={styles.link}
            onClick={() => {
              navigate("/dosage-journal");
            }}
          >
            <FontAwesomeIcon icon={faPenAlt}></FontAwesomeIcon>
            <span className={styles.linkText}>Dosage Journal</span>
          </button>
          <button
            className={styles.link}
            onClick={() => {
              navigate("/dosage-journal/data-and-insights");
            }}
          >
            <FontAwesomeIcon icon={faChartBar}></FontAwesomeIcon>
            <span className={styles.linkText}>Data & Insights</span>
          </button>

          <button
            className={styles.link}
            onClick={() => {
              navigate("/strains");
            }}
          >
            <FontAwesomeIcon icon={faLeaf}></FontAwesomeIcon>
            <span className={styles.linkText}>Strains</span>
          </button>
          <button
            className={styles.link}
            onClick={() => {
              navigate("/forum");
            }}
          >
            <FontAwesomeIcon icon={faCommentDots}></FontAwesomeIcon>
            <span className={styles.linkText}>Forum</span>
          </button>
        </div>
        <Can
          user={employeeData}
          access={"admin-console"}
          yes={
            <div className={styles.bottomLinks}>
              <LinkButton onClick={() => navigate("/admin-console")}>
                Admin Console
              </LinkButton>
            </div>
          }
          no={null}
        ></Can>
      </div>
    </div>
  );
};

export default SideBar;
