import React, { useEffect, useState, useContext, Fragment } from "react";
import logo from "../../assets/kindbio-no-text.svg";
import styles from "./mobileHeaderStyles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LinkButton from "../LinkButton/LinkButton";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../utils/constants";
import MobileAccountSideBar from "../MobileAccountSideBar/MobileAccountSideBar";

const MobileHeader = ({ onShowAccountSideBar }) => {
  //const { employeeData } = useContext(EmployeeContext);
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;
  const [selected, setSelected] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [renderSideBar, setRenderSideBar] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showAccountSideBar, setShowAccountSideBar] = useState(false);
  const history = useHistory();

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    history.push("/");
  };
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (renderSideBar) {
      setShowSideBar(true);
    }
  }, [renderSideBar]);

  const hideSideBar = () => {
    setShowSideBar(false);
    setTimeout(() => {
      setRenderSideBar(false);
    }, 301);
  };

  const toggleSideBar = () => {
    if (renderSideBar && showSideBar) {
      hideSideBar();
    }
    if (!renderSideBar && !showSideBar) {
      setRenderSideBar(true);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((v) => !v);
  };

  const handleClickOutside = (e) => {
    const classList = e.target.classList.toString();
    if (
      !classList.includes("customSelectOption") &&
      !classList.includes("listOptions") &&
      !classList.includes("userButton")
    ) {
      setSelected(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  if (!firebase.isAuthenticated) {
    return null;
  }

  return (
    <Fragment>
      {showAccountSideBar ? (
        <MobileAccountSideBar
          onExit={() => {
            setShowAccountSideBar(false);
          }}
        />
      ) : null}
      <div className={styles.wrapper}>
        <div style={{ flex: 1 }}></div>
        <div className={styles.midWrapper}>
          <LinkButton
            onClick={handleLogoClick}
            style={{
              display: "flex",
              alignItems: "center",
              color: "rgb(80,80,80)",
            }}
            noBlur
            noUnderline
          >
            <img
              src={logo}
              alt="Kindbio Logo"
              height={30}
              className={styles.smallLogo}
            ></img>
          </LinkButton>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          <button
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              border: "none",
              padding: 0,
            }}
            onClick={() => {
              setShowAccountSideBar(true);
            }}
          >
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{ color: colors[3], fontSize: "1.2em" }}
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default MobileHeader;
