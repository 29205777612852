import React, { createContext, useState, useEffect, useContext } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import history from "./utils/history";
import Employees from "./views/Employees/Employees";
import AddNewEmployee from "./views/AddNewEmployee/AddNewEmployee";
import AddNewStrain from "./views/AddNewStrain/AddNewStrain";
import { FirebaseContext } from "./components/FirebaseProvider/FirebaseProvider";

// styles
import "./App.scss";

// fontawesome
import Strains from "./mobileViews/Strains/Strains";
import Journal from "./mobileViews/Journal/Journal";
import Account from "./views/Account/Account";
import EditStrain from "./views/EditStrain/EditStrain";
import StrainDetail from "./mobileViews/StrainDetail/StrainDetail";
import AdminEditEmployee from "./views/AdminEditEmployee/AdminEditEmployee";
import Insights from "./views/Insights/Insights";
import SignIn from "./views/SignIn/SignIn";
import SignUp from "./views/SignUp/SignUp";
import ScrollToTop from "./components/ScrollToTop";
import ActionSheet from "./components/ActionSheet/ActionSheet";
import AdminConsole from "./views/AdminConsole/AdminConsole";
import Forum from "./views/Forum/Forum";
import PostDetail from "./views/PostDetail/PostDetail";
//import { isMobile } from "react-device-detect";
import MobileNav from "./components/MobileNav/MobileNav";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import { AppDataContext } from "./components/AppDataProvider";
import Settings from "./mobileViews/Settings/Settings";
import OnboardingDemo from "./views/OnboardingDemo/OnboardingDemo";

const MobileApp = () => {
  const firebase = useContext(FirebaseContext);
  const { mySessions, strains } = useContext(AppDataContext);

  console.log("mobile app is rendered");

  const ProtectedRoute = ({ component, roles, ...args }) => {
    if (roles) {
      let allowed = false;
      roles.forEach((role) => {
        if (firebase.data.roles.includes(role)) {
          allowed = true;
        }
      });
      if (!allowed) {
        return <Redirect to="/" />;
      }
    }
    return firebase.isAuthenticated ? (
      <Route component={component} {...args} />
    ) : (
      <Redirect to="/" />
    );
  };

  const AppRoutes = () => {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/auth" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/profile" component={Profile} />

        <ProtectedRoute path="/employees" component={Employees} />
        <ProtectedRoute path="/add-new-employee" component={AddNewEmployee} />
        <ProtectedRoute path="/strains/:id" component={StrainDetail} />
        <ProtectedRoute path="/strains" component={Strains} />
        <ProtectedRoute path="/forum/post-detail/:id" component={PostDetail} />
        <ProtectedRoute path="/forum" component={Forum} />

        <ProtectedRoute path="/add-new-strain" component={AddNewStrain} />
        <ProtectedRoute path="/dosage-journal/:page/:id" component={Journal} />
        <ProtectedRoute path="/dosage-journal/:page" component={Journal} />
        <ProtectedRoute path="/dosage-journal" component={Journal} />
        <ProtectedRoute path="/account" component={Account} />
        <ProtectedRoute path="/edit-strain/:id" component={EditStrain} />
        <ProtectedRoute
          path="/edit-employee/:id"
          component={AdminEditEmployee}
        />
        <ProtectedRoute path="/settings" component={Settings} />
        <ProtectedRoute
          path="/admin-console/:page/:id"
          component={AdminConsole}
        />
        <ProtectedRoute
          path="/admin-console"
          component={AdminConsole}
          roles={["Admin"]}
        />

        <ProtectedRoute path="/insights" component={Insights} />
        <ProtectedRoute path="/onboarding" component={OnboardingDemo} />
      </Switch>
    );
  };

  if (firebase.error) {
    return <div>Oops... {firebase.error.message}</div>;
  }

  if (
    firebase.isLoading ||
    (firebase.isAuthenticated && (!mySessions || !strains))
  ) {
    return (
      <div
        id="app"
        style={{
          minHeight: "100vh",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Loading />
      </div>
    );
  } else {
    return (
      <Router history={history}>
        <ScrollToTop></ScrollToTop>
        <div
          id="app"
          style={{
            minHeight: "100vh",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MobileHeader />
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              flex: "1",
            }}
          >
            <AppRoutes></AppRoutes>
          </div>
        </div>
        {firebase.isAuthenticated ? <MobileNav /> : null}
      </Router>
    );
  }
};

export default MobileApp;
