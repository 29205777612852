import React from "react";

const Placeholder = ({ children, style }) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(230,230,230)",
        borderRadius: "8px",
        padding: "30px 15px",
        textAlign: "center",
        fontWeight: "bold",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Placeholder;
