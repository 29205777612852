import React from "react";
import styles from "./flexStyles.module.scss";

const Flex = ({
  children,
  direction,
  flex = 1,
  breakpoint = "md",
  alignItems = "center",
  justifyContent = "center",
  style,
  className,
}) => {
  const directionClasses = {
    row: styles.row,
    "row-reverse": styles.rowReverse,
    column: styles.column,
    "column-reverse": styles.columnReverse,
    responsive: styles.responsiveRow,
  };
  const camelize = (s) => {
    const list = s.split("-");
    const str = list.reduce(
      (finalStr, word) =>
        `${finalStr}${word.substring(0, 1).toUpperCase()}${word.substring(1)}`,
      ""
    );
    return str;
  };
  return (
    <div
      className={[
        styles.flex,
        directionClasses[direction] || styles.row,
        styles[breakpoint],
        className,
        styles[`alignItems${camelize(alignItems)}`],
      ].join(" ")}
      style={{
        flex,
        justifyContent,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Flex;
