import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import styles from "./employeePhotoStyles.module.scss";

const EmployeePhoto = ({
  imgLocation,
  width,
  height,
  style,
  border,
  firebaseId,
  className,
  round,
  signedUrl,
  selected,
}) => {
  const [imgUrl, setImgUrl] = useState(
    signedUrl ||
      "https://icon-library.com/images/tumblr-icon/tumblr-icon-13.jpg"
  );

  const firebase = useContext(FirebaseContext);

  const getUrl = async (location) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);

    const response = await fetch(`/api/getSignedImgUrl?location=${location}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse.url;
  };

  const getUrlFromId = async (id) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getSignedImgUrlFromId?id=${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();

    return jsonResponse.url;
  };

  useEffect(() => {
    let subscribed = true;
    if (firebaseId) {
      getUrlFromId(firebaseId).then((url) => {
        if (subscribed) {
          setImgUrl(url);
        }
      });
    }
    return () => {
      subscribed = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (imgLocation) {
      getUrl(imgLocation).then((url) => {
        if (isSubscribed) {
          setImgUrl(url);
        }
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [imgLocation]);

  useEffect(() => {
    if (signedUrl) {
      setImgUrl(signedUrl);
    }
  }, [signedUrl]);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: width || 200,
          height: height || 200,
          minWidth: width || 200,
          minHeight: height || 200,
          backgroundImage: `url("${imgUrl}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          ...style,
          border: border ? "1px solid rgb(100,100,100)" : "none",
          borderRadius: round ? "50%" : 0,
        }}
      ></div>
    </div>
  );
};

export default EmployeePhoto;
