import React, { useContext, useEffect, useState } from "react";
import {
  faCaretDown,
  faCaretUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compareAsc, format, subDays } from "date-fns";
import colors from "nice-color-palettes";
import ClickableListItem from "../../components/ClickableListItem/ClickableList";
import Container from "../../components/Container/Container";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import styles from "./adminConsoleStyles.module.scss";
import { Link, useHistory, useParams } from "react-router-dom";
import UserDetail from "../UserDetail/UserDetail";
import Loading from "../../components/Loading";
import Input from "../../components/Input/Input";
import { Bar, Doughnut, HorizontalBar } from "react-chartjs-2";
import KPI from "../../components/KPI/KPI";
import journal from "../../assets/notebook.png";

const Summary = ({ data, barData }) => {
  console.log(barData);
  return (
    <div>
      <div className={styles.summaryWrapper}>
        <div className={styles.summaryKpisWrapper}>
          <div>
            <div className={styles.kpiRow}>
              <KPI
                val={data.totalEntries}
                label="Total Sessions"
                style={{ marginRight: "20px" }}
              />
              <KPI
                val={data.totalNotes}
                label="Total Strain Notes"
                style={{ marginRight: "20px" }}
              />
              <KPI val={data.avgSessionsPerDay} label="Avg Sessions Per Day" />
            </div>
          </div>
          <div className={styles.donutWrapper}>
            <div style={{ flex: "50%" }}>
              <Doughnut
                data={{
                  labels: Object.keys(data.sessionsByMethod),
                  datasets: [
                    {
                      label: "Total Entries",
                      backgroundColor: colors[10],
                      hoverBackgroundColor: colors[10],
                      data: Object.keys(data.sessionsByMethod).map(
                        (method) => data.sessionsByMethod[method]
                      ),
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: "Method",
                  },
                  legend: { display: false },
                }}
              />
            </div>
            <div style={{ flex: "50%" }}>
              <Doughnut
                data={{
                  labels: Object.keys(data.sessionsByUserType),
                  datasets: [
                    {
                      label: "Total Entries",
                      backgroundColor: colors[10],
                      hoverBackgroundColor: colors[10],
                      data: Object.keys(data.sessionsByUserType).map(
                        (type) => data.sessionsByUserType[type]
                      ),
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: "User Type",
                  },
                  legend: { display: false },
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.summaryChartWrapper}>
          <HorizontalBar
            data={{
              labels: barData.map(
                (employee) => `${employee.firstName} ${employee.lastName}`
              ),
              datasets: [
                {
                  backgroundColor: "#48a893",

                  hoverBackgroundColor: "#59c5ad",
                  data: barData.map((employee) => employee.totalEntries),
                },
              ],
            }}
            options={{
              legend: { display: false },
              title: { display: true, text: "Top Users" },
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const PastSevenDays = ({ data, style, className }) => {
  const barData = {
    labels: data.entriesByDay.map((d) => format(new Date(d.date), "MMM d")),
    datasets: [
      {
        label: "Total Entries",
        backgroundColor: "#48a893",

        hoverBackgroundColor: "#59c5ad",

        data: data.entriesByDay.map((d) => parseInt(d.totalEntries)),
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    showScale: true,
    pointDot: true,
    showLines: false,
    legend: {
      display: false,
      labels: {
        boxWidth: 50,
        fontSize: 14,
        fontColor: "#222",
        padding: 5,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max:
              Math.ceil(
                data.entriesByDay
                  .map((d) => parseInt(d.totalEntries))
                  .sort((a, b) => b - a)[0] / 10
              ) * 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 7,
          },
        },
      ],
    },
  };
  return (
    <div style={{ ...style }} className={className}>
      <h2 className={styles.header2}>Past Seven Days</h2>
      <div className={styles.dataWrapper}>
        <div className={styles.barWrapper}>
          <Bar
            data={barData}
            options={{
              ...chartOptions,
              title: {
                display: true,
                text: "Number of Journal Sessions Per Day",
              },
            }}
          />
        </div>
        <div className={styles.kpisWrapper}>
          <div className={styles.topRowWrapper}>
            <div style={{ flex: "33%", textAlign: "center" }}>
              <KPI val={data.totalEntries} label="Sessions"></KPI>
            </div>
            <div style={{ flex: "33%", textAlign: "center" }}>
              <KPI val={data.totalNotes} label="Notes"></KPI>
            </div>
            <div style={{ flex: "33%", textAlign: "center" }}>
              <KPI
                val={(data.totalEntries / 7).toFixed(2)}
                label="Avg. Sessions Per Day"
              ></KPI>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <div style={{ flex: "50%" }}>
              <Doughnut
                data={{
                  labels: Object.keys(data.entriesByMethod),
                  datasets: [
                    {
                      label: "Total Entries",
                      backgroundColor: colors[10],
                      hoverBackgroundColor: colors[10],
                      data: Object.keys(data.entriesByMethod).map(
                        (method) => data.entriesByMethod[method]
                      ),
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: "Method",
                  },
                  legend: { display: false },
                }}
              />
            </div>
            <div style={{ flex: "50%" }}>
              <Doughnut
                data={{
                  labels: Object.keys(data.entriesByUserType),
                  datasets: [
                    {
                      label: "Total Entries",
                      backgroundColor: colors[10],
                      hoverBackgroundColor: colors[10],
                      data: Object.keys(data.entriesByUserType).map(
                        (type) => data.entriesByUserType[type]
                      ),
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  title: {
                    display: true,
                    text: "User Type",
                  },
                  legend: { display: false },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableHeader = ({ width, children, selected, onClick, reverse }) => {
  return (
    <button
      className={[styles.tableHeader, selected ? styles.selected : null].join(
        " "
      )}
      style={{ flex: width, maxWidth: width, overflow: "hidden" }}
      onClick={onClick}
    >
      {children}{" "}
      {selected ? (
        <FontAwesomeIcon icon={reverse ? faCaretUp : faCaretDown} />
      ) : null}
    </button>
  );
};

const JournalReport = (props) => {
  let { page, id } = useParams();
  const history = useHistory();

  const sortByField = (arr, fieldName, reverse = false) => {
    if (reverse) {
      return arr.sort((a, b) =>
        a[fieldName] > b[fieldName] ? -1 : a[fieldName] === b[fieldName] ? 0 : 1
      );
    } else {
      return arr.sort((a, b) =>
        a[fieldName] > b[fieldName] ? 1 : a[fieldName] === b[fieldName] ? 0 : -1
      );
    }
  };
  const firebase = useContext(FirebaseContext);

  const [users, setUsers] = useState(false);
  const [kpis, setKpis] = useState(false);
  const [pastSevenDays, setPastSevenDays] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedHeader, setSelectedHeader] = useState("Name");

  const filteredUsers = users
    ? users.filter((user) => {
        const name = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;
        return name.includes(searchValue.toLowerCase());
      })
    : [];

  const getDosageJournalUsageData = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getDosageJournalUsageData`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };
  useEffect(() => {
    let subscribed = true;
    setLoading(true);
    getDosageJournalUsageData().then((response) => {
      console.log(response);
      if (subscribed) {
        setUsers(sortByField(response.employeeData, "lastName"));
        setKpis(response.kpis);
        setPastSevenDays(response.pastSevenDays);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  useEffect(() => {
    if (users && kpis && pastSevenDays) {
      setLoading(false);
    }
  }, [users, kpis, pastSevenDays]);

  const handleSelectHeader = (header) => {
    const headerFieldMap = {
      Name: "lastName",
      Email: "email",
      "Total Sessions": "totalEntries",
      "Sessions Past 7 Days": "entriesPastSevenDays",
      "Total Notes": "numNotes",
    };
    if (header !== selectedHeader) {
      const sortedUsers = sortByField(users, headerFieldMap[header]);
      setReverse(false);
      setSelectedHeader(header);
      setUsers(sortedUsers);
    } else {
      const sortedUsers = sortByField(
        users,
        headerFieldMap[header],
        reverse ? false : true
      );
      setUsers(sortedUsers);
      setReverse((v) => !v);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (page === "user-detail") {
    return (
      <UserDetail
        user={users.find((user) => user.firebaseId === id)}
      ></UserDetail>
    );
  }

  const today = new Date();
  const sevenDaysAgo = subDays(today, 7);
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.headerWrapper} style={{ marginBottom: "50px" }}>
          <div>
            <h1>Dosage Journal</h1>
            <h2>Usage Summary</h2>
          </div>
          <div className={styles.headerImageWrapper}>
            <img
              src={journal}
              alt="journal"
              style={{ width: "100%", maxWidth: "200px", height: "auto" }}
            ></img>
          </div>
        </div>

        <Summary
          data={kpis}
          barData={sortByField(users, "totalEntries", true).slice(0, 5)}
        />
        <PastSevenDays style={{ marginTop: "100px" }} data={pastSevenDays} />
        <div style={{ padding: "20px 0" }}>
          <h2
            className={styles.header2}
            style={{
              margin: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>Portal Users ({filteredUsers.length})</span>

            <Input
              type="text"
              style={{ fontSize: "0.7em" }}
              label={
                <span>
                  <FontAwesomeIcon icon={faSearch} /> Search
                </span>
              }
              value={searchValue}
              onChange={(v) => {
                setSearchValue(v);
              }}
            />
          </h2>
          <div className={styles.tableHeaderWrapper}>
            <TableHeader
              width="29%"
              selected={selectedHeader === "Name"}
              reverse={reverse}
              onClick={() => {
                handleSelectHeader("Name");
              }}
            >
              Name
            </TableHeader>
            <TableHeader
              width="29%"
              selected={selectedHeader === "Email"}
              reverse={reverse}
              onClick={() => {
                handleSelectHeader("Email");
              }}
            >
              Email
            </TableHeader>
            <TableHeader
              width="14%"
              selected={selectedHeader === "Total Sessions"}
              reverse={reverse}
              onClick={() => {
                handleSelectHeader("Total Sessions");
              }}
            >
              Total Sessions
            </TableHeader>
            <TableHeader
              width="14%"
              selected={selectedHeader === "Sessions Past 7 Days"}
              reverse={reverse}
              onClick={() => {
                handleSelectHeader("Sessions Past 7 Days");
              }}
            >
              Sessions Past 7 Days
            </TableHeader>
            <TableHeader
              width="14%"
              selected={selectedHeader === "Total Notes"}
              reverse={reverse}
              onClick={() => {
                handleSelectHeader("Total Notes");
              }}
            >
              Total Notes
            </TableHeader>
          </div>
          {filteredUsers.map((user) => {
            return (
              <ClickableListItem
                key={user.firebaseId}
                onClick={() => {
                  history.push(`/admin-console/user-detail/${user.firebaseId}`);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "100%",
                    padding: "0 5px",
                  }}
                >
                  <div
                    style={{ flex: "29%", overflow: "hidden", maxWidth: "29%" }}
                  >
                    {user.lastName}, {user.firstName}
                  </div>
                  <div
                    style={{ flex: "29%", overflow: "hidden", maxWidth: "29%" }}
                  >
                    {user.email}
                  </div>
                  <div
                    style={{ flex: "14%", overflow: "hidden", maxWidth: "14%" }}
                  >
                    {user.totalEntries}
                  </div>
                  <div
                    style={{ flex: "14%", overflow: "hidden", maxWidth: "14%" }}
                  >
                    {user.entriesPastSevenDays}
                  </div>
                  <div
                    style={{ flex: "14%", overflow: "hidden", maxWidth: "14%" }}
                  >
                    {user.numNotes}
                  </div>
                </div>
              </ClickableListItem>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

const AdminConsole = () => {
  const { page, id } = useParams();

  if (page === "journal-report") {
    return <JournalReport />;
  }
  return (
    <Container>
      {/*<h1>Admin Tools</h1>*/}
      <JournalReport />
    </Container>
  );
};

export default AdminConsole;
