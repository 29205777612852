import React, { useState, useContext, useEffect } from "react";
import styles from "./strainNotesStyles.module.scss";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PopUp from "../PopUp/PopUp";
import Loading from "../Loading/Loading";
import LinkButton from "../LinkButton/LinkButton";
import EmployeePhoto from "../EmployeePhoto/EmployeePhoto";
import { format } from "date-fns";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import TextDisplay from "../TextDisplay/TextDisplay";
import TextEditor from "../TextEditor/TextEditor";
import { convertToRaw } from "draft-js";

const Note = ({
  data,
  employeeData,
  handleDeleteNote,
  noTopBorder,
  liked,
  style,
  className,
}) => {
  const firebase = useContext(FirebaseContext);
  const dates = data.createdAt.split("T")[0].split("-");
  const date = format(
    new Date(dates[0], parseInt(dates[1]) - 1, dates[2]),
    "MMM d, yyyy"
  );
  const [url, setUrl] = useState("");
  //const { getAccessTokenSilently } = useAuth0();

  const getUrl = async (id) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getSignedImgUrlFromId?id=${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();

    return jsonResponse.url;
  };

  useEffect(() => {
    let subscribed = true;

    getUrl(data.firebaseId).then((url) => {
      if (subscribed) {
        setUrl(url);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  return (
    <div>
      {noTopBorder ? <div style={{ margin: "30px 0" }} /> : <hr></hr>}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <EmployeePhoto
            signedUrl={url}
            width={50}
            height={50}
            round
          ></EmployeePhoto>
          <div style={{ marginLeft: "10px" }}>
            <h4
              style={{
                fontSize: "1em",
                fontWeight: "bold",
                margin: 0,
              }}
            >
              {data.authorDisplayName || "Anonymous"}
            </h4>
            <div>{date}</div>
          </div>
        </div>
        {data.firebaseId === employeeData.firebaseId ? (
          <div>
            <LinkButton onClick={() => handleDeleteNote(data._id)}>
              <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
            </LinkButton>
          </div>
        ) : null}
      </div>
      <div style={{ textAlign: "left" }}>
        {data.text ? <TextDisplay rawData={data.text} /> : null}
      </div>
      {/*<div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          left: "-4px",
        }}
      >
        <LikeButton
          onClick={() => {
      
          }}
        />
        <span style={{ fontSize: "0.9em" }}>{data.likes.length}</span>
        </div>*/}
    </div>
  );
};

const StrainNotes = ({
  data,
  handleSave,
  handleDeleteNote,
  className,
  style,
  isMobile,
}) => {
  const [showAddNotePopUp, setShowAddNotePopUp] = useState(false);
  const [noteContent, setNoteContent] = useState();
  const [saving, setSaving] = useState(false);
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;

  return (
    <div className={[styles.wrapper, className].join(" ")} style={{ ...style }}>
      {showAddNotePopUp ? (
        <PopUp isMobile={isMobile}>
          {saving ? (
            <Loading></Loading>
          ) : (
            <div className={styles.responsiveWidth}>
              <TextEditor
                isMobile={isMobile}
                onChange={(v) => {
                  setNoteContent(v);
                }}
                placeholder="Add a note..."
              />
              <div>
                <Button
                  onClick={() => {
                    setNoteContent(null);
                    setShowAddNotePopUp(false);
                  }}
                  style={{ margin: "10px" }}
                  red
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setSaving(true);
                    handleSave(
                      convertToRaw(noteContent.getCurrentContent())
                    ).then(() => {
                      setSaving(false);
                      setShowAddNotePopUp(false);
                    });
                  }}
                  style={{ margin: "10px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </PopUp>
      ) : null}
      <div className={styles.headerWrapper}>
        <h3>Notes ({data.length})</h3>
        <Button
          onClick={() => {
            setShowAddNotePopUp(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
        </Button>
      </div>
      <div>
        {data.map((note, i) => {
          return (
            <Note
              key={`${i}-${note._id}`}
              data={note}
              employeeData={employeeData}
              handleDeleteNote={handleDeleteNote}
              noTopBorder={i === 0}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StrainNotes;
