import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import MobileApp from "./MobileApp";
import * as serviceWorker from "./serviceWorker";
import FirebaseProvider from "./components/FirebaseProvider/FirebaseProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AppDataProvider from "./components/AppDataProvider";
import { isMobile } from "react-device-detect";

ReactDOM.render(
  <FirebaseProvider>
    <AppDataProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {isMobile ? <MobileApp /> : <App />}
      </MuiPickersUtilsProvider>
    </AppDataProvider>
  </FirebaseProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
