import React, { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import animate from "../../assets/styles/animate.module.scss";
import EmployeePhoto from "../EmployeePhoto/EmployeePhoto";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";

const MobileAccountSideBar = ({ onExit }) => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const employeeData = firebase.data;

  const handleClickOutside = (e) => {
    const classList = e.target.classList.toString();

    if (!classList.includes("sideBar")) {
      onExit();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: 999,
      }}
      className={[animate.animated, animate.fadeIn, animate.fast].join(" ")}
    >
      <div
        className={[
          "sideBar",
          animate.animated,
          animate.slideInRight,
          animate.fast,
        ].join(" ")}
        style={{
          position: "fixed",
          top: 0,
          left: "40vw",
          right: 0,
          bottom: 0,
          backgroundColor: "white",
          zIndex: 9999,
          padding: "10px",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          className="sideBar"
        >
          <EmployeePhoto
            width={70}
            height={70}
            round
            signedUrl={employeeData.signedUrl}
            style={{ marginRight: "10px" }}
          ></EmployeePhoto>
          <div
            style={{ fontWeight: "bold" }}
          >{`${employeeData.firstName} ${employeeData.lastName}`}</div>
        </div>
        <TouchableOpacity
          className={["sideBar"].join(" ")}
          noBorder
          style={{ textAlign: "left", marginTop: "20px" }}
          onClick={() => {
            //history.push("/");

            history.push("/settings");
            onExit();
          }}
        >
          <span className="sideBar">Settings</span>
        </TouchableOpacity>

        <TouchableOpacity
          className={["sideBar"].join(" ")}
          noBorder
          style={{ textAlign: "left", marginTop: "20px" }}
          onClick={() => {
            //history.push("/");

            firebase.auth.signOut();
          }}
        >
          <span className="sideBar">Logout</span>
        </TouchableOpacity>
      </div>
    </div>
  );
};

export default MobileAccountSideBar;
