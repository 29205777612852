import React from "react";
import styles from "./helixStyles.module.scss";

const Chain = ({ delayClass, color = "green" }) => {
  const c = color.toLowerCase();
  return (
    <div className={styles.chain}>
      <div className={[styles.line, delayClass].join(" ")}></div>
      <div
        className={[
          styles.ball,
          delayClass,
          c === "green"
            ? styles.green
            : c === "red"
            ? styles.red
            : c === "blue"
            ? styles.blue
            : c === "yellow"
            ? styles.yellow
            : null,
        ].join(" ")}
      ></div>
    </div>
  );
};

const Helix = ({ color = "green", mini }) => {
  return (
    <div className={[styles.wrapper, mini ? styles.mini : null].join(" ")}>
      <div className={[styles.dnaLoader, styles.spaceY3].join(" ")}>
        {[
          styles.first,
          styles.second,
          styles.third,
          styles.fourth,
          styles.fifth,
          styles.sixth,
          styles.seventh,
          styles.eighth,
        ].map((delay) => (
          <Chain key={delay} delayClass={delay} color={color} />
        ))}
      </div>
    </div>
  );
};

export default Helix;
