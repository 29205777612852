import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import spinner from "../../assets/spinner.png";

import styles from "./loadingStyles.module.scss";

const Loading = (props) => {
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: "50px",
        color: "rgb(80,80,80)",
        flex: "1",
        display: props.inline ? "inline-flex" : "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <img
        src={spinner}
        alt="spinner"
        className={styles.loader}
        height={30}
      ></img>
    </div>
  );
};

export default Loading;
