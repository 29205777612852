import { faFrownOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";
import ClickableListItem from "../ClickableListItem/ClickableList";
import EntryDetail from "../EntryDetail/EntryDetail";
import Flex from "../Flex/Flex";
import MethodIcon from "../MethodIcon";

import styles from "./previousEntriesPreviewStyles.module.scss";

export const SessionListItem = ({ session, strain, onClick }) => {
  const d = new Date(session.entryDate);
  return (
    <ClickableListItem noShadow onClick={onClick}>
      <Flex
        justifyContent="space-between"
        style={{
          minHeight: "100%",
          padding: "0 0 0 10px",
          textAlign: "left",
        }}
      >
        <div>
          <div>
            <span style={{ fontWeight: "bold" }}>{format(d, "MM-dd")}</span>
            <span> at {format(d, "p")}</span>
          </div>
          <div>{session.product?.name || strain.strainName}</div>
        </div>
        <div className={styles.methodIcon}>
          <MethodIcon method={session.method} />
        </div>
      </Flex>
    </ClickableListItem>
  );
};

export const NoSessionsPlaceholder = () => {
  const history = useHistory();
  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "rgb(248,248,248)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "200px",
        padding: "30px",
        margin: "20px 0",
      }}
    >
      <div>
        <FontAwesomeIcon
          icon={faFrownOpen}
          style={{ fontSize: "50px", color: "rgb(220,220,220)" }}
        ></FontAwesomeIcon>
      </div>
      <div
        style={{
          fontWeight: "bold",
          color: "rgb(100,100,100)",
          margin: "10px 0",
        }}
      >
        No Entries Yet.
      </div>
      <Button
        onClick={() => {
          history.push("/dosage-journal/new-session");
        }}
        compact
      >
        Get Started
      </Button>
    </div>
  );
};

const PreviousEntriesPreview = ({
  entries,
  strains,
  style,
  className,
  onDelete = () => {},
}) => {
  const [currentEntry, setCurrentEntry] = useState(false);
  if (entries.length === 0) {
    return <NoSessionsPlaceholder />;
  }
  if (currentEntry) {
    return (
      <EntryDetail
        entryData={currentEntry}
        style={{ marginTop: "20px" }}
        strains={strains}
        onBack={() => {
          setCurrentEntry(false);
        }}
        onDelete={() => {
          onDelete();
          setCurrentEntry(false);
        }}
      />
    );
  }
  return (
    <div style={{ ...style }} className={className}>
      {entries.map((entry) => {
        const strain = strains.find((strain) => strain._id === entry.strainId);

        return (
          <SessionListItem
            strain={strain}
            session={entry}
            onClick={() => {
              setCurrentEntry(entry);
            }}
            key={entry._id}
          />
        );
      })}
    </div>
  );
};

export default PreviousEntriesPreview;
