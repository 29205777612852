const prefixes = ["employees", "strains", "sessions"];

const actions = [
  "create",
  "read",
  "readCurrentUser",
  "update",
  "updateCurrentUser",
  "delete",
];

const baseEmployee = [
  "employees:read",
  "employees:updateCurrentUser",
  "strains:read",
  "sessions:read",
  "sessions:create",
  "sessions:updateCurrentUser",
];

const nonEmployee = [
  "employees:readCurrentUser",
  "strains:read",
  "sessions:read",
  "sessions:create",
  "sessions:updateCurrentUser",
];

const rolesAndPermissions = {
  "Tech-Admin": prefixes.map((prefix) => `${prefix}:all`),
  Admin: ["strains:update", "strains:create", "strains:delete"],
  "Manager-HR": ["employees:update", "employees:create", "employees:delete"],
  "Manager-Cultivation": ["strains:update", "strains:create", "strains:delete"],
  Employee: baseEmployee,
  "Non-Employee": nonEmployee,
};

export default rolesAndPermissions;
