import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp as faThumbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./likeButtonStyles.module.scss";

const LikeButton = ({
  icon = faThumbsUp,
  selectedIcon = faThumbsUpSolid,
  selected,
  onClick,
  style,
  className,
  size = "sm",
}) => {
  return (
    <button
      onClick={onClick}
      className={[
        styles.iconButton,
        selected ? styles.selected : null,
        size === "sm"
          ? styles.sm
          : size === "md"
          ? styles.md
          : size === "lg"
          ? styles.lg
          : null,
      ].join(" ")}
      style={{ ...style }}
    >
      <FontAwesomeIcon icon={selected ? selectedIcon : icon} />
    </button>
  );
};

export default LikeButton;
