import React, { useState, useEffect, useContext, Fragment } from "react";
import styles from "./strainDetailStyles.module.scss";
import { useParams, useHistory } from "react-router-dom";
//import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import Can from "../../components/Can/Can";
import StrainNotes from "../../components/StrainNotes/StrainNotes";
import { HorizontalBar } from "react-chartjs-2";
import Container from "../../components/Container/Container";
import KPI from "../../components/KPI/KPI";

import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Helix from "../../components/Helix/Helix";
import StackBanner from "../../components/StackBanner/StackBanner";
import { colors } from "../../utils/constants";
import { MobileH1 } from "../Journal/Journal";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { AppDataContext } from "../../components/AppDataProvider";

const StrainDetail = () => {
  const firebase = useContext(FirebaseContext);
  const { id } = useParams();
  const { strains, reloadData } = useContext(AppDataContext);
  const employeeData = firebase.data;
  const strain = strains.find((s) => s._id === id);
  const [journalData, setJournalData] = useState({});
  const [showingDataFor, setShowingDataFor] = useState("All Users");
  const selectedJournalData =
    showingDataFor === "All Users"
      ? journalData.allUsers || {}
      : journalData.justMe || {};

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: 0,
      minWidth: 120,
      minHeight: 10,
      padding: 0,
      fontSize: 8,
      backgroundColor: "white",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    smallerFont: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();

  const getJournalData = async () => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getJournalDataByStrain?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  const createStrainNote = async (noteContent) => {
    const note = {
      //authId: employeeData.authId,
      firebaseId: employeeData.firebaseId,
      author: `${employeeData.firstName} ${employeeData.lastName}`,
      text: noteContent || undefined,
    };

    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/createStrainNote/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(note),
    });
    const jsonResponse = await response.json();
    reloadData();
    return jsonResponse;
  };

  const deleteNote = async (noteId) => {
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/deleteStrainNote/${id}/${noteId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    reloadData();
    return jsonResponse;
  };

  useEffect(() => {
    let subscribed = true;

    getJournalData().then((data) => {
      if (subscribed) {
        setJournalData(data);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  const feelingsData = {
    labels: [...Object.keys(selectedJournalData.feelings || {})],
    datasets: [
      {
        label: "Avg Rating (out of 5)",
        backgroundColor: colors[3],

        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Object.keys(selectedJournalData.feelings || {}).map(
          (feeling) => selectedJournalData.feelings[feeling]
        ),
      },
    ],
  };

  const effectsData = {
    labels: [
      ...Object.keys(selectedJournalData.sideEffects || {}).filter(
        (key) => parseFloat(selectedJournalData.sideEffects[key]) > 0
      ),
    ],
    datasets: [
      {
        label: "Avg Rating (out of 5)",
        backgroundColor: colors[3],
        data: Object.keys(selectedJournalData.sideEffects || {})
          .filter((key) => parseFloat(selectedJournalData.sideEffects[key]) > 0)
          .map((effect) => selectedJournalData.sideEffects[effect]),
      },
    ],
  };

  const symptomsData = {
    labels: [...Object.keys(selectedJournalData.symptoms || {})],
    datasets: [
      {
        label: "Avg Rating (out of 5)",
        backgroundColor: colors[3],

        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Object.keys(selectedJournalData.symptoms || {}).map(
          (effect) => selectedJournalData.symptoms[effect]
        ),
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    showScale: true,
    pointDot: true,
    showLines: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 5,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 5,
          },
        },
      ],
    },
  };

  const { chemistry, notes } = strain;

  return (
    <div style={{ textAlign: "left" }}>
      <div style={{ backgroundColor: "rgb(235,235,235)", padding: "5px 8px" }}>
        <FontAwesomeIcon
          icon={faLeaf}
          style={{ color: colors[3], marginRight: "10px", fontSize: "1.1em" }}
        ></FontAwesomeIcon>
        <span style={{ fontWeight: "bold", color: colors[4] }}>
          {strain.strainName}
        </span>
      </div>
      <div style={{ padding: "15px 8px 80px 8px" }}>
        <div style={{ marginBottom: "15px" }}>
          <MobileH1 style={{ marginBottom: "15px" }}>Chemistry</MobileH1>
          <div>
            {["thc", "thcv", "cbg", "cbc"].map((field) => {
              const minPercent = chemistry[`${field}MinPercent`];
              const maxPercent = chemistry[`${field}MaxPercent`];
              const displayValue =
                minPercent === maxPercent
                  ? `${
                      minPercent === null || minPercent === undefined
                        ? "--"
                        : minPercent
                    } %`
                  : `${minPercent} - ${maxPercent} %`;
              return (
                <div key={field}>
                  <h3 className={styles.fieldLabel}>{field.toUpperCase()}:</h3>
                  <span style={{ marginLeft: "20px" }}>{displayValue}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <MobileH1>Session Data</MobileH1>
            <FormControl className={classes.formControl}>
              <Select
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                value={showingDataFor}
                className={classes.smallerFont}
                onChange={(e) => {
                  setShowingDataFor(e.target.value);
                }}
              >
                <MenuItem value="All Users" className={classes.smallerFont}>
                  ALL USERS
                </MenuItem>
                <MenuItem value="Just Me" className={classes.smallerFont}>
                  JUST ME
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <span
              style={{
                minWidth: "120px",

                display: "inline-block",
              }}
            >
              Total Sessions
            </span>
            <span style={{ color: colors[3], fontWeight: "bold" }}>
              {selectedJournalData.totalSessions}
            </span>
          </div>
          <div>
            <span
              style={{
                minWidth: "120px",

                display: "inline-block",
              }}
            >
              Avg Rating
            </span>
            <span style={{ color: colors[3], fontWeight: "bold" }}>
              {selectedJournalData.overallRating} / 5
            </span>
          </div>

          <h3 style={{ marginTop: "20px" }}>Feelings</h3>

          <div style={{ minHeight: "300px", margin: "10px 0" }}>
            <HorizontalBar
              data={feelingsData}
              options={{
                ...chartOptions,
              }}
            ></HorizontalBar>
          </div>
          <h3>Symptom Relief</h3>
          <div style={{ minHeight: "300px", margin: "30px 0" }}>
            <HorizontalBar
              data={symptomsData}
              options={{
                ...chartOptions,
              }}
            ></HorizontalBar>
          </div>
          <h3>Side Effects</h3>
          <div style={{ minHeight: "300px", margin: "30px 0" }}>
            <HorizontalBar
              data={effectsData}
              options={{
                ...chartOptions,
              }}
            ></HorizontalBar>
          </div>
        </div>

        <StrainNotes
          isMobile
          data={notes}
          style={{ flex: "30%" }}
          handleSave={createStrainNote}
          handleDeleteNote={(noteId) => {
            deleteNote(noteId).then((response) => {
              reloadData();
            });
          }}
        ></StrainNotes>
      </div>
    </div>
  );
};

export default StrainDetail;
