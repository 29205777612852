import {
  faCircle,
  faThumbsUp as faThumbsUpSolid,
} from "@fortawesome/free-solid-svg-icons";
import { faCommentDots, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import Container from "../../components/Container/Container";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import Loading from "../../components/Loading";
import StackBanner from "../../components/StackBanner/StackBanner";
import TextDisplay from "../../components/TextDisplay/TextDisplay";
import TextEditor from "../../components/TextEditor/TextEditor";
import { Post } from "../Forum/Forum";
import styles from "./postDetailStyles.module.scss";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import LikeButton from "../../components/LikeButton/LikeButton";
import {
  differenceInHours,
  differenceInMinutes,
  format,
  isSameHour,
  isToday,
} from "date-fns";

const Reply = ({
  data,
  style,
  parents = [],
  onSubmitReply,
  onLikeReply,
  postId,
}) => {
  const [replyMode, setReplyMode] = useState(false);
  const [editorState, setEditorState] = useState();
  const firebase = useContext(FirebaseContext);

  const newParents = [...parents];
  newParents.push(data._id);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <EmployeePhoto
          round
          width={40}
          height={40}
          firebaseId={data.author.firebaseId}
        />
        <div style={{ marginLeft: "10px", flex: 1 }}>
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "0.9em" }}
          >
            <span>{data.author.name}</span>
            <FontAwesomeIcon
              style={{
                fontSize: "4px",
                margin: "0 10px",
                color: "rgb(150,150,150)",
              }}
              icon={faCircle}
            />
            <span>
              {data.createdAt
                ? differenceInMinutes(new Date(), new Date(data.createdAt)) < 60
                  ? `${differenceInMinutes(
                      new Date(),
                      new Date(data.createdAt)
                    )}m ago`
                  : isToday(new Date(data.createdAt))
                  ? `${differenceInHours(
                      new Date(),
                      new Date(data.createdAt)
                    )}h ago`
                  : format(new Date(data.createdAt), "MMM d, yyyy")
                : null}
            </span>
          </div>
          <div>
            <TextDisplay rawData={data.content} />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <LikeButton
              onClick={() => {
                onLikeReply([postId, ...newParents]);
              }}
              selected={data.likes.find(
                (like) => like.firebaseId === firebase.data.firebaseId
              )}
            />

            <span
              style={{
                fontSize: "0.9em",
                marginLeft: "5px",
                marginRight: "30px",
              }}
            >
              {data.likes.length}
            </span>
            <button
              style={{
                display: "flex",
                alignItems: "stretch",
                backgroundColor: "white",
                border: "none",
                padding: 0,
                fontSize: "0.9em",
              }}
              onClick={() => {
                setReplyMode(true);
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faCommentDots}
                  style={{ marginRight: "10px" }}
                />
              </div>
              <span>Reply</span>
            </button>
          </div>
          {replyMode ? (
            <Fragment>
              <TextEditor
                compact
                placeholder="Type your reply..."
                onChange={(s) => {
                  setEditorState(s);
                }}
              />
              <div style={{ textAlign: "right", marginTop: "10px" }}>
                <Button
                  red
                  compact
                  onClick={() => {
                    setReplyMode(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  compact
                  onClick={() => {
                    onSubmitReply(newParents, {
                      author: `${firebase.data.firstName} ${firebase.data.lastName}`,
                      content: convertToRaw(editorState.getCurrentContent()),
                    });
                    setReplyMode(false);
                  }}
                >
                  Post
                </Button>
              </div>
            </Fragment>
          ) : null}
          {data.replies?.map((reply) => {
            return (
              <Reply
                key={reply._id}
                data={reply}
                parents={newParents}
                onSubmitReply={onSubmitReply}
                onLikeReply={onLikeReply}
                postId={postId}
              />
            );
          })}
        </div>

        {/*<TextDisplay rawData={convertFromRaw(data.content)} />*/}
      </div>
    </div>
  );
};

const Replies = ({ data, style, onSubmitReply, postId, onLike }) => {
  const firebase = useContext(FirebaseContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  return (
    <div className={styles.repliesWrapper} style={{ ...style }}>
      <TextEditor
        placeholder={"Leave a Comment..."}
        onChange={(s) => {
          setEditorState(s);
        }}
        content={editorState}
      />
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <Button
          onClick={() => {
            onSubmitReply([], {
              author: `${firebase.data.firstName} ${firebase.data.lastName}`,
              content: convertToRaw(editorState.getCurrentContent()),
            });
            setEditorState(EditorState.createEmpty());
          }}
        >
          COMMENT
        </Button>
      </div>
      <div>
        <h3 style={{ marginBottom: "10px" }}>Comments</h3>
        {data.length > 0 ? (
          data.map((reply) => {
            return (
              <Reply
                key={reply._id}
                data={reply}
                onSubmitReply={onSubmitReply}
                onLikeReply={onLike}
                parents={[]}
                postId={postId}
              />
            );
          })
        ) : (
          <div>No Replies Yet</div>
        )}
      </div>
    </div>
  );
};

const PostDetail = () => {
  const firebase = useContext(FirebaseContext);
  const [savedPosts, setSavedPosts] = useState([]);
  const [data, setData] = useState({});
  const [replies, setReplies] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const isMounted = useRef(true);
  const history = useHistory();
  const { id } = useParams();

  const handleDelete = async (post) => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    await fetch(`/api/deletePost?id=${post._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    history.push("/forum");
  };

  const handleLike = (lineage) => {
    console.log("like");
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch("/api/likeReply", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lineage: lineage,
          name: `${firebase.data.firstName} ${firebase.data.lastName}`,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setData(json);
          setReplies(json.replies?.reverse());
        });
    });
  };

  const handleSave = (post) => {
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch("/api/savePost", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postId: post._id,
          name: `${firebase.data.firstName} ${firebase.data.lastName}`,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (isMounted.current) {
            const p = savedPosts.find((post) => post._id === json._id);
            if (p) {
              const copy = [...savedPosts];
              const index = copy.indexOf(p);
              copy.splice(index, 1);
              setSavedPosts(copy);
            } else {
              setSavedPosts((v) => [json, ...v]);
            }
          }
        });
    });
  };

  const handleSubmitReply = (parents = [], replyData = {}) => {
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch(`/api/createReply`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postId: id, parents, replyData }),
      })
        .then((response) => response.json())
        .then((json) => {
          setData(json);
          setReplies(json.replies?.reverse());
        });
    });
  };

  const handleReact = (post, reaction) => {
    console.log(post);
    console.log(reaction);
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch("/api/reactToPost", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: post._id,
          reaction: reaction,
          name: `${firebase.data.firstName} ${firebase.data.lastName}`,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (isMounted.current) {
            setData(json);
          }
        });
    });
  };

  useEffect(() => {
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch(`/api/getSavedPosts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setSavedPosts(json);
        });
    });
    fetch(`/api/getPost/${id}`, { method: "GET" })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (isMounted.current) {
          setData(json);
          setReplies(json.replies?.reverse());
          setLoadingData(false);
        }
      });

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loadingData) return <Loading />;
  return (
    <Fragment>
      <StackBanner
        data={[
          { label: "Forum", location: "/forum" },
          { label: "Post Detail" },
        ]}
      />
      <Container style={{ margin: "20px auto" }}>
        <Post
          onReact={handleReact}
          data={data}
          detail
          savedPosts={savedPosts}
          onSave={handleSave}
          onDelete={handleDelete}
        ></Post>
        <Replies
          data={replies}
          style={{ marginTop: "20px" }}
          onSubmitReply={handleSubmitReply}
          postId={id}
          onLike={handleLike}
        />
      </Container>
    </Fragment>
  );
};

export default PostDetail;
