import React, { useContext, useRef, useState, useEffect } from "react";
import { colors } from "../../utils/constants";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import { MobileH1, StrainLink } from "../Journal/Journal";
import Feelings from "../../components/Feelings/Feelings";
import SymptomRelief from "../../components/SymptomRelief/SymptomRelief";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import { AppDataContext } from "../../components/AppDataProvider";

const HeaderBar = ({ onChange, val }) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      minHeight: 10,
      padding: 0,
      fontSize: 8,
      backgroundColor: "white",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    smallerFont: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();

  return (
    <div style={{ display: "sticky" }}>
      <div
        style={{
          backgroundColor: colors[0],
          padding: "0 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          fontSize: "0.87em",
          color: colors[4],
        }}
      >
        <span>SHOWING DATA FOR: </span>
        <FormControl className={classes.formControl}>
          <Select
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            value={val}
            className={classes.smallerFont}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            <MenuItem value="All Users" className={classes.smallerFont}>
              ALL USERS
            </MenuItem>
            <MenuItem value="Just Me" className={classes.smallerFont}>
              JUST ME
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const Insights = () => {
  const [showingDataFor, setShowingDataFor] = useState("All Users");
  const [insightData, setInsightData] = useState([]);
  const [loadingInsightData, setLoadingInsightData] = useState(true);
  const { strains } = useContext(AppDataContext);
  const isMounted = useRef(true);
  const firebase = useContext(FirebaseContext);
  const feelingsRef = useRef();
  const symptomReliefRef = useRef();
  const history = useHistory();

  const getInsightData = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/getInsightsData`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  useEffect(() => {
    getInsightData().then((insights) => {
      if (isMounted.current) {
        setInsightData(insights);
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (insightData) {
      setLoadingInsightData(false);
    }
  }, [insightData]);

  const data =
    showingDataFor === "All Users" ? insightData.allUsers : insightData.justMe;

  if (loadingInsightData) {
    return <Loading></Loading>;
  }
  return (
    <div>
      <HeaderBar
        onChange={(v) => {
          setShowingDataFor(v);
        }}
        val={showingDataFor}
      ></HeaderBar>
      <div
        style={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "flex-start",
          padding: "0 8px 80px 8px",
        }}
      >
        <div style={{ padding: "12px 0" }}>
          <span style={{ fontWeight: "bold", color: colors[3] }}>
            {data?.edibleSessions + data?.smokeSessions || "-"}
          </span>
          <span> Total Sessions</span>
        </div>
        <MobileH1>Highest Rated</MobileH1>
        <StrainLink
          onClick={() => {
            history.push(`/strains/${data?.overall._id}`);
          }}
          strainName={data?.overall.strainName}
          ratingLabel="Avg Rating"
          rating={data?.overall.overallRating}
          numSessions={data?.overall.numSessions}
          numNotes={data?.overall.numNotes}
          noBorder
        ></StrainLink>
        <MobileH1 style={{ marginTop: "10px" }}>Most Smoked</MobileH1>
        <StrainLink
          onClick={() => {
            history.push(`/strains/${data?.mostSmoked._id}`);
          }}
          strainName={data?.mostSmoked.strainName}
          ratingLabel="Avg Rating"
          rating={data?.mostSmoked.overallRating}
          numSessions={data?.mostSmoked.numSessions}
          numNotes={data?.mostSmoked.numNotes}
          noBorder
        ></StrainLink>

        <Feelings
          style={{ marginTop: "10px" }}
          data={data?.feelings}
          strains={strains}
          refProp={feelingsRef}
          renderChart={true}
        />

        <SymptomRelief
          data={data?.symptoms}
          strains={strains}
          refProp={symptomReliefRef}
          renderChart={true}
        ></SymptomRelief>
      </div>
    </div>
  );
};

export default Insights;
