import {
  faChartBar,
  faFrownOpen,
  faHeart,
  faLaughSquint,
  faSurprise,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faComment,
  faImage,
  faPlusCircle,
  faSave,
  faHeart as faHeartSolid,
  faLaughSquint as faLaughSquintSolid,
  faSurprise as faSurpriseSolid,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { convertToRaw } from "draft-js";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import CheckboxButtons from "../../components/CheckboxButtons/CheckboxButtons";
import ClickableListItem from "../../components/ClickableListItem/ClickableList";
import Container from "../../components/Container/Container";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import FileInput from "../../components/FileInput/FileInput";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";
import LikeButton from "../../components/LikeButton/LikeButton";
import Loading from "../../components/Loading";
import PopUp from "../../components/PopUp/PopUp";
import SaveButton from "../../components/SaveButton/SaveButton";
import TextDisplay from "../../components/TextDisplay/TextDisplay";
import TextEditor from "../../components/TextEditor/TextEditor";
import { colors } from "../../utils/constants";
import styles from "./forumStyles.module.scss";

const categories = [
  "Home Growing",
  "Edibles",
  "Smoking",
  "Vaping",
  "Cannabis Science",
  "Random",
];

const NewPost = ({ onComplete, style }) => {
  const firebase = useContext(FirebaseContext);
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [topics, setTopics] = useState([]);
  const [content, setContent] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [imageError, setImageError] = useState(false);
  const isMounted = useRef(true);
  console.log(topics);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setShowValidationErrors(false);
  }, [title, topics]);

  const getValidationErrors = () => {
    const errors = [];
    if (topics.length === 0) errors.push("Please select at least one topic.");
    if (title.trim() === "") errors.push("Please enter a title.");
    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = getValidationErrors();
    if (validationErrors.length === 0) {
      const formData = new FormData();
      if (imageFile) {
        formData.append("imageFile", imageFile);
        formData.append(
          "filename",
          `forum/${firebase.data.firebaseId}-${Date.now()}`
        );
      }
      formData.append(
        "author",
        `${firebase.data.firstName} ${firebase.data.lastName}`
      );
      formData.append("title", title);
      formData.append("topics", JSON.stringify(topics));
      if (content)
        formData.append(
          "content",
          JSON.stringify(convertToRaw(content.getCurrentContent()))
        );
      setSubmitting(true);
      const token = await firebase.auth.currentUser.getIdToken(true);
      const response = await fetch("/api/submitNewPost", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const jsonResponse = await response.json();
      if (isMounted.current) {
        setSubmitting(false);
        console.log(jsonResponse);
        onComplete(jsonResponse);
      }
      return jsonResponse;
    } else {
      setShowValidationErrors(validationErrors);
    }
  };

  return (
    <div className={styles.newPostWrapper} style={{ ...style }}>
      <h2>
        <FontAwesomeIcon icon={faPlusCircle} /> New Post
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "20px 0 10px 0",
        }}
      >
        <h4>Topics - Choose up to 3 (at least 1 required)</h4>
        <div style={{ marginTop: "10px" }}>
          <CheckboxButtons
            compact
            max={3}
            selected={topics}
            options={categories}
            onChange={(t) => {
              setTopics(t);
            }}
            style={{ marginRight: "5px" }}
          />
        </div>
      </div>
      <div style={{ display: imageFile ? "block" : "none" }}>
        <img
          src={imageFile ? URL.createObjectURL(imageFile) : null}
          height={200}
          width={"auto"}
          style={{ marginBottom: "10px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faImage}
          style={{
            fontSize: "60px",
            color: colors[2],
            margin: "0 10px 0 0",
            lineHeight: 0,
            padding: 0,
          }}
        />

        <FileInput
          onChange={(f) => {
            console.log(f);

            if (
              ["image/jpeg", "image/png", "image/gif"].includes(f.type) ===
              false
            ) {
              setImageError(
                "File type not supported. Must be JPG, PNG, or GIF."
              );
            } else if (f.size > 8014498) {
              setImageError("File is too large. Max size: 8MB");
            } else {
              setImageFile(f);
              setImageError(false);
            }
          }}
        >
          Upload an Image
        </FileInput>
      </div>
      {imageError ? <div style={{ color: "red" }}>{imageError}</div> : null}
      <span style={{ fontSize: "0.8em" }}>
        Supported formats: .jpg .png .gif
      </span>

      <Input
        label="Title (required)"
        value={title}
        onChange={(v) => {
          setTitle(v);
        }}
        fluid
      ></Input>

      <TextEditor
        onChange={(c) => {
          setContent(c);
        }}
      />
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <Button
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          Post
        </Button>
        {showValidationErrors
          ? showValidationErrors.map((error) => (
              <div style={{ color: "red", fontSize: "0.9em" }}>{error}</div>
            ))
          : null}
      </div>
    </div>
  );
};

export const Post = ({
  data,
  style,
  detail,
  onSave,
  savedPosts,
  onReact,
  onDelete,
}) => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  return (
    <div
      className={[styles.postWrapper, detail ? styles.detail : null].join(" ")}
      style={{ ...style }}
      onClick={
        detail
          ? undefined
          : (e) => {
              console.log(e.currentTarget);
              history.push(`/forum/post-detail/${data._id}`);
            }
      }
    >
      {showDeletePopUp ? (
        <PopUp>
          <h4 style={{ marginBottom: "10px" }}>Delete this post?</h4>
          <Button
            red
            style={{ marginRight: "5px" }}
            onClick={(e) => {
              e.stopPropagation();
              setShowDeletePopUp(false);
            }}
          >
            No
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(data);
            }}
          >
            Yes
          </Button>
        </PopUp>
      ) : null}

      {data.author.firebaseId === firebase.data.firebaseId ? (
        <LikeButton
          icon={faTrash}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowDeletePopUp(true);
          }}
          style={{
            position: "absolute",
            top: "4px",
            right: "4px",
            color: "red",
          }}
        />
      ) : null}
      <div className={styles.scoreWrapper}>
        <div className={styles.reactionWrapper}>
          <LikeButton
            icon={faHeart}
            selectedIcon={faHeartSolid}
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              onReact(data, "love");
            }}
            selected={data.reactions?.love.find(
              (user) => user.firebaseId === firebase.data.firebaseId
            )}
          />

          <span style={{ minWidth: "20px", textAlign: "center" }}>
            {data.reactions?.love.length}
          </span>
        </div>
        <div className={styles.reactionWrapper}>
          <LikeButton
            icon={faLaughSquint}
            selectedIcon={faLaughSquintSolid}
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              onReact(data, "laugh");
            }}
            selected={data.reactions?.laugh.find(
              (user) => user.firebaseId === firebase.data.firebaseId
            )}
          />

          <span style={{ minWidth: "20px", textAlign: "center" }}>
            {data.reactions?.laugh.length}
          </span>
        </div>
        <div className={styles.reactionWrapper}>
          <LikeButton
            icon={faSurprise}
            selectedIcon={faSurpriseSolid}
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              onReact(data, "whoa");
            }}
            selected={data.reactions?.whoa.find(
              (user) => user.firebaseId === firebase.data.firebaseId
            )}
          />

          <span style={{ minWidth: "20px", textAlign: "center" }}>
            {data.reactions?.whoa.length}
          </span>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentHeaderWrapper}>
          <span>
            {data.topics?.map((topic) => {
              return (
                <button
                  key={topic}
                  className={styles.topicBadge}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push({
                      pathname: "/forum",
                      key: Date.now(),
                      state: { filter: topic },
                    });
                  }}
                >
                  {topic}
                </button>
              );
            })}
          </span>
          <FontAwesomeIcon
            style={{
              fontSize: "5px",
              margin: "0 20px",
              color: "rgb(150,150,150)",
            }}
            icon={faCircle}
          />

          <EmployeePhoto
            round
            width={40}
            height={40}
            style={{ marginRight: "10px" }}
            firebaseId={data.author.firebaseId}
          />

          <span>Posted by {data.author.name}</span>
        </div>
        {data.imgLocation ? (
          <div
            style={{ maxWidth: "550px", overflow: "hidden", padding: "5px 0" }}
          >
            <img
              src={`https://storage.googleapis.com/health-portal/${data.imgLocation}`}
              style={{
                height: "250px",
                width: "auto",
              }}
            />
          </div>
        ) : null}
        <div className={styles.contentTitleWrapper}>
          <h3>{data.title}</h3>
        </div>
        <div className={styles.contentBodyWrapper}>
          {data.content ? <TextDisplay rawData={data.content} /> : null}
        </div>
        <div className={styles.contentFooterWrapper}>
          <span
            style={{
              marginRight: "20px",
              display: "inline-flex",
              alignItems: "center",
            }}
            className={styles.contentFooterItem}
          >
            <FontAwesomeIcon icon={faComment} />{" "}
            <span style={{ marginLeft: "5px" }}>
              {data.replies.length} Comments
            </span>
          </span>
          <SaveButton
            className={styles.contentFooterItem}
            onClick={(e) => {
              e.stopPropagation();
              onSave(data);
            }}
            selected={savedPosts?.find((post) => post._id === data._id)}
          />
        </div>
      </div>
    </div>
  );
};

const Posts = ({ data, onSave, savedPosts, onReact, onDelete }) => {
  return (
    <div>
      {data.map((postData) => (
        <Post
          savedPosts={savedPosts}
          key={postData._id}
          data={postData}
          style={{ marginBottom: "15px" }}
          onSave={onSave}
          onReact={onReact}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

const PreviewContainer = ({ children }) => {
  return <div className={styles.previewContainer}>{children}</div>;
};

const Preview = ({ title, children }) => {
  return (
    <Fragment>
      <h3 style={{ marginBottom: "5px" }}>{title}</h3>
      <PreviewContainer>{children}</PreviewContainer>
    </Fragment>
  );
};

const MostPopularPreview = () => {
  return (
    <Preview title={"Trending"}>
      <div
        style={{
          textAlign: "center",
          padding: "30px",
          color: "rgb(150,150,150)",
        }}
      >
        <div>
          <FontAwesomeIcon
            icon={faChartBar}
            style={{ fontSize: "3em", color: "rgb(220,220,220)" }}
          />
        </div>
        <div style={{ fontSize: "0.9em" }}>Not Enough Data Yet</div>
      </div>
    </Preview>
  );
};

const PostPreview = ({ post, onClick }) => {
  return (
    <ClickableListItem noShadow onClick={onClick}>
      <div
        style={{
          display: "flex",
          minHeight: "100%",
          flexDirection: "column",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{post.title}</div>
        <div style={{ fontSize: "0.8em" }}>
          {format(new Date(post.createdAt), "MMM d, yyyy")}
        </div>
      </div>
    </ClickableListItem>
  );
};

const MyPostsPreview = ({ created = [], saved }) => {
  const history = useHistory();
  return (
    <Preview title={"My Posts"}>
      <h4 style={{ margin: "0 0 10px 0" }}>
        <FontAwesomeIcon icon={faSave} /> Saved
      </h4>
      {saved.length > 0 ? (
        saved.map((post) => (
          <PostPreview
            key={post._id}
            post={{
              _id: post.postId,
              title: post.title,
              createdAt: post.createdAt,
            }}
            onClick={() => {
              history.push(`/forum/post-detail/${post._id}`);
            }}
          />
        ))
      ) : (
        <div>You haven't saved any posts yet.</div>
      )}
      <h4 style={{ margin: "20px 0 10px 0" }}>
        <FontAwesomeIcon icon={faPlusCircle} /> Created
      </h4>
      {created.length > 0 ? (
        created.map((post) => (
          <PostPreview
            key={post._id}
            post={post}
            onClick={() => {
              history.push(`/forum/post-detail/${post._id}`);
            }}
          />
        ))
      ) : (
        <div>You haven't created any posts yet.</div>
      )}
    </Preview>
  );
};

const NoPosts = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(249,249,249)",
        textAlign: "center",
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <FontAwesomeIcon
          icon={faFrownOpen}
          style={{ color: "rgb(200,200,200)", fontSize: "3em" }}
        />
      </div>
      <div
        style={{
          color: "rgb(180,180,180",
          fontWeight: "bold",
          marginTop: "10px",
        }}
      >
        No Posts Yet.
      </div>
    </div>
  );
};

const Forum = ({ location }) => {
  const firebase = useContext(FirebaseContext);
  const [posts, setPosts] = useState([]);
  const [myPosts, setMyPosts] = useState([]);
  const [savedPosts, setSavedPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [newPostMode, setNewPostMode] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(
    location.state?.filter || "All"
  );
  const isMounted = useRef(true);

  console.log(location.state?.filter);

  useEffect(() => {
    firebase.auth.currentUser.getIdToken().then((token) => {
      fetch(`/api/getSavedPosts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (isMounted.current) {
            setSavedPosts(json);
          }
        });
      fetch("/api/getPosts", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (isMounted.current) {
            setPosts(json);
            setLoadingPosts(false);
          }
        });
      fetch("/api/getMyPosts", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((json) => {
          if (isMounted.current) {
            setMyPosts(json);
          }
        });
    });
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (location.state?.filter) setSelectedTopic(location.state?.filter);
  }, [location.state]);

  const handleReact = (post, reaction) => {
    console.log(post);
    console.log(reaction);
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch("/api/reactToPost", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: post._id,
          reaction: reaction,
          name: `${firebase.data.firstName} ${firebase.data.lastName}`,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          const newPosts = posts.reduce((arr, current) => {
            if (current._id === json._id) {
              return [...arr, json];
            } else {
              return [...arr, current];
            }
          }, []);

          if (isMounted.current) {
            setPosts(newPosts);
          }
        });
    });
  };

  const handleDelete = async (post) => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    await fetch(`/api/deletePost?id=${post._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
  };

  const handleSave = (post) => {
    firebase.auth.currentUser.getIdToken(true).then((token) => {
      fetch("/api/savePost", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postId: post._id,
          name: `${firebase.data.firstName} ${firebase.data.lastName}`,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (isMounted.current) {
            const p = savedPosts.find((post) => post._id === json._id);
            if (p) {
              const copy = [...savedPosts];
              const index = copy.indexOf(p);
              copy.splice(index, 1);
              setSavedPosts(copy);
            } else {
              setSavedPosts((v) => [json, ...v]);
            }
          }
        });
    });
  };

  const displayPosts =
    selectedTopic === "All"
      ? posts
      : posts.filter((post) => post.topics.includes(selectedTopic));
  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          {newPostMode ? (
            <NewPost
              style={{ marginBottom: "30px" }}
              onComplete={(newPost) => {
                window.location.reload();
              }}
            />
          ) : (
            <div style={{ marginBottom: "20px" }}>
              <Button
                onClick={() => {
                  setNewPostMode(true);
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Create New Post
              </Button>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h4 style={{ marginRight: "10px" }}>Browse by Topic:</h4>
            <CheckboxButtons
              radio
              selected={selectedTopic}
              compact
              rounded
              noWrap
              style={{ marginRight: "5px", marginBottom: "5px" }}
              options={["All", ...categories]}
              onChange={setSelectedTopic}
            />
          </div>
          <div>
            <h2 style={{ marginBottom: "10px" }}>Recent Posts</h2>
            {loadingPosts ? (
              <Loading />
            ) : displayPosts.length > 0 ? (
              <Posts
                data={displayPosts}
                onSave={handleSave}
                savedPosts={savedPosts}
                onReact={handleReact}
                onDelete={handleDelete}
              />
            ) : (
              <NoPosts />
            )}
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <MostPopularPreview />
          <MyPostsPreview created={myPosts} saved={savedPosts} />
        </div>
      </div>
    </Container>
  );
};

export default Forum;
