import React from "react";
import Loading from "../Loading/Loading";

import styles from "./buttonStyles.module.scss";

const Button = ({
  reverse,
  disabled,
  compact,
  noBlur,
  selected,
  red,
  solid,
  checkbox,
  style,
  className,
  loading,
  onClick,
  children,
  fluid,
  type = "button",
}) => {
  return (
    <button
      type={type}
      className={[
        className,
        styles.button,
        fluid ? styles.fluid : null,
        reverse ? styles.buttonReverse : null,
        disabled || loading ? styles.disabled : null,
        compact ? styles.compact : null,
        selected ? styles.selected : null,
        solid ? styles.solid : null,
        red ? styles.red : null,
        checkbox ? styles.checkbox : null,
        noBlur ? styles.noBlur : null,
      ].join(" ")}
      onClick={type === "submit" ? undefined : onClick}
      style={{ ...style }}
      disabled={disabled || loading}
    >
      {loading ? <Loading style={{ opacity: "0.8" }}></Loading> : children}
    </button>
  );
};

export default Button;
