import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppDataContext } from "../../components/AppDataProvider";
import LinkButton from "../../components/LinkButton/LinkButton";
import { MobileH1, SessionLink } from "../Journal/Journal";

const MySessions = (props) => {
  const { mySessions, strains } = useContext(AppDataContext);
  const history = useHistory();

  return (
    <div
      style={{
        padding: "3px 8px 50px 8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ textAlign: "left", marginBottom: "10px" }}>
        <LinkButton
          onClick={() => {
            history.push("/dosage-journal");
          }}
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft} /> Dosage Journal
        </LinkButton>
      </div>
      <MobileH1>My Sessions ({mySessions.length})</MobileH1>
      {mySessions.map((session, i) => (
        <SessionLink
          key={session._id}
          method={session.method}
          onClick={() => {
            history.push(`/dosage-journal/session-detail/${session._id}`);
          }}
          name={
            session.strainId
              ? strains.find((strain) => strain._id === session.strainId)
                  .strainName
              : session.product?.name
          }
          date={session.entryDate}
          rating={session.overallRating}
          noBorder={i === mySessions.length}
        ></SessionLink>
      ))}
    </div>
  );
};

export default MySessions;
