import React from "react";
//import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useContext, useState } from "react";
import authConfig from "../../auth_config.json";
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faPlusCircle,
  faTrash,
  faTrashAlt,
  faSearch,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { EmployeeContext } from "../../App";
import styles from "./employeeStyles.module.scss";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import PopUp from "../../components/PopUp/PopUp";
import Loading from "../../components/Loading";
import Can from "../../components/Can/Can";
import Container from "../../components/Container/Container";
import NoAccess from "../NoAccess/NoAccess";
import ColorDots from "../../components/ColorDots/ColorDots";
import PhoneInput from "react-phone-input-2";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";

const Employee = (props) => {
  const history = useHistory();

  const startDate = props.data.startDate
    ? new Date(props.data.startDate)
    : null;
  return (
    <div
      style={{ display: "flex", ...props.style }}
      className={[styles.employee, props.className].join(" ")}
    >
      <EmployeePhoto
        imgLocation={props.data.imgLocation}
        className={styles.largePhoto}
      ></EmployeePhoto>
      <EmployeePhoto
        imgLocation={props.data.imgLocation}
        className={styles.smallPhoto}
        width={100}
        height={100}
      ></EmployeePhoto>
      <div className={styles.employeeTextWrapper}>
        <div style={{ borderBottom: "1px solid rgb(150,150,150)" }}>
          <h3 className={[styles.header2].join(" ")}>
            {props.data.firstName} {props.data.lastName}
          </h3>
          <h3 className={styles.header3}>
            {props.data.jobTitle || "Team Member"}
          </h3>
        </div>
        <Can
          user={props.employeeData}
          access="employees:update"
          yes={
            <div style={{ position: "absolute", right: 0, top: 0 }}>
              <Button
                compact
                style={{ marginRight: "5px" }}
                onClick={() => {
                  history.push(`/edit-employee/${props.data.firebaseId}`);
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>
              </Button>
              <Button
                onClick={() => {
                  props.setShowDeletePopUp(props.data);
                }}
                compact
                red
              >
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
              </Button>
            </div>
          }
          no={null}
        ></Can>

        <div className={styles.employeeDataWrapper}>
          {/*<div className={styles.col}>
            <h4 className={styles.header4}>Employee Since:</h4>{" "}
            <span className={styles.dataField}>
              {`${
                startDate.getMonth() + 1
              }/${startDate.getDate()}/${startDate.getFullYear()}`}
            </span>
            </div>*/}
          <div className={styles.col}>
            <h4 className={styles.header4}>Email:</h4>{" "}
            <span className={styles.dataField}>{props.data.email}</span>
          </div>
          <div className={styles.col}>
            <h4 className={styles.header4}>Cell Phone:</h4>{" "}
            <span className={styles.dataField}>
              <PhoneNumber num={props.data.cellPhone}></PhoneNumber>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Employees = (props) => {
  //const { getAccessTokenSilently } = useAuth0();
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showEmployeeDeleted, setShowEmployeeDeleted] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);
  const [employeeSearchValue, setEmployeeSearchValue] = useState("");
  const history = useHistory();
  //const { employeeData } = useContext(EmployeeContext);
  const firebase = useContext(FirebaseContext);
  const employeeData = firebase.data;

  useEffect(() => {
    const newFilteredEmployees =
      employeeSearchValue.length > 0
        ? employees.filter((employee) =>
            `${employee.firstName.toLowerCase()} ${employee.lastName.toLowerCase()}`.includes(
              employeeSearchValue.toLowerCase()
            )
          )
        : [...employees];
    setFilteredEmployees(newFilteredEmployees);
  }, [employeeSearchValue, employees]);

  const getEmployees = async () => {
    try {
      //const token = await getAccessTokenSilently();
      const token = await firebase.auth.currentUser.getIdToken(true);
      const response = await fetch(`/api/getEmployees`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const jsonResponse = await response.json();

      return jsonResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteEmployee = async (employee) => {
    setDeleting(true);
    //const token = await getAccessTokenSilently();
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch(`/api/deleteEmployee/${employee.firebaseId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    setDeleting(false);
    if (response.status == 200) {
      setShowEmployeeDeleted(true);
    } else {
      setShowDeleteError(jsonResponse.message);
    }
  };

  useEffect(() => {
    let subscribed = true;
    getEmployees().then((employees) => {
      if (subscribed) {
        setEmployees(employees);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  return (
    <Can
      user={employeeData}
      access="employees:read"
      yes={
        <div className={styles.wrapper}>
          {showDeletePopUp ? (
            <PopUp>
              {deleting ? (
                <div>
                  <Loading></Loading>
                </div>
              ) : showEmployeeDeleted ? (
                <div>
                  <h3>Successfully Deleted.</h3>
                  <div>
                    <Button
                      onClick={() => {
                        setShowDeletePopUp(false);
                        setShowEmployeeDeleted(false);
                      }}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              ) : showDeleteError ? (
                <div>
                  Error: {showDeleteError}
                  <div>
                    <Button
                      onClick={() => {
                        setShowDeleteError(false);
                        setShowDeletePopUp(false);
                      }}
                    >
                      Ok.
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <h2>
                    Are you sure you want to delete {showDeletePopUp.firstName}{" "}
                    {showDeletePopUp.lastName}?
                  </h2>
                  <div>
                    <Button
                      style={{ margin: "20px" }}
                      onClick={() => {
                        setShowDeletePopUp(false);
                      }}
                    >
                      No, go back.
                    </Button>
                    <Button
                      style={{ margin: "20px" }}
                      red
                      onClick={() => {
                        handleDeleteEmployee(showDeletePopUp);
                      }}
                    >
                      Yes, delete
                    </Button>
                  </div>
                </div>
              )}
            </PopUp>
          ) : null}
          <Container>
            <h1 style={{ textAlign: "center" }}>Employee Finder</h1>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginBottom: "30px",
              }}
            ></div>
            <div className={styles.headerWrapper}>
              <h2 className={[styles.header1].join(" ")}>
                Employees ({filteredEmployees.length})
              </h2>
              <Input
                fluid
                style={{ flex: 1 }}
                label={
                  <div style={{ fontWeight: "bold" }}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> Search
                  </div>
                }
                type="text"
                value={employeeSearchValue}
                onChange={(v) => {
                  setEmployeeSearchValue(v);
                }}
              />
              {/* 
              <div style={{ flex: "50%", textAlign: "right" }}>
                <Can
                  user={employeeData}
                  access="employees:create"
                  yes={
                    <Button
                      onClick={() => {
                        history.push("/add-new-employee");
                      }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>{" "}
                      Add Employee
                    </Button>
                  }
                  no={null}
                ></Can>
              </div>*/}
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {filteredEmployees
                .sort((a, b) =>
                  a.firstName > b.firstName
                    ? 1
                    : b.firstName > a.firstName
                    ? -1
                    : 0
                )
                .map((employee) => {
                  return (
                    <div
                      key={employee.firebaseId}
                      style={{
                        flex: "50%",
                        padding: "25px",
                        maxWidth: "800px",
                      }}
                    >
                      <Employee
                        data={employee}
                        style={{ padding: "20px 0" }}
                        setShowDeletePopUp={(employee) => {
                          setShowDeletePopUp(employee);
                        }}
                        employeeData={employeeData}
                      ></Employee>
                    </div>
                  );
                })}
            </div>
          </Container>
        </div>
      }
      no={<NoAccess></NoAccess>}
    />
  );
};

export default Employees;
