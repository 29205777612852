import React, { useState, Fragment, useEffect, useContext } from "react";
//import { useAuth0 } from "@auth0/auth0-react";
import styles from "./addNewEmployeeStyles.module.scss";
import Button from "../../components/Button/Button";
import FileInput from "../../components/FileInput/FileInput";
import EmployeePhoto from "../../components/EmployeePhoto/EmployeePhoto";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DateSelect from "../../components/DateSelect/DateSelect";
import ColorDots from "../../components/ColorDots/ColorDots";
import EmployeeDataForm, {
  StringInput,
} from "../../components/EmployeeDataForm/EmployeeDataForm";
import Container from "../../components/Container/Container";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";

const AddNewEmployee = (props) => {
  const firebase = useContext(FirebaseContext);
  const [employee, setEmployee] = useState({});

  const [tempPassword, setTempPassword] = useState("");

  const [imgUrl, setImgUrl] = useState(
    "https://lippianfamilydentistry.net/wp-content/uploads/2015/11/user-default.png"
  );

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  //const { getAccessTokenSilently } = useAuth0();

  const addEmployee = async (data) => {
    setSubmitting(true);
    try {
      //const token = await getAccessTokenSilently();
      const token = await firebase.auth.currentUser.getIdToken(true);
      const response = await fetch("/api/createEmployee", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      setSubmitting(false);
      if (response.status == 200) {
        history.push("/employees");
        window.scrollTo(0, 0);
      } else {
        setShowError(true);
        setErrorMessage(jsonResponse.message);
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  if (submitting) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }

  return (
    <div style={{ marginTop: "50px" }}>
      <h1 style={{ textAlign: "center" }}>Add New Employee</h1>

      <ColorDots style={{ margin: "30px 0" }} />
      <Container>
        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <EmployeeDataForm
            value={employee}
            onChange={(d) => {
              setEmployee(d);
            }}
          />
          <StringInput
            label="Temp Password:"
            value={tempPassword}
            onChange={(v) => {
              setTempPassword(v);
            }}
          ></StringInput>
          <div style={{ textAlign: "center" }}>
            <Button
              className="mt3"
              onClick={() => {
                addEmployee({
                  ...employee,
                  password: tempPassword,
                  sendEmail: true,
                });
              }}
            >
              Create Employee
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddNewEmployee;
