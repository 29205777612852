import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const StrainSphere = React.memo((props) => {
  const animationRef = useRef(null);
  const numSpheres = props.numSpheres || 30;
  const sphereSpeed = props.sphereSpeed || 0.004;
  const sphereColors = props.colors || [0xeb7d5b];
  const bgColor = props.bgColor || "white";

  useEffect(() => {
    let COLORS = [0xeb7d5b, 0xfed23f, 0xb5d33d, 0x6ca2ea, 0x442288],
      RADIUS = 250;

    // Setup new scene, camera, and render it to the DOM
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(bgColor);

    // Setup Lights
    const ambientLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2);
    const mainLight = new THREE.DirectionalLight(0xffffff, 0.9);

    mainLight.castShadow = true;
    mainLight.shadow.mapSize.width = 2048;
    mainLight.shadow.mapSize.height = 2048;
    mainLight.position.x = -20;

    scene.add(ambientLight);
    scene.add(mainLight);

    // Setup Camera
    const camera = new THREE.PerspectiveCamera(
      75,
      props.width / props.height,
      1,
      10000
    );
    camera.position.z = 500;

    // Setup Renderer
    const renderer =
      props.renderer ||
      new THREE.WebGLRenderer({
        antialias: true,
        powerPreference: "high-performance",
      });
    renderer.setSize(props.width, props.height);
    renderer.shadowMap.enabled = true;
    if (animationRef.current.children.length > 0) {
      animationRef.current.removeChild(animationRef.current.children[0]);
    }
    renderer.domElement.style.overflow = "hidden";
    animationRef.current.appendChild(renderer.domElement);

    // Add main sphere

    let geometry = new THREE.SphereBufferGeometry(RADIUS - 80, 12, 6);
    //let material = new THREE.MeshBasicMaterial({ color: 0x3e5544 });

    let material = new THREE.MeshBasicMaterial({
      color: 0xffff77,
    });
    let mainSphere = new THREE.Mesh(geometry, material);
    mainSphere.receiveShadow = true;
    scene.add(mainSphere);

    // Add small spheres

    let spheres = [];
    for (let i = 0; i < numSpheres; i++) {
      geometry = new THREE.SphereBufferGeometry(
        Math.floor(Math.random() * 20) + 6,
        16,
        8
      );
      material = new THREE.MeshBasicMaterial({
        color: sphereColors[Math.floor(Math.random() * sphereColors.length)],
      });

      geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, RADIUS, 0));
      let mesh = new THREE.Mesh(geometry, material);

      mesh.castShadow = true;

      mesh.rotation.x = Math.floor(Math.random() * 100);
      mesh.rotation.y = Math.floor(Math.random() * 100);
      mesh.rotation.z = Math.floor(Math.random() * 100);

      scene.add(mesh);

      spheres.push(mesh);
    }

    let subscribed = true;

    //Animate the cube
    const animate = function () {
      if (subscribed) {
        requestAnimationFrame(animate);

        spheres.forEach((sphere) => {
          sphere.rotation.x += sphereSpeed;
          sphere.rotation.y += sphereSpeed;
          sphere.rotation.z += sphereSpeed;
        });

        renderer.render(scene, camera);
      }
    };

    animate();

    return () => {
      subscribed = false;
    };
  }, [props.colors, props.sphereSpeed, props.numSpheres, props.bgColor]);

  return <div ref={animationRef} style={{ overflow: "hidden" }}></div>;
});

export default StrainSphere;
