import React from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../../utils/constants";
import Container from "../Container/Container";
import LinkButton from "../LinkButton/LinkButton";

const StackBanner = ({ data }) => {
  const history = useHistory();
  return (
    <div
      style={{
        backgroundColor: colors[3],
        color: "white",
        fontWeight: "bold",
        padding: "10px 0",
      }}
    >
      <Container>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {data.map((item, i) => (
            <div key={i}>
              <LinkButton
                onClick={() => {
                  history.push(item.location);
                }}
                style={{ color: "white", fontWeight: "bold" }}
              >
                {item.label}
              </LinkButton>
              {i < data.length - 1 ? (
                <span style={{ margin: "0 10px" }}>{">"}</span>
              ) : null}
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default StackBanner;
