import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  createContext,
  useRef,
} from "react";
import Button from "../../components/Button/Button";
import styles from "./newSessionStyles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faLongArrowAltRight,
  faCheckCircle,
  faCookieBite,
  faEyeDropper,
  faWind,
  faJoint,
  faLongArrowAltLeft,
  faCalendar,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading";
import isEmpty from "lodash/isEmpty";
import animate from "../../assets/styles/animate.module.scss";
import StarRating from "../../components/StarRating/StarRating";
import Rating from "../../components/Rating/Rating";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { clean } from "../../utils/helpers";
import Container from "../../components/Container/Container";
import CheckboxButtons from "../../components/CheckboxButtons/CheckboxButtons";
import { format, getDay, isToday } from "date-fns";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../../components/FirebaseProvider/FirebaseProvider";
import Input from "../../components/Input/Input";

import TextEditor from "../../components/TextEditor/TextEditor";
import { convertToRaw } from "draft-js";
import { colors, symptoms } from "../../utils/constants";
import MethodIcon from "../../components/MethodIcon";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { AppDataContext } from "../../components/AppDataProvider";
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import ProgressUnderline from "../../components/ProgressUnderline/ProgressUnderline";

const feelings = [
  "Happy",
  "Relaxed",
  "Energized",
  "Carefree",
  "Aroused",
  "Talkative",
  "Creative",
  "Focused",
  "Hungry",
  "Sleepy",
];

const effects = [
  "Dry Mouth",
  "Headache",
  "Anxiety",
  "Social Anxiety",
  "Fatigue",
  "Rapid Heartbeat",
  "Dry Eyes",
];

export const SessionContext = createContext();

const MobileStepHeader = ({ stepNum, stepLabel }) => {
  return (
    <div
      style={{
        backgroundColor: colors[0],
        fontSize: "0.9em",
        fontWeight: "bold",
        padding: "5px 0",
        color: "rgba(80,80,80,1)",
      }}
    >
      Step {stepNum + 1}: {stepLabel}
    </div>
  );
};

const MobileStepNavButtons = ({ onNext, onBack }) => {
  const session = useContext(SessionContext);
  return (
    <div style={{ display: "flex" }}>
      <button
        style={{
          flex: "1",
          border: "none",
          backgroundColor: colors[3],
          color: "white",
          fontWeight: "bold",
          padding: "15px 0",
        }}
        onClick={() => {
          window.scrollTo(0, 0);
          session.setValues({ currentStep: session.currentStep + 1 });
        }}
      >
        Next <FontAwesomeIcon icon={faLongArrowAltRight} />
      </button>
    </div>
  );
};

const MobileStepLayout = ({
  stepNum,
  stepLabel,
  subHeader,
  showNextButton,
  children,
}) => {
  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          alignItems: "stretch",
        }}
      >
        {children}
      </div>
      {showNextButton ? (
        <div style={{ position: "fixed", bottom: "50px", left: 0, right: 0 }}>
          <MobileStepNavButtons></MobileStepNavButtons>
        </div>
      ) : null}
    </div>
  );
};

const StepOne = () => {
  const session = useContext(SessionContext);

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        padding: "0 18px",
      }}
    >
      <div style={{ margin: "15px 0" }}>
        <h1>Step 1: Date & Time</h1>
        <ProgressUnderline currentStep={1} totalSteps={7}></ProgressUnderline>
      </div>
      <h2 style={{ marginBottom: "20px" }}>
        When did this session take place?
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginBottom: "40px",
        }}
      >
        <KeyboardDateTimePicker
          disableFuture
          showTodayButton
          value={session.datetime}
          onChange={(dt) => {
            session.setValues({ datetime: dt });
          }}
        />
      </div>
      <div style={{ margin: "15px 0" }}>
        <h1>Step 2: Method</h1>
        <ProgressUnderline currentStep={2} totalSteps={7}></ProgressUnderline>
      </div>
      <h2 style={{ marginBottom: "20px" }}>
        Select your method of consumption.
      </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <CheckboxButtons
          style={{ margin: "6px" }}
          selected={session.method}
          radio
          options={["Smoke", "Edible"]}
          onChange={(v) => {
            session.setValues({
              method: v,
            });
          }}
        ></CheckboxButtons>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          disabled={!session.method}
          solid
          style={{ minWidth: "150px" }}
          onClick={() => {
            session.setValues({ currentStep: session.currentStep + 1 });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const SelectStrainMobile = () => {
  const session = useContext(SessionContext);
  const firebase = useContext(FirebaseContext);
  const isMounted = useRef(true);
  const [searchValue, setSearchValue] = useState("");
  const [loadingStrains, setLoadingStrains] = useState(true);
  const [strains, setStrains] = useState([]);

  const getStrains = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch("/api/getStrains", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  useEffect(() => {
    getStrains().then((strains) => {
      if (isMounted.current) {
        setStrains(strains);
        setLoadingStrains(false);
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredStrains = strains.filter((strain) =>
    strain.strainName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div
      style={{
        padding: "0 18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ margin: "15px 0" }}>
        <h1>Step 3: Strain</h1>
        <ProgressUnderline currentStep={3} totalSteps={7}></ProgressUnderline>
      </div>
      <div>
        <Input
          type="text"
          value={searchValue}
          onChange={setSearchValue}
          label={
            <span>
              <FontAwesomeIcon icon={faSearch} /> Search
            </span>
          }
          fluid
        ></Input>
      </div>
      <div
        style={{
          maxHeight: "50vh",
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "flex-start",
          marginBottom: "30px",
        }}
      >
        {filteredStrains.map((strain) => (
          <TouchableOpacity
            key={strain._id}
            selected={session.strain?._id === strain._id}
            onClick={() => {
              session.setValues({ strain });
            }}
          >
            {strain.strainName}
          </TouchableOpacity>
        ))}
      </div>

      <div
        style={{
          textAlign: "center",
        }}
      >
        <Button
          solid
          style={{ minWidth: "150px" }}
          disabled={!session.strain}
          onClick={() => {
            session.setValues({ currentStep: session.currentStep + 1 });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const SelectDosageMobile = () => {
  const session = useContext(SessionContext);
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      minHeight: 10,
      padding: 0,
      fontSize: 8,
      backgroundColor: "white",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    smallerFont: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();
  console.log(session.dosage);
  return (
    <MobileStepLayout
      showNextButton
      stepNum={session.currentStep}
      stepLabel="Enter the Dosage Amount"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControl className={classes.formControl}>
          <Select
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            value={
              session.method.toLowerCase() === "smoke"
                ? session.dosage || 1
                : session.dosage || 5
            }
            className={classes.smallerFont}
            onChange={(e) => {
              console.log(e.target.value);
              session.setValues({ dosage: e.target.value });
            }}
          >
            {session.method.toLowerCase() === "smoke"
              ? [1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                  <MenuItem
                    value={num}
                    className={classes.smallerFont}
                    key={num}
                  >
                    {num}
                    {num === 8 ? "+" : null}
                  </MenuItem>
                ))
              : [
                  2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25, 27.5, 30, 32.5,
                  35, 37.5, 40,
                ].map((num) => (
                  <MenuItem
                    value={num}
                    className={classes.smallerFont}
                    key={num}
                  >
                    {num}
                    {num === 40 ? "+" : null}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        {session.method.toLowerCase() === "smoke" ? (
          <span>puffs</span>
        ) : (
          <span>mg</span>
        )}
      </div>
      {session.method.toLowerCase() === "edible" ? (
        <div style={{ marginTop: "20px" }}>
          <div style={{ fontSize: "0.9em" }}>Batch Number (optional):</div>
          <FormControl className={classes.formControl}>
            <Select
              inputProps={{ "aria-label": "Without label" }}
              value={session.batchNum || 100}
              className={classes.smallerFont}
              onChange={(e) => {
                console.log(e.target.value);
                session.setValues({ batchNum: e.target.value });
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                <MenuItem value={num} className={classes.smallerFont} key={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}
    </MobileStepLayout>
  );
};

const SelectEdibleMobile = () => {
  const session = useContext(SessionContext);
  const [edibles, setEdibles] = useState([]);
  const [loadingEdibles, setLoadingEdibles] = useState(true);
  const firebase = useContext(FirebaseContext);
  const getEdibles = async () => {
    const token = await firebase.auth.currentUser.getIdToken(true);
    const response = await fetch("/api/getEdibles", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const jsonResponse = await response.json();
    setLoadingEdibles(false);

    return jsonResponse;
  };

  useEffect(() => {
    let subscribed = true;
    getEdibles().then((edibles) => {
      if (subscribed) {
        setEdibles(edibles);
      }
    });
    return () => {
      subscribed = false;
    };
  }, []);

  return (
    <div style={{ padding: "0 18px" }}>
      <div style={{ margin: "15px 0" }}>
        <h1>Step 3: Product & Dosage</h1>
        <ProgressUnderline currentStep={3} totalSteps={7}></ProgressUnderline>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "flex-start",
        }}
      >
        {edibles.map((product) => (
          <TouchableOpacity
            key={product._id}
            selected={session.product?._id === product._id}
            onClick={() => {
              session.setValues({ product });
            }}
          >
            {product.name}
          </TouchableOpacity>
        ))}
      </div>
      <h2 style={{ margin: "30px 0" }}>How many milligrams did you take?</h2>
      <div style={{ textAlign: "center" }}>
        <Select
          displayEmpty
          value={session.dosage}
          onChange={(e) => {
            session.setValues({ dosage: e.target.value });
          }}
        >
          <MenuItem value={2.5}>2.5</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={7.5}>7.5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={12.5}>12.5</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={17.5}>17.5</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={22.5}>22.5</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={27.5}>27.5</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
        mg
      </div>
      <div style={{ margin: "30px 0", textAlign: "center" }}>
        <Button
          disabled={!session.product}
          solid
          style={{ minWidth: "150px" }}
          onClick={() => {
            session.setValues({ currentStep: session.currentStep + 1 });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const SelectProductMobile = () => {
  const session = useContext(SessionContext);

  if (session.method.toLowerCase() === "smoke") {
    return <SelectStrainMobile></SelectStrainMobile>;
  }
  if (session.method.toLowerCase() === "edible") {
    return <SelectEdibleMobile></SelectEdibleMobile>;
  }
};

const RateFeelingsMobile = () => {
  const session = useContext(SessionContext);
  return (
    <div style={{ padding: "0 18px" }}>
      <div style={{ margin: "15px 0" }}>
        <h1>Step 4: Feelings</h1>
        <ProgressUnderline currentStep={4} totalSteps={7}></ProgressUnderline>
      </div>
      <h2 style={{ marginBottom: "20px" }}>
        How did this session make you feel?
      </h2>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        {
          <CheckboxButtons
            compact
            style={{ margin: "4px" }}
            selected={session.feelings}
            onChange={(v) => {
              session.setValues({ feelings: v });
            }}
            options={feelings}
          ></CheckboxButtons>
        }
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ minWidth: "150px" }}
          solid
          onClick={() => {
            session.setValues({ currentStep: session.currentStep + 1 });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const SelectSymptomsMobile = () => {
  const session = useContext(SessionContext);
  const firebase = useContext(FirebaseContext);
  const options = ["Worse", "No Effect", "Better"];

  return (
    <div style={{ padding: "0 18px" }}>
      <div style={{ margin: "15px 0" }}>
        <h1>Step 5: Symptom Relief</h1>
        <ProgressUnderline currentStep={5} totalSteps={7}></ProgressUnderline>
      </div>
      <h2 style={{ marginBottom: "30px" }}>
        Rate how this session affected each of your symptoms.
      </h2>
      {firebase.data.symptoms.map((symptom) => (
        <div key={symptom}>
          <h3 style={{ fontSize: "1em", margin: "5px 0" }}>{symptom}</h3>
          <div>
            <CheckboxButtons
              compact
              style={{ marginRight: "8px", marginBottom: "5px" }}
              options={options}
              selected={options[session.symptomRatings[symptom] + 1]}
              onChange={(v) => {
                session.setValues({
                  symptomRatings: {
                    ...session.symptomRatings,
                    [symptom]: options.indexOf(v) - 1,
                  },
                });
              }}
              radio
            ></CheckboxButtons>
          </div>
        </div>
      ))}
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <Button
          disabled={
            Object.keys(session.symptomRatings).length !==
            firebase.data.symptoms.length
          }
          solid
          style={{ minWidth: "150px" }}
          onClick={() => {
            session.setValues({ currentStep: session.currentStep + 1 });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const RateSideEffectsMobile = () => {
  const session = useContext(SessionContext);
  return (
    <div style={{ padding: "0 18px" }}>
      <div style={{ margin: "15px 0" }}>
        <h1>Step 6: Side Effects</h1>
        <ProgressUnderline currentStep={6} totalSteps={7}></ProgressUnderline>
      </div>
      <h2 style={{ marginBottom: "30px" }}>
        Did this session produce any side effects? Select all that apply.
      </h2>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <CheckboxButtons
          compact
          style={{ margin: "5px" }}
          options={effects}
          onChange={(v) => {
            session.setValues({ effects: v });
          }}
          selected={session.effects}
        ></CheckboxButtons>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ minWidth: "150px" }}
          solid
          onClick={() => {
            session.setValues({ currentStep: session.currentStep + 1 });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const CommentsMobile = () => {
  const session = useContext(SessionContext);
  const firebase = useContext(FirebaseContext);
  const [errorCode, setErrorCode] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { reloadData } = useContext(AppDataContext);
  const employeeData = firebase.data;
  const history = useHistory();
  console.log(session.batchNum);

  const handleSubmit = async () => {
    setSubmitting(true);
    const token = await firebase.auth.currentUser.getIdToken(true);
    const data = {
      firebaseId: employeeData.firebaseId,
      createdAt: Date.now(),
      entryDate: session.datetime,
      strainId: session.strain?._id,
      strainName: session.strain?.strainName,
      product: session.product,
      method: session.method,
      dosage: parseFloat(session.dosage),
      units:
        session.method.toLowerCase() === "smoke"
          ? "puffs"
          : session.method.toLowerCase() === "edible"
          ? "mg"
          : null,
      batchNum: session.batchNum || undefined,
      symptomRatings: session.symptomRatings,
      sideEffects: session.effects,
      feelings: session.feelings,
      overallRating: session.rating,
      noteContent: session.note?.getCurrentContent().hasText()
        ? convertToRaw(session.note.getCurrentContent())
        : undefined,
    };
    console.log(data);
    const cleanData = clean(data);
    console.log(cleanData);
    const employeeResponse = fetch("/api/createSession", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cleanData),
    });

    if (employeeResponse) {
      setSuccessMessage(true);

      setTimeout(() => {
        reloadData();
        history.push("/dosage-journal");
      }, 900);
    }

    setSubmitting(false);
  };

  if (submitting) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }
  if (successMessage) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "50vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "3em",
          color: colors[4],
        }}
      >
        <div style={{ textAlign: "center" }}>
          <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
        </div>
        <div style={{ textAlign: "center" }}>Session Saved</div>
      </div>
    );
  }

  return (
    <div style={{ padding: "0 18px" }}>
      <div style={{ margin: "15px 0" }}>
        <h1>Step 7: Overall</h1>
        <ProgressUnderline currentStep={7} totalSteps={7}></ProgressUnderline>
      </div>
      <div style={{ textAlign: "center" }}>
        <div style={{ marginBottom: "20px" }}>
          <h2>How would you rate this session overall?</h2>
          <StarRating
            value={session.rating}
            onChange={(v) => {
              session.setValues({ rating: v });
            }}
            style={{ marginTop: "30px" }}
          ></StarRating>
        </div>
        <form
          style={{ textAlign: "center", padding: "20px 6px 70px 6px" }}
          onSubmit={handleSubmit}
        >
          <TextEditor
            onChange={(v) => {
              session.setValues({ note: v });
            }}
            placeholder="Any additional comments? (optional)"
          ></TextEditor>
          <input type="submit" style={{ display: "none" }} />
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
              marginBottom: "50px",
            }}
          >
            <Button solid type="submit" disabled={session.rating === 0}>
              Finish & Save
            </Button>
            {errorCode ? (
              <div>
                <div>There was an error.</div>
                <div>Error Code: {errorCode}</div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

const NextStep = () => {
  const session = useContext(SessionContext);
  useEffect(() => {
    session.setValues({ currentStep: session.currentStep + 1 });
  }, []);
  return <div></div>;
};

const NewSession = ({}) => {
  const firebase = useContext(FirebaseContext);
  const [sessionData, setSessionData] = useState({
    currentStep: 0,
    datetime: new Date(),
    dosage: 5,
    units: "mg",
    feelings: [],
    selectedSymptoms: [],
    symptomRatings: {},
    effects: [],
    rating: 0,
  });

  return (
    <SessionContext.Provider
      value={{
        ...sessionData,
        setValues: (newValues) => {
          setSessionData((current) => ({ ...current, ...newValues }));
        },
      }}
    >
      {
        [
          <StepOne></StepOne>,
          <SelectProductMobile></SelectProductMobile>,
          <RateFeelingsMobile></RateFeelingsMobile>,
          firebase.data.symptoms?.length > 0 ? (
            <SelectSymptomsMobile></SelectSymptomsMobile>
          ) : (
            <NextStep></NextStep>
          ),
          <RateSideEffectsMobile></RateSideEffectsMobile>,
          <CommentsMobile></CommentsMobile>,
        ][sessionData.currentStep]
      }
    </SessionContext.Provider>
  );
};

export default NewSession;
